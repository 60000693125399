import { createServices } from '@vs/vsf-boot';
export default createServices({
  EmployeeDoctorController_getAllByMedicalCareManagerPinyinQto_efea49: {
    method: 'post',
    url: '/api/employee-doctor/get-all-by-medical-care-manager-pinyin-qto',
    parameterFomatter: (data?: { qto: MedicalCareManagerPinyinQto2C4F14 }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: MedicalCareMedicalManagerPinyinVoE82346[],
    ) => data,
  },
  EmployeeDoctorController_getListMedicalCareManagerVoByIsCustomerService_2a33ea:
    {
      method: 'post',
      url: '/api/employee-doctor/get-list-medical-care-manager-vo-by-is-customer-service',
      parameterFomatter: (data?: { isCustomerService: number }) => data,
      responseFormatter: (_, __, data?: MedicalCareManagerVoE82346[]) => data,
    },
  EmployeeDoctorController_getMedicalCareManagerVoById_182445: {
    method: 'post',
    url: '/api/employee-doctor/get-medical-care-manager-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: MedicalCareManagerDepartmentVoE82346) =>
      data,
  },
  EmployeeTeamController_getAllByTeamManagerPinyinQto_8cea9e: {
    method: 'post',
    url: '/api/employee-team/get-all-by-team-manager-pinyin-qto',
    parameterFomatter: (data?: { qto: TeamManagerPinyinQto264856 }) => data,
    responseFormatter: (_, __, data?: TeamManagerPinyinVoB30939[]) => data,
  },
  InstitutionInfoController_getAllByInstitutionDepartmentPinyinQto_d8ca77: {
    method: 'post',
    url: '/api/institution-info/get-all-by-institution-department-pinyin-qto',
    parameterFomatter: (data?: { qto: InstitutionDepartmentPinyinQto82Ad0F }) =>
      data,
    responseFormatter: (_, __, data?: InstitutionDepartmentPinyinVoBe74D3[]) =>
      data,
  },
});
