import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  InfiniteScroll,
  NavBar,
  PullToRefresh,
  Radio,
  Rate,
} from '@vs/vsf-mobile';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import avatarMan from '@/assets/avatar_man.png';
import avatarWoMan from '@/assets/avatar_woman.png';
import doctorImg from '@/assets/doctor.png';
import Dom_title from '@/components/dom_title';
import EllipsisDom from '@/components/ellipsisDom';
import { PAGE_SIZE } from '@/config';
const OrderDetail: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [evaluateAvg, seEvaluateAvg] = useState<any>();
  const [hasMore, setHasMore] = useState(false);
  const [from, setFrom] = useState(0);
  const getPagefallByEvaluateFrontQto = async (reload) => {
    const res =
      await vsf.services.OrderRecordController_getPagefallByEvaluateFrontQto_0d042c(
        {
          qto: {
            size: PAGE_SIZE,
            from: reload ? 0 : from * PAGE_SIZE ?? 0,
          },
        },
      );
    /* eslint-disable */
    if (res.code === 200) {
      setData(
        reload ? res?.data?.result ?? [] : [...data, ...res?.data?.result],
      );
      setHasMore(res.data.result.length === PAGE_SIZE);
      setFrom(reload ? 1 : from + 1);
      return res.data?.result;
    } else {
      setHasMore(false);
    }
    /* eslint-enable */
  };
  const queryEvaluateAvgPointEo = async () => {
    const res =
      await vsf.services.OrderRecordController_queryEvaluateAvgPointEo_48dbe9();
    if (res.code === 200) {
      seEvaluateAvg(res.data);
    }
  };

  const toBundlePage = (orderId) => {
    vsf.navigateTo(`/health_care_service_details?orderId=${orderId}`);
  };
  useEffect(() => {
    getPagefallByEvaluateFrontQto(false);
    queryEvaluateAvgPointEo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickBack = () => {
    vsf?.goBack();
  };
  const renderSrcEmpty = (src) => {
    if (!src) return doctorImg;
    if (src?.length == 0) return doctorImg;
    return src;
  };
  return (
    <>
      <NavBar children="我的服务评价" backArrow onBack={handleClickBack} />
      <Dom_title title="我的服务评价" />
      <div className="health_care_appraise">
        <div className="card">
          <div className="apprais_avatar">
            <img src={renderSrcEmpty(evaluateAvg?.doctorAvatar)} alt="" />
          </div>
          <div className="center">
            <div className="name">{evaluateAvg?.doctorName ?? '-'}</div>
            <div className="rate_box">
              <span className="num">{evaluateAvg?.avgPoint ?? '-'}</span>
              <Rate
                style={{
                  '--star-size': '16px',
                  '--active-color': '#FFDE2F',
                }}
                readOnly
                value={evaluateAvg?.avgPoint}
              ></Rate>
            </div>
          </div>
          <div className="right">
            <div className="appraise_num">
              {evaluateAvg?.goodEvaluateRate ?? '-'}%
            </div>
            <div className="appraise_text">好评率</div>
          </div>
          <div className="bg"></div>
        </div>
        <div className="user_appraise">用户评价</div>
        <PullToRefresh onRefresh={() => getPagefallByEvaluateFrontQto(true)}>
          <div className="list">
            {data?.map((item) => {
              return (
                <div className="appraise_card" key={item.id}>
                  <div className="appraise_card_header">
                    <div className="avatar">
                      <img
                        src={
                          item?.visitPatient?.patientGender === 'WOMAN'
                            ? avatarWoMan
                            : avatarMan
                        }
                        alt=""
                      />
                    </div>
                    <div className="appraise_card_header_right">
                      <div className="top">
                        <div className="user">
                          <div className="name">
                            {item.visitPatient?.patientName ?? '-'}
                          </div>
                        </div>
                        <div
                          className="detail_btn"
                          onClick={() => toBundlePage(item.id)}
                        >
                          <div className="text ellipsis1">
                            {item.bundleName ?? '-'}
                          </div>
                          <RightOutline color="#1778FF" fontSize={12} />
                        </div>
                      </div>
                      <div className="star">
                        <Rate
                          style={{
                            '--star-size': '14px',
                            '--active-color': '#ff7f7f',
                          }}
                          readOnly
                          value={item.orderEvaluateDto?.evaluatePoint}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="appraise_card_content">
                    <EllipsisDom
                      content={item?.orderEvaluateDto?.evaluateContent}
                      rows={3}
                    />
                  </div>
                  <div className="date">
                    {item?.orderEvaluateDto?.createdAt
                      ? dayjs(item?.orderEvaluateDto?.createdAt).format(
                          'YYYY-MM-DD',
                        )
                      : '-'}
                  </div>
                </div>
              );
            })}
          </div>

          <InfiniteScroll
            children="我是有底线的~"
            loadMore={getPagefallByEvaluateFrontQto}
            hasMore={hasMore}
            className="InfiniteScroll_box"
          />
        </PullToRefresh>
      </div>
    </>
  );
};

export default definePage(OrderDetail);
