import { createServices } from '@vs/vsf-boot';
export default createServices({
  PlanDoctorController_getTasksForDoctor_4e3be7: {
    method: 'post',
    url: '/api/rag-plan/get-tasks-for-doctor',
    parameterFomatter: (data?: { planId: number }) => data,
    responseFormatter: (_, __, data?: PlanTaskStageAndExecuteVoDe76C9[]) =>
      data,
  },
  PlanDoctorController_getUnExecuteTasks_9d5953: {
    method: 'post',
    url: '/api/rag-plan/get-un-execute-tasks',
    parameterFomatter: (data?: { planId: number }) => data,
    responseFormatter: (_, __, data?: PlanTaskDoctorWaitExecuteVoDe76C9) =>
      data,
  },
  PlanDoctorController_getPlanById_5b90dc: {
    method: 'post',
    url: '/api/rag-plan/get-plan-by-id',
    parameterFomatter: (data?: { planId: number }) => data,
    responseFormatter: (_, __, data?: AccountPlanVoDe76C9) => data,
  },
  PlanDoctorController_getAllByMonitoringAndElementByKeyPlanQto_461051: {
    method: 'post',
    url: '/api/rag-plan/get-all-by-monitoring-and-element-by-key-plan-qto',
    parameterFomatter: (data?: { qto: ElementByPlanAndElementQto08B695 }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: PlanIndicatorMonitoringWithElementVoDe76C9,
    ) => data,
  },
  PlanDoctorController_getExecuteElements_b2b053: {
    method: 'post',
    url: '/api/rag-plan/get-execute-elements',
    parameterFomatter: (data?: { planId: number }) => data,
    responseFormatter: (_, __, data?: PlanTaskExecuteElementVoDe76C9[]) => data,
  },
});
