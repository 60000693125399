import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_createOrderEvaluate_aafd09: {
    method: 'post',
    url: '/api/order-record/create-order-evaluate',
    parameterFomatter: (data?: {
      btoParam: CreateOrderEvaluateBto53Cb1D;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: OrderEvaluateVo03Ec0D) => data,
  },
  OrderRecordController_getPagefallByEvaluateFrontQto_0d042c: {
    method: 'post',
    url: '/api/order-record/get-pagefall-by-evaluate-front-qto',
    parameterFomatter: (data?: { qto: EvaluateFrontQto510B2F }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrderRecordBundleDetailVo03Ec0D[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderRecordController_getOrderRecordEvaluateVoById_d84e0e: {
    method: 'post',
    url: '/api/order-record/get-order-record-evaluate-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: OrderRecordEvaluateVo03Ec0D) => data,
  },
  OrderRecordController_queryEvaluateAvgPointEo_48dbe9: {
    method: 'post',
    url: '/api/order-record/query-evaluate-avg-point-eo',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: EvaluateAvgPointEo8A4D30) => data,
  },
  OrderRecordController_getPagedByGetEvaluateByBundleQto_75ca28: {
    method: 'post',
    url: '/api/order-record/get-paged-by-get-evaluate-by-bundle-qto',
    parameterFomatter: (data?: { qto: GetEvaluateByBundleQto510B2F }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrderRecordBundleDetailVo03Ec0D[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  EmployeePatientController_getPatientByUserAccount_309763: {
    method: 'post',
    url: '/api/employee-patient/get-patient-by-user-account',
    parameterFomatter: (data?: { userAccount: string }) => data,
    responseFormatter: (_, __, data?: PatientManagerVo462A84[]) => data,
  },
});
