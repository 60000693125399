import React, { useCallback, useEffect, useState } from 'react';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import { Avatar, Button, Divider, List } from 'antd-mobile';
import dayjs from 'dayjs';
import './index.less';

const btnList = [
  {
    id: 1,
    text: '检查报告',
  },
  {
    id: 2,
    text: '检验报告',
  },
];

const outpatientLeft = [
  {
    id: 1,
    text: '超敏 CRP 测定',
    timer: '2023-10-17 20:58:45',
  },
  {
    id: 2,
    text: '凝血四项',
    timer: '2023-10-29 05:31:25',
  },
  {
    id: 3,
    text: '血常规',
    timer: '2023-11-23 15:52:48',
  },
  {
    id: 4,
    text: '凝血四项',
    timer: '2023-07-15 19:06:08',
  },
  {
    id: 5,
    text: '凝血四项',
    timer: '2023-09-26 07:33:28',
  },
  {
    id: 6,
    text: '血常规',
    timer: '2023-05-15 19:06:08',
  },
  {
    id: 7,
    text: '凝血四项',
    timer: '2023-09-08 20:05:04',
  },
  {
    id: 8,
    text: '血常规',
    timer: '2023-05-23 02:31:48',
  },
  {
    id: 9,
    text: 'C反应蛋白',
    timer: '2023-05-11 19:06:08',
  },
  {
    id: 10,
    text: 'C反应蛋白',
    timer: '2023-11-04 06:59:13',
  },
];
const outpatientRight = [
  {
    id: 1,
    text: '血常规',
    timer: '2023-10-17 20:58:45',
  },
  {
    id: 2,
    text: '胸部B超',
    timer: '2023-10-29 05:31:25',
  },
  {
    id: 3,
    text: '血常规',
    timer: '2023-11-23 15:52:48',
  },
  {
    id: 4,
    text: '胸部B超',
    timer: '2023-07-15 19:06:08',
  },
  {
    id: 5,
    text: '血常规',
    timer: '2023-09-26 07:33:28',
  },
  {
    id: 6,
    text: '胸部B超',
    timer: '2023-05-15 19:06:08',
  },
  {
    id: 7,
    text: '胸部B超',
    timer: '2023-09-08 20:05:04',
  },
  {
    id: 8,
    text: '胸部B超',
    timer: '2023-05-23 02:31:48',
  },
  {
    id: 9,
    text: '脑电图',
    timer: '2023-05-11 19:06:08',
  },
  {
    id: 10,
    text: '胸部正位CR',
    timer: '2023-11-04 06:59:13',
  },
  {
    id: 10,
    text: '体表包块US',
    timer: '2023-01-04 06:59:13',
  },
];

export default function index(props) {
  const { currentTab } = props?.routes?.query;
  const [zindex, setZindex] = useState(0);
  const [dataList, setDataList] = useState([]);
  const tabsBtn = (index) => {
    setZindex(index);
  };

  useEffect(() => {
    let n;
    switch (currentTab) {
      case 'inspect':
        n = 0;
        break;
      case 'inspect1':
        n = 1;
        break;

      default:
        break;
    }
    setZindex(n);
  }, []);

  useEffect(() => {
    let dataStr;
    switch (zindex) {
      case 0:
        dataStr = outpatientLeft?.sort(
          (a, b) => dayjs(a.timer).valueOf() - dayjs(b.timer).valueOf(),
        );
        break;
      case 1:
        dataStr = outpatientRight?.sort(
          (a, b) => dayjs(a.timer).valueOf() - dayjs(b.timer).valueOf(),
        );
        break;

      default:
        break;
    }
    setDataList(dataStr);
  }, [zindex]);

  const title = () => {
    return (
      <div className="reportQuery_title">
        <div className="reportQuery_title_user">
          <Avatar src="" />
          <h3>库浩晨</h3>
          <p>男</p>
          <p className="i">|</p>
          <p>2岁1个月5天</p>
        </div>
        <Divider className="divider"></Divider>
        <div className="reportQuery_title_btn">
          {btnList.map((item, index) => {
            return (
              <Button
                color="default"
                fill="none"
                size="large"
                shape="rounded"
                className={index === zindex ? 'active' : null}
                onClick={() => tabsBtn(index)}
              >
                {item.text}
              </Button>
            );
          })}
        </div>
      </div>
    );
  };
  const content = () => {
    return (
      <ul className="reportQuery_content">
        {dataList.map((item, index) => {
          return (
            <li key={index}>
              <div className="reportQuery_content_left">
                <h3>{item.text} 报告单</h3>
                <p>{item.timer}</p>
              </div>
              <div className="reportQuery_content_right">
                <Button color="success" fill="none">
                  确认报告
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <div className="reportQuery">
      {title()}
      {content()}
    </div>
  );
}
