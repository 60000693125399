import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar, SearchBar, Toast } from '@vs/vsf-mobile';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import React, { useEffect, useRef, useState } from 'react';

import Dom_title from '@/components/dom_title';
const size = 20;
const ServiceList: React.FC = (props: any) => {
  const { routes } = props;
  const [list, setList] = useState<OnlineVideoListEo8A4D30[]>([]);
  const [videoList, setVideoList] = useState<OnlineVideoListEo8A4D30[]>([]);
  const [env, setEnv] = useState(false);

  const idRef = useRef(routes.query?.id ?? 6008);

  useEffect(() => {
    window?.uni.webView.getEnv(function (res) {
      console.log(res, res?.miniprogram, '当前环境：-------');
      setEnv(res?.miniprogram);
    });
    handleVideoList(idRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVideoList = async (orderItemId) => {
    const res = await vsf?.services?.OrderRecordController_videoUse_6f1470({
      orderItemId,
    });
    if (res?.code == 200) {
      setList(res?.data);
      setVideoList(res?.data);
    }
  };

  const handleVideDtails = async (value) => {
    const res = await vsf?.services?.OrderRecordController_videoValid_43254a({
      ...value,
    });
    if (res?.code === 200) {
      console.log(env, 'env');
      if (env) {
        handleClickVideo(value);
      } else {
        Toast.show({
          icon: 'fail',
          content: '现仅支持小程序观看视频',
        });
      }
    }
  };

  const handleSearchChange = (value) => {
    console.log(value, 'value');
    const searchList = list?.filter((v) => {
      return v?.title?.includes(value);
    });
    setVideoList(searchList);
  };

  const handleClickVideo = (value) => {
    window.uni?.navigateTo({
      url: `/pages/consult/health-course/course-detail/course-detail?id=${value?.id}`,
    });
  };

  const renderVideoItem = (data) => {
    return (
      <div
        className="video-item"
        onClick={() => {
          const data1 = {
            ...data,
            orderItemId: idRef?.current,
            videoId: data?.id,
          };
          handleVideDtails(data1);
        }}
      >
        <div className="video-header">
          <img className="img" src={data?.imgUrlCompressed}></img>
          <div className="title">视频</div>
        </div>
        <div className="video-content">
          <div className="header-title">{data?.title}</div>
          <div className="header-bottom">
            <div className="num">点击查看视频</div>
            <div className="text">
              <RightOutline />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar children="视频宣教" backArrow />
      <Dom_title title="视频宣教" />
      <div className="video-list-container">
        <SearchBar placeholder="搜索视频" onChange={handleSearchChange} />
        <div className="video-list-content">
          {videoList?.map((v) => renderVideoItem(v))}

          {videoList?.length == 0 && <div className="empty">暂无数据</div>}
          {/* {!env && <div className="prompt">温馨提示：请与小程序观看视频</div>} */}
        </div>
      </div>
    </>
  );
};

export default definePage(ServiceList);
