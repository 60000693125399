import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Calendar,
  Mask,
  Modal,
  NavBar,
  Popup,
  Toast,
} from '@vs/vsf-mobile';
import { DatePicker } from 'antd-mobile';
import {
  CheckCircleFill,
  CloseCircleOutline,
  DownOutline,
  RightOutline,
} from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import closeIcon from '@/assets/closeIcon.png';
import disease from '@/assets/disease.png';
import health from '@/assets/health.png';
import Slice41 from '@/assets/Slice41.png';
import Slice42 from '@/assets/Slice42.png';
import Slice43 from '@/assets/Slice43.png';
import Slice44 from '@/assets/Slice44.png';
import Slice45 from '@/assets/Slice45.png';
import Slice46 from '@/assets/Slice46.png';
import Slice47 from '@/assets/Slice47.png';
import Slice48 from '@/assets/Slice48.png';
import Slice49 from '@/assets/Slice49.png';
import Slice50 from '@/assets/Slice50.png';
import Slice54 from '@/assets/Slice54.png';
import Slice57 from '@/assets/Slice57.png';
import Slicetab from '@/assets/Slicetab.png';
import step2_img from '@/assets/step/step2_img.png';
import step2_text from '@/assets/step/step2_text.png';
import text1 from '@/assets/text1.png';
import user_avatar from '@/assets/user_avatar.png';
import warnIcon from '@/assets/warnIcon.png';
import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import { TaskCategoryENUM } from '@/config';
import { GenderEnum } from '@/config';
import { contentHtml, getENVIR } from '@/utils';
const pngList = [
  {
    taskCategory: 4,
    name: '健康日记',
    defPng: Slice45,
    activePng: Slice41,
    data: [],
  },
  {
    taskCategory: '2-3',
    name: '治疗任务',
    defPng: Slice48,
    activePng: Slice44,
    data: [],
  },
  {
    taskCategory: 5,
    name: '随访问卷',
    defPng: Slice46,
    activePng: Slice42,
    data: [],
  },
  {
    taskCategory: 6,
    name: '评估量表',
    defPng: Slice47,
    activePng: Slice43,
    data: [],
  },
];
// 获取本周的起始日期和结束日期
function getCurrentWeek(date) {
  const weekStart = 0; // 1表示周一是一周的开始，0表示周日是一周的开始
  let weekNum = 1;
  const startOfWeek = dayjs(date).day(weekStart).format('YYYY-MM-DD');
  const list = [startOfWeek];
  while (weekNum < 7) {
    const weekDay = dayjs(date)
      .day(weekStart + weekNum)
      .format('YYYY-MM-DD');
    list.push(weekDay);
    weekNum++;
  }
  return list;
}
const DailyQuest: React.FC<any> = (props) => {
  const [visible, setVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [defaultPlan, setDefaultPlan] = useState<AccountPlanVoDe76C9 | any>();
  const [taskDaily, setTaskDaily] = useState<any>();
  const [taskDaily1, setTaskDaily1] = useState<any>([]);
  const [historyTaskDaily, setHistoryTaskDaily] = useState<any>([]);
  // const [historyTaskDaily1, sethistoryTaskDaily1] = useState<any>([]);
  const [executeDays, setExecuteDays] = useState<any>([]);
  const [unreadNum, setUnreadNum] = useState(undefined);
  const [planList, setPlanList] = useState<
    {
      name: string;
      data: AccountPlanVoDe76C9[];
    }[]
  >();
  const [monthDate, setMonthDate] = useState<string>(dayjs().format('YYYY.M'));
  const [urlstate, setUrlState] = useUrlState(
    {
      currentPlanId: undefined,
      open: 0,
      selectDate: dayjs().format('YYYY-MM-DD'),
      activeCard: undefined,
      page: 1,
    },
    { navigateMode: 'replace' },
  );

  const [addPage, setAddPage] = useState(urlstate?.page ?? 1);
  const page = useRef(1);
  const { selectDate, activeCard } = props.routes?.query || {};
  const { plan } = props.stores;

  const {
    planData,
    setPlanData,
    warnNum,
    setWarnNum,
    setStep,
    step,
    warnData,
  } = plan;

  const initRef = useRef(0);
  const initactiveCardRef = useRef(0);

  const getDefaultPlan = (data): AccountPlanVoDe76C9 => {
    return data?.find(
      (item) => item.id == urlstate.currentPlanId,
    ) as AccountPlanVoDe76C9;
  };
  // 查询默认个案
  const getAccountDefaultPlan = async () => {
    const res =
      await vsf.services?.PlanPatientController_getAccountDefaultPlan_700494();
    if (res.code === 200 && res.data) {
      setDefaultPlan(res.data ?? {});
      setUrlState({ currentPlanId: res.data?.id });
    } else {
      setPlanData('none');
      vsf.navigateTo(`/index`);
      vsf.showToast('暂无个案!');
    }
  };
  // 查询个案列表
  const getAccountPlans = async () => {
    const res =
      await vsf.services?.PlanPatientController_getAccountPlans_492034();
    if (res.code === 200) {
      if (urlstate.currentPlanId && !defaultPlan) {
        const plan = getDefaultPlan(res.data);
        setDefaultPlan(plan);
      }
      const namelsit = [
        ...new Set(
          res.data?.map((item) => {
            return item.planPatient?.patient?.patientName;
          }),
        ),
      ];
      const data = namelsit.map((item) => {
        return {
          name: item,
          data: res.data.filter(
            (f) => f.planPatient?.patient?.patientName === item,
          ),
        };
      });
      setPlanList(data as any);
    }
  };
  // 查询个案详情
  const getTaskDaily = async () => {
    const res = await vsf.services?.PlanPatientController_getTaskDaily_7f5b87({
      qto: {
        idIs: defaultPlan?.id,
        planDate: dayjs(urlstate.selectDate).format('YYYY-MM-DD 00:00:00'),
      },
    });
    if (res.code === 200) {
      const data = pngList.map((item) => {
        const list = res.data.filter((f) => {
          if (item.taskCategory === '2-3') {
            return (
              f.taskMaster.taskCategory == 2 || f.taskMaster.taskCategory == 3
            );
          }
          return f.taskMaster.taskCategory == item.taskCategory;
        });
        return {
          ...item,
          data: list,
          executeNum: list?.filter((item) => item.isExecute).length,
        };
      });
      setTaskDaily({
        data,
        executeNum: res.data?.filter(
          (item) => !item.isExecute && item.taskMaster.taskCategory != 1,
        ).length,
        num: res.data?.filter((item) => item.taskMaster.taskCategory != 1)
          .length,
      });
      setTaskDaily1(
        res.data?.filter((item) => item.taskMaster.taskCategory == 1),
      );
      if (initactiveCardRef.current === 0 && activeCard) {
        initactiveCardRef.current = 1;
        return;
      }
      initactiveCardRef.current = 1;
      setUrlState({
        activeCard: data?.find((item) => item.data?.length)?.taskCategory,
      });
    }
  };

  const handleGetHistory = async () => {
    const res =
      await vsf.services?.PlanPatientController_getHistoryTaskDaily_849e29({
        qto: {
          idIs: defaultPlan?.id,
          planDate: dayjs(urlstate.selectDate).format('YYYY-MM-DD 00:00:00'),
        },
      });
    if (res.code === 200) {
      setHistoryTaskDaily(res?.data);

      // sethistoryTaskDaily1(
      //   res.data?.filter((item) => item.taskMaster.taskCategory == 1),
      // );
      // if (initactiveCardRef.current === 0 && activeCard) {
      //   initactiveCardRef.current = 1;
      //   return;
      // }
      // initactiveCardRef.current = 1;
      // sethistoryTaskDaily1({
      //   activeCard: data?.find((item) => item.data?.length)?.taskCategory,
      // });
    }
  };
  // 查询存在未执行任务的日期
  const getUnExecuteDays = async () => {
    const res =
      await vsf.services?.PlanPatientController_getUnExecuteDays_68068d({
        qto: {
          planPathwayPlanMasterIdIs: defaultPlan?.id,
          startDateTimeBiggerThanEqual: null,
          endDateTimeLessThan: null,
        },
      });
    if (res.code === 200) {
      setExecuteDays(res.data ?? []);
    }
  };
  const handleJumpDetails = () => {
    vsf?.navigateTo(
      `/indicator_details?planPatientId=${defaultPlan?.planPatient?.patient?.id}&type='health_care`,
    );
  };
  const handelStep2 = () => {
    setStep(3);
    vsf.navigateTo('mine');
  };
  const onDateChange = (date) => {
    setMonthDate(dayjs(date).format('YYYY.M'));
    vsf.refs?.date996?.jumpTo({
      year: dayjs(date).year(),
      month: dayjs(date).month() + 1,
    });
  };
  // 专病待处理数量
  const handleQueryAllUnread = async () => {
    const res =
      await vsf?.services?.SpecialistGuidanceController_queryAllUnread_f73ea7({
        planMasterIdIs: defaultPlan?.id,
      });
    if (res?.code === 200) {
      setUnreadNum(res?.data);
    }
  };
  useEffect(() => {
    if (defaultPlan?.id) {
      getUnExecuteDays();
      handleQueryAllUnread();
      if (initRef.current === 0 && selectDate) {
        initRef.current = initRef.current + 1;
        return;
      }
      if (defaultPlan.status === 'FINISH' && defaultPlan.endDateTime) {
        setUrlState({
          selectDate: dayjs(defaultPlan.endDateTime).format('YYYY-MM-DD'),
        });
        onDateChange(defaultPlan.endDateTime);
      } else {
        setUrlState({
          selectDate: dayjs().format('YYYY-MM-DD'),
        });
        onDateChange(new Date());
      }
      initRef.current = initRef.current + 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlan]);

  useEffect(() => {
    if (defaultPlan?.id) {
      getTaskDaily();
      handleGetHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlan, urlstate.selectDate]);

  useEffect(() => {
    if (!urlstate.currentPlanId) {
      getAccountDefaultPlan();
    }
    getAccountPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    const { page } = urlstate;
    const newPage = Number(page) + 1;
    setAddPage(newPage);
    setUrlState({ page: newPage });
  };

  const renderHistoryList = (id, type = 'arr') => {
    let list: any = [];
    if (id == '2-3') {
      list = historyTaskDaily?.filter(
        (v) =>
          v?.taskMaster?.taskCategory == '2' ||
          v?.taskMaster?.taskCategory == '3',
      );
    } else {
      list = historyTaskDaily?.filter((v) => v?.taskMaster?.taskCategory == id);
    }

    let historyList = [];
    if (addPage == 1) {
      historyList = list.slice(0, 2);
    } else {
      const num = 2 + (addPage - 1) * 10;
      historyList = list.slice(0, num > list?.length ? list?.length : num);
    }
    return type == 'num' ? list?.length : historyList;
  };

  const renderList = () => {
    const { activeCard } = urlstate;
    const list = taskDaily?.data?.find(
      (item) => item.taskCategory == activeCard,
    );
    if (!list?.data?.length && renderHistoryList(activeCard, 'num') == 0) {
      return null;
    }
    return (
      <div className="item_info">
        {list?.data?.length > 0 && <div className="title">今日任务</div>}

        {list.data?.map((item) => (
          <div
            className={classNames('quest_item', {
              quest_item_active: !item.isExecute,
            })}
            key={item.id + item.planDate}
          >
            <div className="text ellipsis1">{item.title}</div>
            {/* <div className="text ellipsis1">
              <div className="text ellipsis1">{item.title}</div>
              {!item.isExecute && (
                <div className="text time">任务日期：2024-07-10</div>
              )}
            </div> */}
            <div
              className="btn"
              onClick={() => {
                vsf?.navigateTo(
                  `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.planTaskExecute?.[0]?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
                );
              }}
            >
              {item.isExecute ? '已完成' : '去完成'}
            </div>
          </div>
        ))}
        {renderHistoryList(activeCard, 'arr')?.length > 0 && (
          <div className="title">历史未完成任务</div>
        )}
        {renderHistoryList(activeCard)?.map((item) => (
          <div
            className={classNames('quest_item', {
              quest_item_active: !item.isExecute,
            })}
            key={item.id + item.planDate}
          >
            <div className="text ellipsis1">
              <div className="text ellipsis1 flex1">{item.title}</div>
              {!item.isExecute && (
                <div className="text time">
                  任务日期：{dayjs(item?.planDate)?.format('YYYY-MM-DD')}
                </div>
              )}
            </div>
            <div
              className="btn"
              onClick={() => {
                vsf?.navigateTo(
                  `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.planTaskExecute?.[0]?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
                );
              }}
            >
              {item.isExecute ? '已完成' : '去完成'}
            </div>
          </div>
        ))}
        {renderHistoryList(activeCard, 'num') > 2 &&
          renderHistoryList(activeCard, 'arr')?.length <
            renderHistoryList(activeCard, 'num') && (
            <div className="mession">
              <span onClick={handleAdd}>查看更多</span>
            </div>
          )}
      </div>
    );
  };

  const renderTaskCategory1List = () => {
    console.log('taskDaily1', taskDaily1);
    return (
      <div className="heal_list">
        {taskDaily1?.map((item) => (
          <div
            className="popular-item"
            onClick={() => {
              vsf?.navigateTo(
                `/taskDetail?id=${item?.id}&detailId=${item?.taskMaster?.usedDetail?.id}&isExecute=${item?.isExecute}&planDate=${JSON.stringify(item?.planDate) ?? ''}&taskMasterId=${item?.taskMaster?.id}&planTaskExecute=${item?.planTaskExecute?.[0]?.id}&taskCategory=${item?.taskMaster?.taskCategory}`,
              );
            }}
            key={item.taskCategory}
          >
            <div className="popular-content">
              <div className="popular-content-item">
                <div className="popular-img-box">
                  {item.iconImg ? (
                    <img className="popular-img" src={item.iconImg}></img>
                  ) : null}
                </div>
                <div className="item-content">
                  <div className="content-name">{item.title}</div>
                  <div className="content-text">
                    {/* {contentHtml(item.content)} */}
                    {item.taskIntroduction}
                  </div>
                </div>
                <div className="iocn">
                  <RightOutline color="#7C8797" fontSize={18} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const renderSafeArea = () => {
    if (getENVIR()?.includes('wx')) {
      const operatingSystem = navigator.userAgent;
      const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      const isAndroid = navigator.userAgent.match(/Android/i);
      console.log(
        operatingSystem,
        `ios:${isiOS}`,
        `Android:${isAndroid}`,
        'operatingSystem',
      );
      return isiOS ? '22px' : '0px';
    }
  };

  const renderNum = (value) => {
    if (value)
      return (
        <div className="daily_quest_page_dot">
          {Number(value) <= 99 ? value : '99+'}
        </div>
      );
    return undefined;
  };

  return (
    <>
      <NavBar children="每日任务" backArrow />
      <DomTitle title="每日任务" />
      <div className="daily_quest_page">
        <div className="bg">
          <div className="user_info">
            <div className="avatar">
              <img src={user_avatar} alt="" />
            </div>
            <div className="user-info-content">
              <div className="user_info-top">
                <div className="user_name_box">
                  <div className="user_name">
                    {defaultPlan?.planPatient?.patient?.patientName ?? '-'}
                  </div>
                  <div className="user_gender">
                    {defaultPlan?.planPatient?.patient?.patientGender
                      ? GenderEnum[
                          defaultPlan?.planPatient?.patient?.patientGender
                        ]
                      : '-'}
                  </div>
                  <div className="user_age">{defaultPlan?.age ?? '-'}岁</div>
                </div>
              </div>
              <div className="user_info-bottom">
                <div className="user_right_text ellipsis1">
                  {defaultPlan?.currentPlanPathway?.title ?? '-'}
                </div>
              </div>
            </div>
            <div className="user_right " onClick={() => setPopupVisible(true)}>
              <div className="change_btn">
                <img src={Slice54} alt="" />
                <span>切换</span>
              </div>
            </div>
          </div>
          {defaultPlan?.abnormalCount ? (
            <div className="err_box" onClick={handleJumpDetails}>
              <img src={Slice50} alt="" />
              <div className="err_text">
                您有{defaultPlan?.abnormalCount}
                项异常指标，请及时查看处理意见!
              </div>
            </div>
          ) : null}
        </div>

        <div className="center-box">
          {/* 切图需要替换 */}
          <Badge
            content={renderNum(unreadNum)}
            style={{
              '--right': '2%',
              '--top': '4%',
              // borderRadius: '50%',
            }}
            bordered
          >
            <img
              className="img2"
              src={Slice57}
              onClick={() => {
                vsf?.navigateTo(`/guidance?id=${defaultPlan?.id}`);
              }}
            ></img>
          </Badge>
          <img
            className="img img1"
            src={health}
            onClick={() => {
              vsf?.navigateTo(`/health_education?id=${defaultPlan?.id}`);
            }}
          ></img>
        </div>
        <div className="date_box">
          <div
            className="date_change"
            onClick={() => {
              setVisible(true);
            }}
          >
            {monthDate}
            <img
              src={Slice49}
              className={classNames('downFill', { up: visible })}
            />
          </div>
          <div
            className="open"
            onClick={() => setUrlState({ open: urlstate.open == 1 ? 0 : 1 })}
          >
            {Number(urlstate.open) ? '收起' : '展开'}
            <DownOutline
              className={classNames('down_icon', {
                up: Number(urlstate.open),
              })}
            />
          </div>
          <Calendar
            id="date996"
            selectionMode="single"
            value={new Date(urlstate.selectDate)}
            allowClear={false}
            max={
              defaultPlan?.status === 'FINISH' && defaultPlan?.endDateTime
                ? new Date(defaultPlan?.endDateTime)
                : new Date()
            }
            min={
              defaultPlan?.startDateTime
                ? new Date(defaultPlan?.startDateTime)
                : undefined
            }
            onChange={(val) => {
              setUrlState({
                selectDate: dayjs(val).format('YYYY-MM-DD'),
              });
            }}
            className={classNames(`b_calendare`, {
              activeRetract: !Number(urlstate.open),
            })}
            onPageChange={(y, m) => setMonthDate(`${y}.${m}`)}
            renderDate={(date) => {
              const dates = getCurrentWeek(
                dayjs(urlstate.selectDate).format('YYYY-MM-DD'),
              );
              const d = dayjs(date).date();
              if (
                !dates.includes(dayjs(date).format('YYYY-MM-DD')) &&
                !Number(urlstate.open)
              )
                return null;
              return (
                <div
                  className={classNames('show-custom-cell', {
                    ['execute-custom-cell']: executeDays
                      .map((item) => dayjs(item).format('YYYY-MM-DD'))
                      .includes(dayjs(date).format('YYYY-MM-DD')),
                  })}
                >
                  {d}
                </div>
              );
            }}
          />
        </div>
        <div className="mession">
          <img src={text1} alt="" />
        </div>
        {taskDaily?.num ? (
          <div className="d_card">
            <div className="title">
              {[]}
              待办任务 （
              {taskDaily?.executeNum +
                historyTaskDaily?.filter(
                  (v) => v?.taskMaster?.taskCategory != '1',
                )?.length ?? '0'}
              项）
            </div>
            <div className="d_card_list">
              {taskDaily?.data?.map((item) => {
                if (
                  !item.data.length &&
                  renderHistoryList(item?.taskCategory, 'num') == 0
                )
                  return null;
                return (
                  <div
                    className={classNames('d_card_item', {
                      active: item.taskCategory == urlstate.activeCard,
                    })}
                    key={item.taskCategory}
                    onClick={() =>
                      setUrlState({ activeCard: item.taskCategory, page: 1 })
                    }
                  >
                    <img
                      className="card_img"
                      src={
                        item.taskCategory == urlstate.activeCard
                          ? item.activePng
                          : item.defPng
                      }
                      alt=""
                    />
                    <div className="d_name">{item.name}</div>
                    <div className="d_num">
                      {item.executeNum ?? '-'}/
                      {item.data?.length +
                        renderHistoryList(item?.taskCategory, 'num') ?? '-'}
                    </div>
                  </div>
                );
              })}
            </div>
            {renderList()}
            {taskDaily1.length ? (
              <div className="d_card2">
                <div className="title">
                  今日健康宣教（{taskDaily1.length}篇）
                </div>
                {renderTaskCategory1List()}
              </div>
            ) : null}
          </div>
        ) : null}

        {!taskDaily?.num && !taskDaily1.length ? (
          <div className="empty_content">
            <Empty text="今日无待办任务" />
          </div>
        ) : null}
      </div>
      <DatePicker
        visible={visible}
        max={new Date()}
        min={
          defaultPlan?.startDateTime
            ? new Date(defaultPlan?.startDateTime)
            : undefined
        }
        onClose={() => {
          setVisible(false);
        }}
        className={classNames(`b_date_picker`, {
          activeRetract: !Number(urlstate.open),
        })}
        precision="month"
        onConfirm={(val) => {
          onDateChange(val);
        }}
      />
      <Popup
        visible={popupVisible}
        onClose={() => {
          setPopupVisible(false);
        }}
        className="b_popup"
      >
        <div className="popup_content">
          <CloseCircleOutline
            className="colos"
            onClick={() => {
              setPopupVisible(false);
            }}
          />
          <div className="popup_header">
            <span>切换服务</span>
          </div>
          <div className="popup_list">
            {planList?.map((item) => (
              <div className="plan_item" key={item.name}>
                <div className="name">{item.name}</div>
                {item.data?.map((p) => (
                  <div
                    className={classNames('plan', {
                      active_plan: p.id === defaultPlan?.id,
                    })}
                    key={p.id}
                    onClick={() => {
                      setUrlState({
                        currentPlanId: p?.id,
                        open: 0,
                        // selectDate: dayjs().format('YYYY-MM-DD'),
                        activeCard: undefined,
                        page: 1,
                      });
                      setDefaultPlan(p);
                      setPopupVisible(false);
                      setAddPage(1);
                      page.current = 1;
                    }}
                  >
                    <div className="plan_text ellipsis1">
                      {p.currentPlanPathway?.title}
                    </div>
                    {p.id === defaultPlan?.id ? (
                      <CheckCircleFill className="avtive_icon" />
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Popup>
      <Modal visible={!!warnNum}>
        <div className="warnBox">
          <div
            className="closeIcon"
            onClick={() => {
              setWarnNum(0);
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="warnIcon">
            <img src={warnIcon} alt="" />
          </div>
          <div className="warnTitle">指标异常</div>
          <div className="warnText">
            您有{warnNum}项异常指标，请及时查看处理意见
          </div>
          <div
            className="warnBtn"
            onClick={() => {
              setWarnNum(0);
              vsf?.navigateTo(
                `indicator_details?planPatientId=${defaultPlan?.planPatient?.patient?.id}&id=${warnData?.planTaskExecuteWithElementDtos?.[0]?.elementId}&type='health_care`,
              );
            }}
          >
            去查看
          </div>
        </div>
      </Modal>
      <Mask visible={step === 2}>
        <div className="step2">
          <img className="step2_img" src={step2_img} alt="" />
          <div className="step2_btn" onClick={handelStep2}>
            我知道了
          </div>
          <div className="box">
            <div className="box_con"></div>
            <div className="box_line">
              <img className="step2_text" src={step2_text} alt="" />
            </div>
          </div>
        </div>
        <div className="tabBar_item" style={{ bottom: renderSafeArea() }}>
          <img src={Slicetab} alt="" />
          <div className="tabBar_item_text">每日任务</div>
        </div>
      </Mask>
    </>
  );
};

export default definePage(DailyQuest);
