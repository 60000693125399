import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_getUserPatientList_ff0499: {
    method: 'post',
    url: '/api/order-record/get-user-patient-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: PatientManagerDetailVo462A84[]) => data,
  },
  OrderRecordController_createOrder_a974a9: {
    method: 'post',
    url: '/api/order-record/create-order',
    parameterFomatter: (data?: {
      bundleTeamId: number;
      visitPatientId: number;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrderRecordController_orderUse_87cce4: {
    method: 'post',
    url: '/api/order-record/order-use',
    parameterFomatter: (data?: { orderItemId: number; doctorId: number }) =>
      data,
    responseFormatter: (_, __, data?: CreateOrderItemUseLogOutVo03Ec0D) => data,
  },
  OrderRecordController_getUsedOrderItemLog_ac1713: {
    method: 'post',
    url: '/api/order-record/get-used-order-item-log',
    parameterFomatter: (data?: { orderItemId: number }) => data,
    responseFormatter: (_, __, data?: OrderItemUseLogOperatorVo03Ec0D) => data,
  },
  PlanPatientController_hasHistoryPlan_17096e: {
    method: 'post',
    url: '/api/rag-plan/has-history-plan',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
