import Slice15 from '@/assets/Slice15.png';
import Slice16 from '@/assets/Slice16.png';
import Slice17 from '@/assets/Slice17.png';
import Slice18 from '@/assets/Slice18.png';
import Slice19 from '@/assets/Slice19.png';
import Slice20 from '@/assets/Slice20.png';
import Slice21 from '@/assets/Slice21.png';
import Slice22 from '@/assets/Slice22.png';
import Slice23 from '@/assets/Slice23.png';
import Slice24 from '@/assets/Slice24.png';
export const STORAGE_KEY_PREFIX = 'specialist-child';

export default {};

export const CareOrderStatusEnum = {
  WAIT_PAY: '待付款',
  IN_SERVE: '服务中',
  CANCEL: '已取消',
  REFUNDING: '退款中',
  REFUNDED: '已退款',
  WAIT_EVALUATE: '待评价',
  EVALUATED: '已评价',
};

export const HeaithCareOrderStatusEnum = {
  WAIT_PAY: '待付款',
  IN_SERVE: '进行中',
  CANCEL: '已取消',
  REFUNDING: '退款中',
  REFUNDED: '已退款',
  WAIT_EVALUATE: '待评价',
  EVALUATED: '已评价',
};

export const INVOICE = [
  { label: '未开票', value: 'WAIT_INVOICE' },
  { label: '作废中', value: 'REFUNDING' },
  { label: '开票中', value: 'CREATING' },
  { label: '开票失败', value: 'FAILED' },
  { label: '已作废', value: 'REFUND' },
  { label: '开票成功', value: 'SUCCESS' },
  // { label: '未开票', value: 'CREATE' },
];

export const SERVETYPE = [
  { label: '图文咨询', value: 'GRAPHIC_CONSULTATION' },
  { label: '电话咨询', value: 'TELEPHONE_CONSULTATION' },
  { label: '复诊续方', value: 'PRESCRIPTION_CONSULTATION' },
  { label: '复诊申请', value: 'FACE_APPLY' },
  { label: '线下服务', value: 'OFFLINE_SERVE' },
  { label: '视频宣教', value: 'VIDEO_EVANGELISM' },
  { label: '哮喘随访', value: 'ASTHMA_FOLLOW' },
  { label: '哮喘日记', value: 'ASTHMA_DIARY' },
  { label: '哮喘管理', value: 'ASTHMA_MANAGER' },
  { label: '哮喘评估', value: 'ASTHMA_ASSESSMENT' },
  { label: '专病管理', value: 'SPECIALIST_MANAGER' },
];
export const orderServeImg = {
  GRAPHIC_CONSULTATION: Slice15,
  TELEPHONE_CONSULTATION: Slice18,
  PRESCRIPTION_CONSULTATION: Slice20,
  FACE_APPLY: Slice17,
  OFFLINE_SERVE: Slice19,
  VIDEO_EVANGELISM: Slice21,
  ASTHMA_FOLLOW: Slice23,
  ASTHMA_DIARY: Slice22,
  ASTHMA_MANAGER: Slice16,
  ASTHMA_ASSESSMENT: Slice24,
  SPECIALIST_MANAGER: Slice22,
};

export const TITLE = [
  { label: '主任医师', value: 'ZRYS' },
  { label: '副主任医师', value: 'FZRYS' },
  { label: '主治医师', value: 'ZZYS' },
  { label: '医师', value: 'YS' },
  { label: '主任护师', value: 'ZRHS' },
  { label: '副主任护师', value: 'FZRHS' },
  { label: '主管护师', value: 'ZGHS' },
  { label: '副主任药师', value: 'FZRMEDICAL' },
  { label: '主任药师', value: 'ZRMEDICAL' },
  { label: '护师', value: 'HS' },
  { label: '主管药师', value: 'ZGMEDICAL' },
];

export const TITLEEnum = {
  ZRYS: '主任医师',
  FZRYS: '副主任医师',
  ZZYS: '主治医师',
  YS: '医师',
  ZRHS: '主任护师',
  FZRHS: '副主任护师',
  ZGHS: '主管护师',
  FZRMEDICAL: '副主任药师',
  ZRMEDICAL: '主任药师',
  HS: '护师',
  ZGMEDICAL: '主管药师',
};

export enum ApplyStatusEnum {
  WAIT_AUDIT = '待处理',
  AUDIT_SUCCESS = '申请通过',
  CANCEL = '驳回',
  OUT_TIME_CANCEL = '超时关闭',
}

export enum ApplyStatusEnum1 {
  WAIT_AUDIT = '待审核',
  AUDIT_SUCCESS = '审核通过',
  CANCEL = '审核不通过',
  PATIENT_CANCEL = '取消',
  OUT_TIME_CANCEL = '超时关闭',
}

export enum ApplyStatusTYPE {
  WAIT_AUDIT = 'WAIT_AUDIT',
  AUDIT_SUCCESS = 'AUDIT_SUCCESS',
  CANCEL = 'CANCEL',
  OUT_TIME_CANCEL = 'OUT_TIME_CANCEL',
}

export enum GenderEnum {
  MAN = '男',
  WOMAN = '女',
}

export enum InvoiceEEnum {
  REFUNDING = '开票作废中',
  CREATING = '开票申请中',
  SUCCESS = '开票成功',
  FAILED = '开票失败  ',
  REFUND = '开票失败',
}

export enum InvoiceEnumType {
  REFUNDING = '发票正在作废中，作废完成后将进行退款',
  CREATING = '开票申请中，开票成功后，系统将为您推送发票。',
  SUCCESS = '开票成功，稍后请注意查收！',
  FAILED = '开票失败，请联系客服！  ',
  REFUND = '开票失败，请联系客服！',
}
// [
//   { label: '未开票', value: 'WAIT_INVOICE' },
//   { label: '作废中', value: 'REFUNDING' },
//   { label: '开票中', value: 'CREATING' },
//   { label: '开票失败', value: 'FAILED' },
//   { label: '已作废', value: 'REFUND' },
//   { label: '开票成功', value: 'SUCCESS' },
//   // { label: '未开票', value: 'CREATE' },
// ];

export enum TimePointEnum {
  AM = '上午',
  PM = '下午',
}
export enum ThirdServiceTYPE {
  ASTHMA = 'ASTHMA',
  SLEEP = 'SLEEP',
}
export enum ThirdServiceEnum {
  ASTHMA = '哮喘',
  SLEEP = '睡眠检测',
}

export const PAGE_SIZE = 10;

export enum TaskCategoryENUM {
  T1 = '健康宣教',
  T2 = '复诊提醒',
  T3 = '治疗干预',
  T4 = '健康日记',
  T5 = '随访问卷',
  T6 = '评估量表',
}

export const planStatus = [
  // { label: '待入经', value: 'WAIT_EXECUTE' },
  { label: '路径中', value: 'EXECUTE' },
  { label: '已出径', value: 'FINISH' },
  { label: '已中止', value: 'STOP' },
];

export enum PLANSTATUSENUM {
  WAIT_EXECUTE = '待入经',
  EXECUTE = ' 路径中',
  FINISH = '已出径',
  STOP = '已中止',
}
export enum taskExecuteENUM {
  FINISH = '已完成',
  WAIT_ADD = '待补充',
}

export enum PERSONTYPE {
  DOCTOR = '医生',
  NURSE = '护士',
  DIETITIAN = '营养师',
  THERAPIST = '治疗师',
  MEDICAL = '药师',
}

export enum customerUrlList {
  ServiceDetails = '/admin/addFrom/CreateBundleI',
  OrderDetails = '/admin/addFrom/order_details',
}

export const kefu_id = 6;

export const aichatUrl = 'https://aichat.byteawake.com';

export const aichatUrlObj = {
  produce: 'https://zbgl-aichat.chzju.cn',
  test: 'https://specialist-child-aichat.byteawake.com',
  local: 'https://aichat.byteawake.com',
};

export const aichatKefuIdObj = {
  produce: 7,
  test: 7,
  local: 7,
};

export const webUrlObj = {
  produce: 'https://zbgl.chzju.cn',
  test: 'https://specialist-child.byteawake.com',
  local: 'https://specialist-child-h5.byteawake.com',
};
