// import '@vs/vsf-mobile/dist/umd/index.css';
// import '@/assets/1700876676888728.ttf';
import '@/assets/AlibabaHealthFont20CN.ttc';
import '@/assets/fond.css';
import './style/global.less';
import './style/reset.less';
import './style/vars.less';
import './api';
import 'amis/lib/themes/cxd.css';
import 'amis/lib/helper.css';
import 'amis/sdk/iconfont.css';
import 'amis-editor-core/lib/style.css';

import { runApp } from '@vs/vsf-boot';
import { vsfMobileGlobal } from '@vs/vsf-mobile';

import routes from '@/router';
import { loadEnums } from '@/stores/dbenums';
import * as stores from '@/stores/index';

import services from './services';

import('./components').then(() => {
  loadEnums();
  runApp(
    {
      stores,
      services,
      router: {
        config: routes,
      },
    },
    vsfMobileGlobal,
  );
});
