import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, Button, Ellipsis, NavBar, Rate, SafeArea } from '@vs/vsf-mobile';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import avatarMan from '@/assets/avatar_man.png';
import avatarWoMan from '@/assets/avatar_woman.png';
import Customer from '@/assets/customer.png';
import Dom_title from '@/components/dom_title';
import EllipsisDom from '@/components/ellipsisDom';
import MajordomoPopup from '@/components/majordomo-popup';
import {
  aichatKefuIdObj,
  aichatUrl,
  aichatUrlObj,
  customerUrlList,
  kefu_id,
} from '@/config';
import {
  formatEvaluationCount,
  getWebEnv,
  jsonToBase64,
  nameHide,
} from '@/utils';

import ServiceListPopupButton from './components/serviceListPopupButton';

export const renderChatUrL = () => {
  console.log('1');
};

const ServiceDetails: React.FC = (props: any) => {
  const { routes } = props;

  const { user } = props.stores;
  const { currentUserInfo } = user;

  const [popupVisible, setPopupVisible] = useState(false);
  const [majordomoInfo, setMajordomoInfo] = useState<any>({});
  const [severInfo, setSeverInfo] = useState<any>({});
  const [evaluate, setEvaluate] = useState<any>([]);
  const [count, setCount] = useState(0);

  const idRef = useRef(routes.query?.id);
  const { doctorId } = props.routes.query || {};
  useEffect(() => {
    handleGetServeBundle();
    getPagefallByGetEvaluateByBundleQto();
  }, []);

  useEffect(() => {
    if (severInfo?.operationsManagerId) {
      handleMajordomoInfo(severInfo?.operationsManagerId);
    }
  }, [severInfo]);

  const handleClickBack = () => {
    vsf?.goBack();
  };

  const handleClickMajord = () => {
    // setPopupVisible(true);
    const data = {
      visitorProduct: {
        title: severInfo?.bundleName,
        img: severInfo?.bundleImg,
        url:
          location?.origin +
          `${customerUrlList?.ServiceDetails}?id=${idRef?.current}`,
      },
    };
    const jsonStr = JSON.stringify(data); // 将JSON对象转换为字符串
    // window.location.href =
    window.location.href = `${aichatUrlObj?.[getWebEnv()]}/chatIndex?ent_id=${aichatKefuIdObj?.[getWebEnv()]}&visitor_id=${
      currentUserInfo?.userAccount
    }&visitor_name=${JSON?.stringify({ patientName: '访客用户', userAccount: currentUserInfo?.userAccount })}&extra=${jsonToBase64(jsonStr)}`;
  };

  const handleMajordomoInfo = async (id) => {
    const res =
      await vsf?.services?.EmployeeDoctorController_getMedicalCareManagerVoById_182445(
        {
          id,
        },
      );
    if (res?.code == 200) {
      setMajordomoInfo(res?.data);
    }
  };

  const handleGetServeBundle = async () => {
    const res =
      await vsf?.services?.ServeBundleController_getServeBundleById_a3d7d9({
        id: idRef?.current,
      });
    if (res?.code == 200) {
      setSeverInfo(res?.data);
    }
  };
  const getPagefallByGetEvaluateByBundleQto = async () => {
    const res =
      await vsf?.services?.OrderRecordController_getPagedByGetEvaluateByBundleQto_75ca28(
        {
          qto: {
            serveBundleIdIs: idRef?.current,
            size: 1,
            from: 0,
          },
        },
      );
    if (res?.code == 200) {
      setEvaluate(res?.data?.result);
      setCount(res?.data?.count);
    }
  };

  return (
    <>
      <NavBar children="服务详情" backArrow onBack={handleClickBack} />
      <Dom_title title="服务详情" />
      <div className="service-details-container">
        <div className="content">
          <img
            className="header-img"
            src={severInfo?.bundleDetailImg?.[0]}
          ></img>
          <div className="details">
            <div className="service-details">
              <div className="header-title">{severInfo?.bundleName}</div>
              <div className="text">
                <span className="title">服务介绍：</span>
                {severInfo?.bundleDescr}
              </div>
              <div className="num">
                <span className="title">服务周期：</span>
                {severInfo?.servePeriod ?? 0}天
              </div>
            </div>
          </div>
          {evaluate?.length ? (
            <div className="evaluate">
              <div className="evaluate_header">
                <div className="evaluate_header_left">
                  评价({formatEvaluationCount(count)})
                </div>
                <div
                  className="evaluate_header_right"
                  onClick={() => {
                    vsf?.navigateTo(`/appraise_details?id=${idRef?.current}`);
                  }}
                >
                  查看全部
                  <RightOutline />
                </div>
              </div>

              <div className="evaluate_content">
                <div className="appraise_card">
                  <div className="appraise_card_header">
                    <div className="avatar">
                      <img
                        src={
                          evaluate[0]?.visitPatient?.patientGender === 'WOMAN'
                            ? avatarWoMan
                            : avatarMan
                        }
                        alt=""
                      />
                    </div>
                    <div className="user">
                      <div className="name">
                        {evaluate[0]?.visitPatient?.patientName
                          ? nameHide(evaluate[0]?.visitPatient?.patientName)
                          : '-'}
                      </div>
                      <div className="star">
                        <Rate
                          style={{
                            '--star-size': '14px',
                            '--active-color': '#ff7f7f',
                          }}
                          readOnly
                          value={evaluate[0]?.orderEvaluateDto?.evaluatePoint}
                        />
                      </div>
                    </div>
                    <div className="date">
                      {/* {severInfo?.orderEvaluateDto?.createdAt
                      ? dayjs(severInfo?.orderEvaluateDto?.createdAt).format(
                          'YYYY-MM-DD',
                        )
                      : '-'} */}
                    </div>
                  </div>
                  <div className="appraise_card_content">
                    <EllipsisDom
                      content={
                        evaluate[0]?.orderEvaluateDto?.evaluateContent ?? '-'
                      }
                      rows={3}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {severInfo?.bundleDetailImg?.map((v, i) => {
            return i > 0 && <img className="details-img" src={v}></img>;
          })}
        </div>
        <div className="bottom">
          <div className="left" onClick={handleClickMajord}>
            <img src={Customer} className="icon"></img>
            <div className="text">联系客服</div>
          </div>
          <ServiceListPopupButton
            className="rigth"
            serveBundleId={idRef?.current}
            experienceFlag={severInfo?.experienceFlag}
            doctorId={doctorId}
          >
            立即购买
          </ServiceListPopupButton>
        </div>
        <MajordomoPopup
          visible={popupVisible}
          onClosePopup={() => {
            setPopupVisible(false);
          }}
          title={majordomoInfo?.doctorName}
          phone={majordomoInfo?.phone}
          codeUrl={majordomoInfo?.wechatQrImg}
        ></MajordomoPopup>
      </div>
    </>
  );
};

export default definePage(ServiceDetails);
