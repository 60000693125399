import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_getDoctorOrderList_83dec2: {
    method: 'post',
    url: '/api/order-record/get-doctor-order-list',
    parameterFomatter: (data?: { orderStatus: OrderStatusEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrderRecordTeamPatientVo03Ec0D[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
