import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar } from '@vs/vsf-mobile';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import React, { useEffect, useRef, useState } from 'react';

import Dom_title from '@/components/dom_title';
const size = 20;
const ServiceList: React.FC = (props: any) => {
  const { routes } = props;

  const [list, setList] = useState<ServeBundleVoF1Ba03[]>([]);
  const [serviceInfo, setServiceInfo] =
    useState<SpecialistCategoryAmountVoF1Ba03>({});

  const idRef = useRef(routes.query?.id);
  const scrollIdRef = useRef<any>(null);
  const count = useRef<number>(0);
  const hasMore = useRef<boolean>(false);

  useEffect(() => {
    handleGetServiceInfo(idRef?.current);
    handleGetServiceList(false, scrollIdRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetServiceList = async (isScroll = false, scrollId) => {
    const res =
      await vsf?.services?.ServeBundleController_getFallServeBundle_286f2c({
        qto: {
          scrollId: scrollId,
          size,
          specialistIdIs: idRef?.current,
        },
      });
    if (res?.code == 200) {
      const data = res?.data;
      scrollIdRef.current = data?.scrollId;
      count.current = data?.count;
      hasMore.current = data?.hasMore;
      setList(isScroll ? [...list, ...data.result] : data?.result);
    }
  };

  const handleJump = (v) => {
    vsf?.navigateTo(`/serviceDetails?id=${v?.id}`);
  };

  const handleMore = async () => {
    handleGetServiceList(true, scrollIdRef?.current);
  };

  const handleGetServiceInfo = async (id) => {
    const res =
      await vsf?.services?.SpecialistCategoryController_getSpecialistCategoryAmountById_68823b(
        {
          id,
        },
      );
    if (res?.code == 200) {
      setServiceInfo(res?.data);
    }
  };

  return (
    <>
      <NavBar children="服务列表" backArrow />
      <Dom_title title="服务列表" />
      <div className="service-list-container">
        <div className="service-header">
          <img className="header-logo" src={serviceInfo?.titleImg}></img>
          <div className="header-text">
            {serviceInfo?.specialistName}（
            {serviceInfo?.specialistBundleAmountDto?.bundleAmount ?? 0}项）
          </div>
        </div>
        <div className="service-list-content">
          {list?.map((v, i) => (
            <div
              className="service-item"
              key={i}
              onClick={() => {
                handleJump(v);
              }}
            >
              <img className="item-logo" src={v?.bundleImg}></img>
              <div className="item-content">
                <div className="item-name">{v?.bundleName}</div>
                <div className="item-text">{v?.bundleDescr}</div>
              </div>
              <RightOutline color="#7C8797" fontSize={18}></RightOutline>
            </div>
          ))}
        </div>
        {/* <div className="service-bottom">
          <div
            className="service-btn"
            onClick={() => vsf?.navigateTo('/order_list')}
          >
            <FileOutline />
            <div className="btn-name">我的服务记录</div>
            <RightOutline />
          </div>
        </div> */}
        <InfiniteScroll loadMore={handleMore} hasMore={hasMore.current} />
      </div>
    </>
  );
};

export default definePage(ServiceList);
