import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, NavBar, Tabs } from '@vs/vsf-mobile';
import React, { useState } from 'react';

import Dom_title from '@/components/dom_title';

import OrderListDom from './components/orderListDom';
const tabList = [
  {
    title: '全部',
    value: 'ALL',
    children: <OrderListDom />,
  },
  {
    title: '进行中',
    value: 'IN_SERVE',
    children: <OrderListDom orderStatusIs="IN_SERVE" />,
  },
  {
    title: '待评价',
    value: 'WAIT_EVALUATE',
    children: <OrderListDom orderStatusIs="WAIT_EVALUATE" />,
  },
  {
    title: '已评价',
    value: 'EVALUATED',
    children: <OrderListDom orderStatusIs="EVALUATED" />,
  },
  {
    title: '已取消',
    value: 'CANCEL',
    children: <OrderListDom orderStatusIs="CANCEL" />,
  },
];

const OrderList: any = (props) => {
  const { activeKey } = props.routes?.query || {};
  const [urlstate, setUrlState] = useUrlState(
    {
      activeKey: activeKey || 'ALL',
    },
    { navigateMode: 'replace' },
  );

  const handleClickBack = () => {
    vsf?.goBack();
  };

  return (
    <>
      <NavBar children="我的专科服务" backArrow onBack={handleClickBack} />
      <Dom_title title="我的专科服务" />
      <div className="health_care_order_list">
        <Tabs
          className="tabs"
          activeKey={urlstate?.activeKey || 'ALL'}
          onChange={(activeKey) => setUrlState({ activeKey })}
        >
          {tabList.map((item) => {
            return (
              <Tabs.Tab title={item.title} key={item.value}>
                {item.children}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default definePage(OrderList);
