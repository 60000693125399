import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  Button,
  Form,
  InfiniteScroll,
  NavBar,
  Popup,
  Tabs,
  TextArea,
} from '@vs/vsf-mobile';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import empty from '@/assets/empty.png';
import Dom_title from '@/components/dom_title';
import {
  ApplyStatusEnum,
  ApplyStatusTYPE,
  GenderEnum,
  TimePointEnum,
} from '@/config';
const size = 20;
const Interview: React.FC = (props: any) => {
  const { routes } = props;
  const [list, setList] = useState<VisitApplyPatientDoctorVoF6A530[]>([]);
  const [activeKey, setActiveKey] = useState('Interview');
  const [visible, setVisible] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const scrollIdRef = useRef<any>(null);
  const count = useRef<number>(0);
  const hasMore = useRef<boolean>(false);
  const from = useRef<number>(0);

  const idRef = useRef(routes.query?.id ?? 2401);

  const [form] = Form.useForm();

  useEffect(() => {
    if (activeKey == 'Interview') {
      handleList(false, scrollIdRef?.current, ['WAIT_AUDIT']);
    } else {
      handleList(false, scrollIdRef?.current, [
        'AUDIT_SUCCESS',
        'CANCEL',
        'OUT_TIME_CANCEL',
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  const handleList = async (isScroll = false, scrollId, applyStatus) => {
    const res =
      await vsf?.services?.VisitApplyController_getPagedByGetApplyByDoctorStatusQto_9007bf(
        {
          qto: {
            scrollId: scrollId,
            size,
            doctorIdIs: idRef?.current,
            applyStatusIn: applyStatus,
            from: from.current,
          },
        },
      );
    if (res?.code == 200) {
      const data = res?.data;
      const value = isScroll ? [...list, ...data.result] : data?.result;
      from.current = value?.length;
      // scrollIdRef.current = data?.scrollId;
      count.current = data?.count;
      // hasMore.current = data?.hasMore;

      setList(isScroll ? [...list, ...data.result] : data?.result);
    }
  };

  const handleupdateStatus = async (value) => {
    const res =
      await vsf?.services?.VisitApplyController_updateStatusVisitApply_4af036({
        btoParam: {
          ...value,
        },
      });
    setBtnLoading(false);
    scrollIdRef.current = null;
    count.current = 0;
    from.current = 0;
    handleList(false, scrollIdRef?.current, ['WAIT_AUDIT']);
  };

  const handleClick = () => {
    form?.submit();
  };

  const handleFinish = async (value) => {
    await handleupdateStatus({
      ...currentData,
      ...value,
      applyStatus: 'CANCEL',
    });
    setVisible(false);
  };

  const handleTabsChange = (value) => {
    scrollIdRef.current = null;
    count.current = 0;
    from.current = 0;
    setList([]);
    setActiveKey(value);
  };

  const handleMore = async () => {
    if (activeKey == 'Interview') {
      handleList(true, scrollIdRef?.current, ['WAIT_AUDIT']);
    } else {
      handleList(true, scrollIdRef?.current, [
        'AUDIT_SUCCESS',
        'CANCEL',
        'OUT_TIME_CANCEL',
      ]);
    }
  };

  const renderEmpty = () => {
    return (
      <div className="empty">
        <img src={empty} className="img"></img>
        <div className="empty-text">暂无数据</div>
      </div>
    );
  };

  const renderItem = (data) => {
    return (
      <div className="item">
        <div className="item-header">
          <div className="left">
            {' '}
            <div className="name">{data?.visitPatient?.patientName}</div>
            <div className="age">
              {GenderEnum?.[data?.visitPatient?.patientGender]}
            </div>
            <div className="age">{data?.visitPatient?.age}岁</div>
          </div>
          <div className="rigth">
            {activeKey != 'Interview' && (
              <div
                className={classNames(``, {
                  [data?.applyStatus]: true,
                  red:
                    data?.applyStatus == ApplyStatusTYPE?.['CANCEL'] ||
                    data?.applyStatus == ApplyStatusTYPE?.['OUT_TIME_CANCEL'],
                })}
              >
                {ApplyStatusEnum?.[data?.applyStatus]}
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(`item-content`, {
            record: activeKey == 'record',
          })}
        >
          <div className="content-item">
            <div className="item-title">来源服务包</div>
            <div className="item-text">{data?.order?.bundleName}</div>
          </div>
          <div className="content-item">
            <div className="item-title">联系电话</div>
            <div className="item-text">{data?.phone}</div>
          </div>
          <div className="content-item">
            <div className="item-title">号别</div>
            <div className="item-text">{data?.thirdRegName}</div>
          </div>
          <div className="content-item">
            <div className="item-title">院区</div>
            <div className="item-text">{data?.hospitalName}</div>
          </div>
          <div className="content-item">
            <div className="item-title">就诊时间</div>
            <div className="item-text">
              {dayjs(data?.visitDate)?.format('YYYY-MM-DD')}{' '}
              {TimePointEnum?.[data?.timePoint]}
            </div>
          </div>
          <div className="content-item">
            <div className="item-title">申请原因</div>
            <div className="item-text">{data?.reason}</div>
          </div>
          {activeKey == 'record' &&
            data?.applyStatus == ApplyStatusTYPE?.['CANCEL'] && (
              <div className="content-item">
                <div className="item-title red">驳回原因</div>
                <div className="item-text red">{data?.cancelReason}</div>
              </div>
            )}
          {/* Interview */}
        </div>
        {activeKey == 'Interview' && (
          <div className="item-bottom">
            <div
              className="btn onlinBtn"
              onClick={() => {
                setCurrentData(data);
                setVisible(true);
                // handleupdateStatus({ ...data, applyStatus: 'CANCEL' });
              }}
            >
              驳回
            </div>
            <Button
              className="btn"
              loading={btnLoading}
              onClick={() => {
                setBtnLoading(true);
                // run({ ...data, applyStatus: 'AUDIT_SUCCESS' });
                handleupdateStatus({ ...data, applyStatus: 'AUDIT_SUCCESS' });
              }}
            >
              确定
            </Button>
          </div>
        )}
      </div>
    );
  };

  const tabList = [
    {
      title: '待处理',
      value: 'Interview',
      children: (
        <div className="tab1">
          {list?.length == 0 ? (
            renderEmpty()
          ) : (
            <>
              {list?.map((v) => renderItem(v))}
              <InfiniteScroll
                loadMore={handleMore}
                hasMore={list?.length < count?.current && count?.current > 0}
                children="我是有底线的~"
              />
            </>
          )}
        </div>
      ),
    },
    {
      title: '已处理',
      value: 'record',
      children: (
        <div className="tab1">
          {list?.length == 0 ? (
            renderEmpty()
          ) : (
            <>
              {list?.map((v) => renderItem(v))}
              <InfiniteScroll
                loadMore={handleMore}
                hasMore={list?.length < count?.current && count?.current > 0}
                children="我是有底线的~"
              />
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <NavBar children="复诊申请" backArrow />
      <Dom_title title="复诊申请" />
      <div className="interview-container">
        <Tabs
          className="tabs"
          activeKey={activeKey}
          onChange={handleTabsChange}
        >
          {tabList.map((item) => {
            return (
              <Tabs.Tab title={item.title} key={item.value}>
                {item.children}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        className="interview-popup"
        bodyClassName="popup-content"
      >
        <div className="popup-header">
          <div className="btn" onClick={() => setVisible(false)}>
            取消
          </div>
          <div className="title">驳回原因</div>
          <div className="btn determine" onClick={handleClick}>
            确定
          </div>
        </div>
        <div className="content">
          <Form
            layout="horizontal"
            footer={false}
            form={form}
            onFinish={handleFinish}
          >
            <Form.Item
              name="cancelReason"
              label=""
              rules={[{ required: true, message: '请输入审核不通过原因' }]}
            >
              <TextArea placeholder="审核不通过原因" autoSize rows={5} />
            </Form.Item>
          </Form>
        </div>
      </Popup>
    </>
  );
};

export default definePage(Interview);
