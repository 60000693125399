import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, CenterPopup, Grid, NavBar, Swiper, Toast } from '@vs/vsf-mobile';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import files from '@/assets/files.png';
import housekeep from '@/assets/housekeep.png';
import indexBg from '@/assets/index_bg.png';
import index2 from '@/assets/index2.png';
import index3 from '@/assets/index3.png';
import logo from '@/assets/logo.png';
import NewIndexBg from '@/assets/new_index_bg.png';
import record from '@/assets/record.png';
import sleep from '@/assets/sleep.png';
import DomTitle from '@/components/dom_title';
import MajordomoPopup from '@/components/majordomo-popup';

const Index: React.FC<any> = (props) => {
  const [isHeaderRetract, setisHeaderRetract] = useState(false);
  const [list, setList] = useState<any>([]);
  const [shallList, setShallList] = useState([]);
  const [currentService, setCurrentService] = useState<any>(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [majordomoInfo, setMajordomoInfo] = useState<any>({});

  useEffect(() => {
    handleGetList();
    handleGetShallList();
    handleMajordomoInfo();
  }, []);

  const popularList = [
    { name: '哈哈哈', num: 4, list: [{}, {}, {}] },
    { name: '哈哈哈', num: 4, list: [{}] },
    { name: '哈哈哈', num: 4, list: [{}, {}] },
  ];

  const handleClickRetract = () => {
    setisHeaderRetract(!isHeaderRetract);
  };

  const handleGetList = async () => {
    const res =
      await vsf?.services?.SpecialistCategoryController_getInstitutionSpecialistCategory_566775();
    if (res?.code == 200) {
      setList(res?.data);
    }
  };

  const handleMajordomoInfo = async () => {
    const res =
      await vsf?.services?.EmployeeDoctorController_getListMedicalCareManagerVoByIsCustomerService_2a33ea(
        {
          isCustomerService: 1,
        },
      );
    if (res?.code == 200) {
      setMajordomoInfo(res?.data);
    }
  };

  const handleGetShallList = async () => {
    const res =
      await vsf?.services?.SpecialistCategoryController_getSpecialistCategoryPopularBundle_8b58c4();
    if (res?.code == 200) {
      setShallList(res?.data);
      setCurrentService(res?.data?.[0]);
    }
  };

  const handleClickMajordomo = () => {
    setPopupVisible(true);
  };

  const handleJumpList = (value) => {
    vsf?.navigateTo(`/service_list?id=${value?.id}`);
  };

  const handleClickJump = (src, id) => {
    vsf?.navigateTo(`${src}?id=${id}`);
  };

  const renderHeaderListDom = (value) => {
    return value?.map((item) => {
      return (
        <div
          // className="header-item"
          className={classNames(`header-item`, {
            activeItem: currentService?.id == item?.id,
          })}
          key={item?.id}
          onClick={() => {
            setCurrentService(item);
          }}
        >
          <div className="item-text">{renderStr(item?.specialistName)}</div>
        </div>
      );
    });
  };

  const renderStr = (value) => {
    if (value?.length > 6) {
      return `${value?.substring(0, 6)}...`;
    }
    return value;
  };

  const renerPopularList = (value) => {
    return (
      <div
        className="popular-item"
        onClick={() => {
          vsf?.navigateTo(`/serviceDetails?id=${value?.id}`);
        }}
      >
        <div className="popular-content">
          <div className="popular-content-item">
            <img className="popular-img" src={value?.bundleImg}></img>
            <div className="item-content">
              <div className="content-name">{value?.bundleName}</div>
              <div className="content-text">{value?.bundleDescr}</div>
            </div>
            <div className="iocn">
              <RightOutline
                color="#fff"
                fontSize={15}
                onClick={() => {
                  vsf?.navigateTo(`/serviceDetails?id=${value?.id}`);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar children="服务优选" backArrow />
      <DomTitle title="服务优选" />
      <div className="index-container">
        <div className="header-bg">
          <img src={NewIndexBg} className="img-bg"></img>
        </div>
        <div className="index-header">
          <img className="index-logo" src={logo}></img>
          {/* <div className="index-header-title">特色专病专科</div>
          <div className="index-text">在线名医随时问 全程照护更安心</div> */}
        </div>
        <div className="service content-item">
          {/* <Swiper
            loop
            autoplay
            onIndexChange={(i) => {
              console.log(i, 'onIndexChange1');
            }}
            className="Swiper"
          >
            <Swiper.Item key={1}>
              <img className="index-div" src={sleep}></img>
            </Swiper.Item>
          </Swiper> */}
          <img
            className="index-div"
            src={sleep}
            onClick={() => handleClickJump('/serviceDetails', 3313)}
          ></img>
          <div className="service-content">
            <div className="service-item">
              <img
                src={index2}
                onClick={() => handleClickJump('/service_list', 9)}
              ></img>
            </div>
            <div className="service-item">
              <img
                src={index3}
                onClick={() => handleClickJump('/serviceDetails', 3317)}
              ></img>
            </div>
          </div>
        </div>
        <div className="first content-item">
          <div
            className={classNames(`first-content`, {
              activeRetract: isHeaderRetract,
            })}
          >
            <div className="index-title">特色专区</div>
            <Grid columns={4} gap={3}>
              {list?.map((v, i) => (
                <Grid.Item key={i}>
                  <div
                    className="grid-item"
                    onClick={() => {
                      handleJumpList(v);
                    }}
                  >
                    <img src={v?.titleImg} className="item-img"></img>
                    <div className="item-name">{v?.specialistName}</div>
                  </div>
                </Grid.Item>
              ))}
            </Grid>
          </div>
        </div>
        <div className="third">
          <div className="index-title">优选服务</div>
          <div className="third-content">
            <div className="third-header">{renderHeaderListDom(shallList)}</div>
            {currentService?.popularServeBundleList?.map((v) =>
              renerPopularList(v),
            )}
          </div>
        </div>
        <MajordomoPopup
          visible={popupVisible}
          onClosePopup={() => {
            setPopupVisible(false);
          }}
          title={majordomoInfo?.[0]?.doctorName}
          phone={majordomoInfo?.[0]?.phone}
          codeUrl={majordomoInfo?.[0]?.wechatQrImg}
        ></MajordomoPopup>
      </div>
    </>
  );
};

export default definePage(Index);
