import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  Button,
  InfiniteScroll,
  NavBar,
  PullToRefresh,
  Rate,
  SafeArea,
} from '@vs/vsf-mobile';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import avatarMan from '@/assets/avatar_man.png';
import avatarWoMan from '@/assets/avatar_woman.png';
import Customer from '@/assets/customer.png';
import Dom_title from '@/components/dom_title';
import EllipsisDom from '@/components/ellipsisDom';
import MajordomoPopup from '@/components/majordomo-popup';
import { PAGE_SIZE } from '@/config';
import { formatEvaluationCount, nameHide } from '@/utils';

import ServiceListPopupButton from './components/serviceListPopupButton';

const ServiceDetails: React.FC = (props: any) => {
  const { routes } = props;

  const [popupVisible, setPopupVisible] = useState(false);
  const [majordomoInfo, setMajordomoInfo] = useState<any>({});
  const [severInfo, setSeverInfo] = useState<any>({});
  const [evaluate, setEvaluate] = useState<any>([]);
  const [hasMore, setHasMore] = useState(false);
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(0);

  const idRef = useRef(routes.query?.id);

  useEffect(() => {
    handleGetServeBundle();
    getPagefallByGetEvaluateByBundleQto(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (severInfo?.operationsManagerId) {
      handleMajordomoInfo(severInfo?.operationsManagerId);
    }
  }, [severInfo]);

  const handleClickBack = () => {
    vsf?.goBack();
  };

  const handleClickMajord = () => {
    setPopupVisible(true);
  };

  const handleMajordomoInfo = async (id) => {
    const res =
      await vsf?.services?.EmployeeDoctorController_getMedicalCareManagerVoById_182445(
        {
          id,
        },
      );
    if (res?.code == 200) {
      setMajordomoInfo(res?.data);
    }
  };

  const handleGetServeBundle = async () => {
    const res =
      await vsf?.services?.ServeBundleController_getServeBundleById_a3d7d9({
        id: idRef?.current,
      });
    if (res?.code == 200) {
      setSeverInfo(res?.data);
    }
  };
  const getPagefallByGetEvaluateByBundleQto = async (reload) => {
    const res =
      await vsf?.services?.OrderRecordController_getPagedByGetEvaluateByBundleQto_75ca28(
        {
          qto: {
            serveBundleIdIs: idRef?.current,
            size: PAGE_SIZE,
            from: reload ? 0 : from * PAGE_SIZE ?? 0,
          },
        },
      );
    /* eslint-disable */
    if (res?.code == 200) {
      setEvaluate(reload ? res?.data?.result ?? [] : [...evaluate, ...res?.data?.result]);
      setCount(res.data.count);
      setHasMore(res.data.result.length === PAGE_SIZE);
      setFrom(reload ? 1 : from + 1);
      return res.data?.result;
    } else {
      setHasMore(false);
    }
    /* eslint-enable */
  };
  return (
    <>
      <NavBar children="用户评价" backArrow onBack={handleClickBack} />
      <Dom_title title="用户评价" />
      <div className="service-details-container">
        <PullToRefresh
          onRefresh={() => getPagefallByGetEvaluateByBundleQto(true)}
        >
          <div className="appraise_num">
            评价({formatEvaluationCount(count)})
          </div>
          <div className="list">
            {evaluate?.map((item) => {
              return (
                <div className="appraise_card" key={item.id}>
                  <div className="appraise_card_header">
                    <div className="avatar">
                      <img
                        src={
                          item?.visitPatient?.patientGender === 'WOMAN'
                            ? avatarWoMan
                            : avatarMan
                        }
                        alt=""
                      />
                    </div>
                    <div className="appraise_card_header_right">
                      <div className="top">
                        <div className="user">
                          <div className="name">
                            {item.visitPatient?.patientName
                              ? nameHide(item.visitPatient?.patientName)
                              : '-'}
                          </div>
                        </div>
                        <div className="date">
                          {item?.orderEvaluateDto?.createdAt
                            ? dayjs(item?.orderEvaluateDto?.createdAt).format(
                                'YYYY-MM-DD',
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="star">
                        <Rate
                          style={{
                            '--star-size': '14px',
                            '--active-color': '#ff7f7f',
                          }}
                          readOnly
                          value={item.orderEvaluateDto?.evaluatePoint}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="appraise_card_content">
                    <EllipsisDom
                      content={item?.orderEvaluateDto?.evaluateContent ?? '-'}
                      rows={3}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <InfiniteScroll
            children="我是有底线的~"
            loadMore={getPagefallByGetEvaluateByBundleQto}
            hasMore={hasMore}
            className="InfiniteScroll_box"
          />
        </PullToRefresh>
        <div className="bottom">
          <div className="left" onClick={handleClickMajord}>
            <img src={Customer} className="icon"></img>
            <div className="text">联系客服</div>
          </div>
          <ServiceListPopupButton
            className="rigth"
            serveBundleId={idRef?.current}
          >
            立即购买
          </ServiceListPopupButton>
        </div>
        <MajordomoPopup
          visible={popupVisible}
          onClosePopup={() => {
            setPopupVisible(false);
          }}
          title={majordomoInfo?.doctorName}
          phone={majordomoInfo?.phone}
          codeUrl={majordomoInfo?.wechatQrImg}
        ></MajordomoPopup>
      </div>
    </>
  );
};

export default definePage(ServiceDetails);
