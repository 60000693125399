import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Grid,
  Mask,
  NavBar,
  Rate,
  SpinLoading,
  TextArea,
} from '@vs/vsf-mobile';
import { useDocumentVisibility } from 'ahooks';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
// 时间插件
import duration from 'dayjs/plugin/duration';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import avatarMan from '@/assets/avatar_man.png';
import avatarWoMan from '@/assets/avatar_woman.png';
import doctorImg from '@/assets/doctor.png';
import Slice25 from '@/assets/Slice25.png';
import Slice26 from '@/assets/Slice26.png';
import Slice36 from '@/assets/Slice36.png';
import Slice38 from '@/assets/Slice38.png';
import step1_img from '@/assets/step/step1_img.png';
import Dom_title from '@/components/dom_title';
import EllipsisDom from '@/components/ellipsisDom';
import MajordomoPopup from '@/components/majordomo-popup';
import {
  aichatUrlObj,
  customerUrlList,
  GenderEnum,
  orderServeImg,
} from '@/config';
import {
  aichatKefuIdObj,
  aichatUrl,
  CareOrderStatusEnum,
  kefu_id,
  ThirdServiceTYPE,
} from '@/config';
import {
  getENVIR,
  getIntegerAndDecimal,
  getUrlConfig,
  getWebEnv,
  isJSON,
  isNumber,
  jsonToBase64,
  nameHide,
} from '@/utils';

import ServiceListPopupButton from './components/serviceListPopupButton';
// 微信小程序支付参数
interface appletPayParams {
  thirdOrderNo: string; // 订单号
  orderId: string; // 订单号
  payNum: string; // 金额
  tradeNo?: string; // 流水号
  pageType: string; //
}
dayjs.extend(duration);
const SpecialistSerrviceDetail = (props) => {
  const [value, setValue] = useState<any>();
  const [star, setStar] = useState<any>();
  const [data, setData] = useState<DoctorOrderServeVo03Ec0D | any>({});
  const [time, setTime] = useState<any>(); //倒计时时间
  const timeRef = useRef<any>(); //设置延时器
  const [popupVisible, setPopupVisible] = useState(false);
  const [majordomoInfo, setMajordomoInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [env, setEnv] = useState<any>({});
  const [thirdService, setThirdService] = useState<any>({});
  // const [maskLoading, setMaskLoading] = useState(false);
  const documentVisibility = useDocumentVisibility();
  const { user, plan } = props.stores;
  const { planData, setPlanData, setStep, step } = plan;
  const { currentUserInfo } = user;

  //倒计时
  useEffect(() => {
    if (time < 0) {
      return setTime(null);
    }
    if (time === 0) {
      getPatientOrderServeById();
      return;
    }
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const handleClickBack = () => {
    if (!planData) setPlanData();
    vsf?.goBack();
  };

  const { orderId, orderStatus } = props.routes?.query || {};
  const getPatientOrderServeById = async () => {
    const res =
      await vsf.services.OrderRecordController_getPatientOrderServeById_b4f0a3({
        id: Number(orderId),
      });
    if (res.code === 200) {
      console.log(orderStatus);
      // 轮询接口 5次
      if (
        (orderStatus == 'true' || time === 0) &&
        res.data?.orderStatus === 'WAIT_PAY'
      ) {
        // !maskLoading && setMaskLoading(true);
        return setTimeout(() => {
          getPatientOrderServeById();
        }, 500);
      }
      // maskLoading && setMaskLoading(false);
      if (!planData) setPlanData();
      setData(res.data ?? {});
      if (res.data?.submitDateTime && res.data?.orderStatus === 'WAIT_PAY') {
        try {
          setTime(
            dayjs(dayjs(res.data.submitDateTime).add(15, 'minutes')).diff(
              dayjs(),
              'seconds',
            ),
          );
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setData({ orderStatus: '1' });
      // vsf.showToast('系统错误')
    }
  };
  // 获取流水号
  const getTradeNo = async () => {
    setLoading(true);
    const res = await vsf.services.OrderPayController_getTradeNo_9f9f57({
      orderId,
    });
    setLoading(false);
    if (res.code === 200) {
      const appletPayData: appletPayParams = {
        thirdOrderNo: res.data?.orderId, // 订单号
        orderId: res.data?.orderId, // 订单号
        payNum: data?.charge, // 金额
        tradeNo: res.data?.tradeNo, // 流水号
        pageType: 'package', //
      };
      wxPay(appletPayData);
    } else {
      // vsf.showToast('创建订单失败!', 'error');
    }
  };
  // 取消订单
  const cancelOrder = async () => {
    setLoading1(true);
    const res = await vsf.services.OrderRecordController_cancelOrder_f1eef1({
      orderId,
    });
    setLoading1(false);
    if (res.code === 200) {
      getPatientOrderServeById();
    } else {
      // vsf.showToast('创建订单失败!', 'error');
    }
  };
  const wxPay = (data) => {
    const searchStr = new URLSearchParams(data).toString();
    window.uni?.redirectTo({
      url: `/pages/common/pay-select-way-2/pay-select-way-2?${searchStr}`,
      // url: `/pages/common/pay-select-way-2/pay-select-way-2?${encodeURIComponent(JSON.stringify(data))}`,
    });
  };

  const handleMajordomoInfo = async (id) => {
    const res =
      await vsf?.services?.EmployeeDoctorController_getMedicalCareManagerVoById_182445(
        {
          id,
        },
      );
    if (res?.code == 200) {
      setMajordomoInfo(res?.data);
    }
  };
  // 新手引导
  const hasHistoryPlan = async () => {
    const res =
      await vsf.services?.PlanPatientController_getAccountDefaultPlan_700494({
        orderId,
      });
    if (res.code === 200 && res.data) {
      const data =
        await vsf?.services?.PlanPatientController_hasHistoryPlan_17096e({
          orderId,
        });
      if (data?.code == 200 && data.data === false) {
        setStep(1);
      }
    }
  };
  const handleClickMajord = () => {
    // setPopupVisible(true);
    const patientName = data?.visitPatient?.patientName;
    const value = {
      visitorProduct: {
        title: `订单编号：${data?.orderNumber}<br/>订单名称：${data?.bundleName}<br/>患者姓名：${patientName}`,
        img: data?.bundleImg,
        url:
          location?.origin + `${customerUrlList?.OrderDetails}?id=${orderId}`,
      },
    };
    const jsonStr = JSON.stringify(value); // 将JSON对象转换为字符串
    console.log(data, value, location, 'data');
    const visitPatient = {
      age: data?.age,
      phone: data?.visitPatient?.phone,
      sex: GenderEnum[data?.visitPatient?.patientGender],
    };
    // window.location.href =
    window.location.href = `${aichatUrlObj?.[getWebEnv()]}/chatIndex?ent_id=${aichatKefuIdObj?.[getWebEnv()]}&visitor_id=${orderId}&visitor_name=${JSON?.stringify({ patientName, userAccount: currentUserInfo?.userAccount, bundleName: data?.bundleName })}&extra=${jsonToBase64(jsonStr)}&kefu_id=${
      data?.serveBundle?.operationsManager?.accountNumber ?? ''
    }`;
  };

  const handleClickMajords = () => {
    // setPopupVisible(true);
    const patientName = data?.visitPatient?.patientName;
    const value = {
      visitorProduct: {
        title: `订单编号：${data?.orderNumber}<br/>订单名称：${data?.bundleName}<br/>患者姓名：${patientName}`,
        img: data?.bundleImg,
        url:
          location?.origin + `${customerUrlList?.OrderDetails}?id=${orderId}`,
      },
    };
    const jsonStr = JSON.stringify(value); // 将JSON对象转换为字符串
    console.log(data, value, location, 'data');
    const visitPatient = {
      age: data?.age,
      phone: data?.visitPatient?.phone,
      sex: GenderEnum[data?.visitPatient?.patientGender],
    };
    // window.location.href = `${aichatUrlObj?.[getWebEnv()]}/chatIndex?ent_id=${aichatKefuIdObj?.[getWebEnv()]}&visitor_id=${JSON.stringify(
    //   visitPatient,
    // )}&visitor_name=${JSON?.stringify({ patientName, userAccount: currentUserInfo?.userAccount, bundleName: data?.bundleName })}&extra=${jsonToBase64(jsonStr)}`;
    window.location.href = `${aichatUrlObj?.[getWebEnv()]}/chatIndex?ent_id=${aichatKefuIdObj?.[getWebEnv()]}&visitor_id=${orderId}&visitor_name=${JSON?.stringify({ patientName, userAccount: currentUserInfo?.userAccount, bundleName: data?.bundleName })}&extra=${jsonToBase64(jsonStr)}`;
  };

  const handleClickInvoice = async (data) => {
    const res = await vsf?.services?.OrderRecordController_getInvoice_2d81e4({
      orderId: orderId,
      // orderId: 19701,
      // 19701
    });
    if (res?.code === 200) {
      const value = res?.data;
      if (!value)
        return vsf.navigateTo(
          `/invoice?orderId=${orderId}&price=${data?.roundingCharge}&invoiceStatus=${value?.invoiceStatus}`,
        );
      vsf.navigateTo(
        `/invoice_success?orderId=${orderId}&invoiceStatus=${value?.invoiceStatus}&price=${data?.roundingCharge}`,
      );
    }
  };

  const submitAppraise = async () => {
    console.log('评价内容', _.trim(value));
    if (!_.trim(value) || !star) {
      return vsf.showToast('评价内容不能为空！', 'error');
    }
    const res =
      await vsf?.services?.OrderRecordController_createOrderEvaluate_aafd09({
        btoParam: {
          orderId,
          evaluatePoint: star,
          evaluateContent: value,
        },
        extApiParam: {},
      });
    if (res?.code == 200) {
      getPatientOrderServeById();
      // getOrderRecordEvaluateVoById();
    }
  };

  // 睡眠检测
  const toSleep = async () => {
    const value = {
      channel: 'h5-C009',
      userPhone: data.visitPatient?.phone,
      patidNum: data.idNum,
    };
    const searchStr = new URLSearchParams(value).toString();
    console.log(`${getUrlConfig().SpecialistSLEEP}?${searchStr}`);
    location.href = `${getUrlConfig().SpecialistSLEEP}?${searchStr}`;
  };
  // 获取是否含有三方服务
  const getThirdServiceEo = async () => {
    const res = await vsf.services.OrderPayController_getThirdServiceEo_52a825({
      orderId: Number(orderId),
    });
    if (res.code === 200) {
      setThirdService(res.data?.thirdService);
    }
  };

  const handelStep1 = () => {
    setStep(2);
    vsf.navigateTo('/daily_quest');
  };
  useEffect(() => {
    console.log(`Current document visibility state: ${documentVisibility}`);
    if (documentVisibility === 'visible') {
      getPatientOrderServeById();
      getThirdServiceEo();
    } else {
      console.log(`Current document visibility state: ${documentVisibility}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentVisibility]);
  useEffect(() => {
    if (data?.serveBundle?.operationsManager?.id) {
      handleMajordomoInfo(data?.serveBundle?.operationsManager?.id);
    }
  }, [data]);
  useEffect(() => {
    window.uni?.getEnv?.((res) => {
      setEnv(res);
    });
  }, []);
  useEffect(() => {
    if (
      step === '-1' &&
      orderStatus == 'true' &&
      data?.orderStatus === 'IN_SERVE'
    )
      hasHistoryPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, data]);

  const renderBtn = (data) => {
    if (
      (data?.orderStatus == 'IN_SERVE' ||
        data?.orderStatus == 'WAIT_EVALUATE' ||
        data?.orderStatus == 'EVALUATED' ||
        data?.orderStatus == 'EVALUATED') &&
      data?.charge > 0
    ) {
      return (
        <Button
          className="btn order_detail_btn"
          onClick={() => {
            handleClickInvoice(data);
          }}
        >
          开发票
        </Button>
      );
    }
  };

  return (
    <>
      <NavBar
        className="nav_bar"
        children="专科服务详情"
        backArrow
        onBack={handleClickBack}
      />
      <Dom_title title="专科服务详情" />

      <div className="specialist_serrvice_detail_bg"></div>
      <div className="specialist_serrvice_detail">
        <div className="specialist_serrvice_detail_bg"></div>

        {data?.orderStatus === 'WAIT_PAY' && (
          <div className="head_pay">
            <div className="box">
              <span className="lable">待付款</span>
              <span className="time">
                {dayjs.duration(time, 'seconds').format('mm分ss秒后取消')}
              </span>
            </div>
          </div>
        )}
        <div className="card">
          <div className="header">
            <span className="title">{data?.bundleName}</span>
            <span
              className={`state ${data.orderStatus === 'WAIT_PAY' ? 'state1' : 'state2'}`}
            >
              {CareOrderStatusEnum[data.orderStatus]}
            </span>
          </div>
          <div className="line"></div>
          <div className="infos">
            <span className="label">服务对象</span>
            <span className="value">
              {data.visitPatient?.patientName ?? '-'}
            </span>
          </div>
          <div className="infos">
            <span className="label">医生/团队</span>
            <span className="value">
              {data.bundleTeamType === 'TEAM'
                ? data.serveTeam?.teamName ?? '-'
                : data.serveDoctor?.doctorName ?? '-'}
            </span>
          </div>
          <div className="infos">
            <span className="label">服务周期</span>
            <span className="value">
              {data.servePeriod ? data.servePeriod + '天' : '-'}
            </span>
          </div>
          <div className="price_box">
            <div className="label">服务费用</div>
            <div className="price">
              <span className="unit">￥</span>
              {data.roundingCharge ? (
                <>
                  <span className="price_integer">
                    {getIntegerAndDecimal(data.roundingCharge).integer}.
                  </span>
                  <span className="price_fractional">
                    {getIntegerAndDecimal(data.roundingCharge).decimal}
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="line"></div>

          <div className="price_box btn-box">
            {renderBtn(data)}
            <Button
              className="btn order_detail_btn"
              onClick={() => vsf.navigateTo(`/order_detail?orderId=${orderId}`)}
            >
              订单详情
            </Button>
          </div>
        </div>
        {data?.orderStatus === 'IN_SERVE' &&
        thirdService === ThirdServiceTYPE.SLEEP ? (
          <div className="online_consultation">
            <div className="title">服务记录</div>
          </div>
        ) : null}
        {data?.orderStatus === 'IN_SERVE' ? (
          <div className="card_list">
            {thirdService === ThirdServiceTYPE.SLEEP ? (
              <div className="list_item" onClick={toSleep}>
                <img src={Slice38} className="icon"></img>
                <div className="name">睡眠检测</div>
                <RightOutline
                  fontWeight={500}
                  className="right"
                  color="#243752"
                  fontSize={14}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="bg_card">
          <div className="header">
            {data?.orderStatus === 'WAIT_PAY'
              ? '支付即可享受以下服务'
              : '服务项目'}
          </div>
          <div className="bg_card_card">
            <Grid columns={3} gap={16}>
              {data?.orderServeItem?.map((item) => {
                return (
                  <Grid.Item key={item.id}>
                    {/* eslint-disable */}
                    {data?.orderStatus === 'IN_SERVE' ? (
                      <ServiceListPopupButton
                        className="card_item"
                        order_item_id={item.id}
                        orderId={data.id}
                        serveType={item.serveType}
                        unuseCount={item.serveCount && item.unuseCount === 0}
                        onLoad={getPatientOrderServeById}
                        value={item}
                        onClickCustomer={handleClickMajord}
                      >
                        <div className="icon">
                          <img src={orderServeImg[item.serveType]} alt="" />
                        </div>
                        <div className="name">{item.serveName ?? '-'}</div>
                        <div className="total_num">
                          {isNumber(item.serveCount)
                            ? '总共' + item.serveCount + '次'
                            : '不限次数'}
                        </div>
                        {data?.orderStatus === 'IN_SERVE' &&
                          isNumber(item.serveCount) ? (
                          <div className="remainder_num">
                            剩余{item.serveCount === 0 ? 0 : item.unuseCount ?? '-'}次
                          </div>
                        ) : null}
                      </ServiceListPopupButton>
                    ) : (
                      <div className="card_item">
                        <div className="icon">
                          <img src={orderServeImg[item.serveType]} alt="" />
                        </div>
                        <div className="name">{item.serveName ?? '-'}</div>
                        <div className="total_num">
                          {item.serveCount
                            ? '总共' + item.serveCount + '次'
                            : '不限次数'}
                        </div>
                        {data?.orderStatus === 'IN_SERVE' &&
                          item.serveCount && (
                            <div className="remainder_num">
                              剩余{item.unuseCount ?? '-'}次
                            </div>
                          )}
                      </div>
                    )}
                    {/* eslint-enable */}
                  </Grid.Item>
                );
              })}
            </Grid>
          </div>
        </div>
        {!!data.bundleUpgrade?.length && data.orderStatus === 'IN_SERVE' && (
          <>
            <div className="upgrade_line">
              <div className="line"></div>
              <div className="center">
                <img className="img" src={Slice26} alt="" />
                <span className="text">升级服务包</span>
              </div>
              <div className="line"></div>
            </div>
            {data.bundleUpgrade?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="up_service"
                  onClick={() =>
                    data?.orderStatus === 'IN_SERVE' &&
                    vsf.navigateTo(
                      `/service_details?id=${item.serveBundle?.id}`,
                    )
                  }
                >
                  <img
                    className="avatar"
                    src={item.serveBundle?.bundleImg}
                    alt=""
                  />
                  <div className="center">
                    <div className="title">
                      {item.serveBundle?.bundleName ?? '-'}
                    </div>
                    <div className="info">
                      {item.serveBundle?.bundleDescr ?? '-'}
                    </div>
                  </div>
                  <div className="icon">
                    <RightOutline color="#7C8797" fontSize={16} />
                  </div>
                </div>
              );
            })}
          </>
        )}
        {data?.orderStatus === 'WAIT_EVALUATE' && (
          <>
            <div className="upgrade_line">
              <div className="line"></div>
              <div className="center">
                <img className="img" src={Slice25} alt="" />
                <span className="text">我的评价</span>
              </div>
              <div className="line"></div>
            </div>
            <div className="rate">
              <div className="label">评分</div>
              <Rate
                style={{
                  '--star-size': '14px',
                  '--active-color': '#ff7f7f',
                }}
                // readOnly
                onChange={setStar}
                value={star}
              />
            </div>
            <div className="user_appraise">
              <div className="label">用户评价</div>
              <TextArea
                placeholder="您的建议非常重要，我们会虚心接受，做得更好~"
                value={value}
                onChange={(val) => {
                  setValue(val);
                }}
                autoSize={{ minRows: 3, maxRows: 5 }}
                maxLength={250}
                showCount
                className="text_area"
              />
            </div>
            <div className="user_appraise_btn" onClick={submitAppraise}>
              提交评价
            </div>
          </>
        )}
        {data?.orderStatus === 'EVALUATED' && (
          <>
            <div className="upgrade_line">
              <div className="line"></div>
              <div className="center">
                <img className="img" src={Slice25} alt="" />
                <span className="text">我的评价</span>
              </div>
              <div className="line"></div>
            </div>
            <div className="appraise_card">
              <div className="appraise_card_header">
                <div className="avatar">
                  <img
                    src={
                      data?.visitPatient?.patientGender === 'WOMAN'
                        ? avatarWoMan
                        : avatarMan
                    }
                    alt=""
                  />
                </div>
                <div className="user">
                  <div className="name">
                    {data?.visitPatient?.patientName
                      ? nameHide(data?.visitPatient?.patientName)
                      : '-'}
                  </div>
                  <div className="star">
                    <Rate
                      style={{
                        '--star-size': '14px',
                        '--active-color': '#ff7f7f',
                      }}
                      readOnly
                      value={data?.orderEvaluateDto?.evaluatePoint}
                    />
                  </div>
                </div>
                <div className="date">
                  {data?.orderEvaluateDto?.createdAt
                    ? dayjs(data?.orderEvaluateDto?.createdAt).format(
                        'YYYY-MM-DD',
                      )
                    : '-'}
                </div>
              </div>
              <div className="appraise_card_content">
                <EllipsisDom
                  content={data?.orderEvaluateDto?.evaluateContent}
                  rows={3}
                />
              </div>
            </div>
          </>
        )}
        <div className={env?.miniprogram ? 'pay_box wx_pay_box' : 'pay_box'}>
          <div className="left">
            {/* {data?.orderStatus === 'WAIT_PAY' && (
              <>
                <div className="lable">总费用</div>
                <div className="price">
                  <span className="num">{data.roundingCharge}</span>
                  <span className="unit">元</span>
                </div>
              </>
            )} */}
            {data?.orderStatus === 'WAIT_PAY' && (
              <Button
                loading={loading1}
                className="cancel_btn"
                onClick={cancelOrder}
              >
                取消订单
              </Button>
            )}
          </div>
          {data?.orderStatus === 'WAIT_PAY' ? (
            <Button loading={loading} className="pay_btn" onClick={getTradeNo}>
              立即支付
            </Button>
          ) : (
            <Button className="pay_btn" onClick={handleClickMajords}>
              联系客服
            </Button>
          )}
        </div>
      </div>
      <MajordomoPopup
        visible={popupVisible}
        onClosePopup={() => {
          setPopupVisible(false);
        }}
        title={majordomoInfo?.doctorName}
        phone={majordomoInfo?.phone}
        codeUrl={majordomoInfo?.wechatQrImg}
      ></MajordomoPopup>
      {/* <Mask visible={maskLoading}>
        <div className="loading_box">
          <SpinLoading />
          <div className="loading_text">订单状态同步中</div>
        </div>
      </Mask> */}
      <Mask visible={step === 1}>
        <div className="step1">
          <div className="title">
            特色专病专科模块，<span>菜单升级</span>
          </div>
          <div className="info">
            您的服务包含“<span>健康任务</span>”，请在“
            <span>每日任务</span>”菜单查看
          </div>
          <img src={step1_img} className="info_img" />
          <div className="step_btn" onClick={handelStep1}>
            去查看
          </div>
        </div>
      </Mask>
    </>
  );
};

export default definePage(SpecialistSerrviceDetail);
