import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Grid, NavBar, Rate, TextArea } from '@vs/vsf-mobile';
import { useDocumentVisibility } from 'ahooks';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import avatarMan from '@/assets/avatar_man.png';
import avatarWoMan from '@/assets/avatar_woman.png';
import doctorImg from '@/assets/doctor.png';
import Slice25 from '@/assets/Slice25.png';
import Slice35 from '@/assets/Slice35.png';
import Slice36 from '@/assets/Slice36.png';
import Slice37 from '@/assets/Slice37.png';
import Slice38 from '@/assets/Slice38.png';
import Dom_title from '@/components/dom_title';
import EllipsisDom from '@/components/ellipsisDom';
import MajordomoPopup from '@/components/majordomo-popup';
import { orderServeImg } from '@/config';
import {
  HeaithCareOrderStatusEnum,
  ThirdServiceEnum,
  ThirdServiceTYPE,
} from '@/config';
import {
  getENVIR,
  getIntegerAndDecimal,
  getUrlConfig,
  isJSON,
  isNumber,
} from '@/utils';

// 测试环境访问链接：
// 跳转问诊记录界面
export const InterviewRecordsURL =
  'https://zhicall.chzju.cn/hu-web/im/order/user/list/byType';
// 哮喘患者管理
export const URL = '/elk-asthma-internet-doctor/index.html#/patientmgmt';

// 患者主页
export const URL1 = '/elk-asthma-internet-doctor/index.html#/patientmgmt/0';
const SpecialistSerrviceDetail = (props) => {
  const [data, setData] = useState<DoctorOrderServeVo03Ec0D | any>({});
  const [popupVisible, setPopupVisible] = useState(false);
  const [majordomoInfo, setMajordomoInfo] = useState<any>({});
  const [thirdService, setThirdService] = useState<any>({});
  const documentVisibility = useDocumentVisibility();
  const { user } = props.stores;
  const { doctorUserInfo, patientUserInfo } = user;

  const handleClickBack = () => {
    vsf?.goBack();
  };
  const { orderId } = props.routes?.query || {};
  const getDoctorOrderServeById = async () => {
    const res =
      await vsf.services.OrderRecordController_getDoctorOrderServeById_7d4eae({
        id: Number(orderId),
      });
    if (res.code === 200) {
      setData(res.data ?? {});
    }
  };

  const handleMajordomoInfo = async (id) => {
    const res =
      await vsf?.services?.EmployeeDoctorController_getMedicalCareManagerVoById_182445(
        {
          id,
        },
      );
    if (res?.code == 200) {
      setMajordomoInfo(res?.data);
    }
  };

  const handleClickMajord = () => {
    setPopupVisible(true);
  };

  const toOrderRecords = () => {
    vsf.showToast('暂未开放!', 'warn');
    // const value = {
    //   userAccountNo: doctorUserInfo.userAccount,
    //   // userNo: '',
    //   // hospitalId: '',
    //   // companyId: '',
    //   // doctorNo: '',
    // };
    // const searchStr = new URLSearchParams(value).toString();
    // location.href = `${InterviewRecordsURL}?${searchStr}`;
  };
  // 哮喘管理;
  const toPatientManage = async () => {
    const res = await vsf.services?.OrderRecordController_asthmaLogin_b92979?.({
      orderId,
      type: 2,
    });
    if (res.code === 200 && res.data) {
      if (isJSON(res.data?.result)) {
        const data = JSON.parse(res.data?.result);
        console.log('toPatientManage', data);
        if (data?.data && data?.errcode === 0) {
          console.log('asthmaLogin', data?.data?.access_token);
          const value = {
            access_token: data?.data?.access_token,
          };
          const searchStr = new URLSearchParams(value).toString();
          location.href = `${getUrlConfig()[thirdService]}${URL}?${searchStr}`;
        } else {
          vsf.showToast(data.errmsg, 'error');
        }
      }
    }
  };
  // 哮喘患者档案;
  const toPatientDetail = async () => {
    const res = await vsf.services?.OrderRecordController_asthmaLogin_b92979?.({
      orderId,
      type: 1,
    });
    if (res.code === 200 && res.data) {
      if (isJSON(res.data?.result)) {
        const data = JSON.parse(res.data?.result);
        console.log('toPatientManage', data);
        if (data?.data && data?.errcode === 0) {
          console.log('asthmaLogin', data?.data?.access_token);
          const value = {
            access_token: data?.data?.access_token,
            doctor: '1',
          };
          const searchStr = new URLSearchParams(value).toString();
          location.href = `${getUrlConfig()[thirdService]}${URL1}?${searchStr}`;
        } else {
          vsf.showToast(data.errmsg, 'error');
        }
      }
    }
  };
  // 睡眠检测
  const toSleep = async () => {
    const value = {
      channel: 'h5-C009',
      userPhone: data.visitPatient?.phone,
      patidNum: data.idNum,
    };
    const searchStr = new URLSearchParams(value).toString();
    console.log(`${getUrlConfig().HealthSLEEP}?${searchStr}`);
    location.href = `${getUrlConfig().HealthSLEEP}?${searchStr}`;
  };
  // 获取是否含有三方服务
  const getThirdServiceEo = async () => {
    const res = await vsf.services.OrderPayController_getThirdServiceEo_52a825({
      orderId: Number(orderId),
    });
    if (res.code === 200) {
      setThirdService(res.data?.thirdService);
    }
  };

  useEffect(() => {
    if (documentVisibility === 'visible') {
      getDoctorOrderServeById();
      getThirdServiceEo();
    } else {
      console.log(`Current document visibility state: ${documentVisibility}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentVisibility]);
  useEffect(() => {
    if (data?.serveBundle?.operationsManager?.id) {
      handleMajordomoInfo(data?.serveBundle?.operationsManager?.id);
    }
  }, [data]);
  return (
    <>
      <NavBar children="服务详情" backArrow onBack={handleClickBack} />
      <Dom_title title="服务详情" />
      <div className="health_care_service_details">
        <div className="card">
          <div
            className="header"
            onClick={() =>
              vsf.navigateTo(`/health_care_order_detail?orderId=${orderId}`)
            }
          >
            <div className="left">
              <div className="title">{data.bundleName ?? '-'}</div>
              <div className="info">
                服务有效期：{data.serveDateRange ?? '-'}
              </div>
            </div>
            <div className={`price`}>
              <span className="unit">￥</span>
              <span>{data.roundingCharge}</span>
            </div>
            <div className="bg"></div>
          </div>
          <div className="info_box">
            <div className="online_consultation">
              <div className="title">
                <span>服务说明</span>
                <div
                  className={`line ${data.orderStatus !== 'CANCEL' ? 'state1' : 'state2'}`}
                >
                  |
                </div>
                <span
                  className={`state ${data.orderStatus !== 'CANCEL' ? 'state1' : 'state2'}`}
                >
                  {HeaithCareOrderStatusEnum[data.orderStatus]}
                </span>
              </div>
              <div
                className="tit_exita"
                onClick={() =>
                  vsf.navigateTo(`/health_care_order_detail?orderId=${orderId}`)
                }
              >
                <span>订单详情</span>
                <RightOutline color="#1778FF" fontSize={12} />
              </div>
            </div>
            <div className="infos">
              <span className="label">医生/团队</span>
              <span className="value">
                {data.bundleTeamType === 'TEAM'
                  ? data.serveTeam?.teamName ?? '-'
                  : data.serveDoctor?.doctorName ?? '-'}
              </span>
            </div>
            {data.serveTeam?.teamDoctor ? (
              <div className="infos">
                <span className="label">团队成员</span>
                <span className="value">
                  {data.serveTeam?.teamLeader?.doctorName
                    ? data.serveTeam?.teamLeader?.doctorName + '(组长)'
                    : '-'}
                  {data.serveTeam?.teamDoctor.length > 1 && '、'}
                  {data.serveTeam?.teamDoctor
                    .filter(
                      (item) =>
                        item.doctor?.doctorName !==
                        data.serveTeam?.teamLeader?.doctorName,
                    )
                    .map((item) => item.doctor?.doctorName)
                    .join('、')}
                </span>
              </div>
            ) : null}
            <div className="infos">
              <div className="label">服务对象</div>
              <div className="value">
                {data.visitPatient?.patientName ?? '-'}
              </div>
              <div className="value">{data.age ? data.age + '岁' : null}</div>
              <div className="value">
                {data.visitPatient?.patientGender &&
                  (data.visitPatient?.patientGender === 'MAN' ? '男' : '女')}
              </div>
            </div>
          </div>
        </div>
        {thirdService === ThirdServiceTYPE.SLEEP ? (
          <div className="online_consultation">
            <div className="title">服务记录</div>
          </div>
        ) : null}
        <div className="card_list">
          {/* <div className="list_item" onClick={toOrderRecords}>
            <img src={Slice37} className="icon"></img>
            <div className="name">咨询记录</div>
            <RightOutline className="right" color="#243752" fontSize={14} />
          </div>
          {thirdService === ThirdServiceTYPE.ASTHMA ? (
            <>
              <div className="list_item" onClick={toPatientManage}>
                <img src={Slice35} className="icon"></img>
                <div className="name">哮喘管理</div>
                <RightOutline
                  className="right"
                  color="#243752"
                  fontSize={14}
                />
              </div>
              <div className="list_item" onClick={toPatientDetail}>
                <img src={Slice36} className="icon"></img>
                <div className="name">哮喘患者档案</div>
                <RightOutline
                  fontWeight={500}
                  className="right"
                  color="#243752"
                  fontSize={14}
                />
              </div>
            </>
          ) : null} */}
          {thirdService === ThirdServiceTYPE.SLEEP ? (
            <div className="list_item" onClick={toSleep}>
              <img src={Slice38} className="icon"></img>
              <div className="name">睡眠检测</div>
              <RightOutline
                fontWeight={500}
                className="right"
                color="#243752"
                fontSize={14}
              />
            </div>
          ) : null}
        </div>
        <div className="online_consultation">
          <div className="title">服务项目</div>
        </div>
        <div className="bg_card_card">
          <Grid columns={3} gap={16}>
            {data?.orderServeItem?.map((item) => {
              return (
                <Grid.Item key={item.id}>
                  <div className="card_item">
                    <div className="icon">
                      <img src={orderServeImg[item.serveType]} alt="" />
                    </div>
                    <div className="name">{item.serveName ?? '-'}</div>
                    <div className="total_num">
                      {isNumber(item.serveCount)
                        ? '总共' + item.serveCount + '次'
                        : '不限次数'}
                    </div>
                    {data?.orderStatus === 'IN_SERVE' &&
                    isNumber(item.serveCount) ? (
                      <div className="remainder_num">
                        剩余{item.serveCount === 0 ? 0 : item.unuseCount ?? '-'}
                        次
                      </div>
                    ) : null}
                  </div>
                </Grid.Item>
              );
            })}
          </Grid>
        </div>
        {data?.orderStatus === 'EVALUATED' && (
          <>
            <div className="upgrade_line">
              <div className="line"></div>
              <div className="center">
                <img className="img" src={Slice25} alt="" />
                <span className="text">用户评价</span>
              </div>
              <div className="line"></div>
            </div>
            <div className="appraise_card">
              <div className="appraise_card_header">
                <div className="avatar">
                  <img
                    src={
                      data?.visitPatient?.patientGender === 'WOMAN'
                        ? avatarWoMan
                        : avatarMan
                    }
                    alt=""
                  />
                </div>
                <div className="user">
                  <div className="name">
                    {data?.visitPatient?.patientName ?? '-'}
                  </div>
                  <div className="star">
                    <Rate
                      style={{
                        '--star-size': '14px',
                        '--active-color': '#ff7f7f',
                      }}
                      readOnly
                      value={data?.orderEvaluateDto?.evaluatePoint}
                    />
                  </div>
                </div>
                <div className="date">
                  {data?.orderEvaluateDto?.createdAt
                    ? dayjs(data?.orderEvaluateDto?.createdAt).format(
                        'YYYY-MM-DD',
                      )
                    : '-'}
                </div>
              </div>
              <div className="appraise_card_content">
                <EllipsisDom
                  content={data?.orderEvaluateDto?.evaluateContent ?? '-'}
                  rows={3}
                />
              </div>
            </div>
          </>
        )}
        <div className="pay_box">
          <div className="left"></div>
          <Button className="pay_btn" onClick={handleClickMajord}>
            联系客服
          </Button>
        </div>
      </div>
      <MajordomoPopup
        visible={popupVisible}
        onClosePopup={() => {
          setPopupVisible(false);
        }}
        title={majordomoInfo?.doctorName}
        phone={majordomoInfo?.phone}
        codeUrl={majordomoInfo?.wechatQrImg}
        type="doctor"
      ></MajordomoPopup>
    </>
  );
};

export default definePage(SpecialistSerrviceDetail);
