import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitApplyController_getVisitApplyByOrderServeItemId_93cf3c: {
    method: 'post',
    url: '/api/visit-apply/get-visit-apply-by-order-serve-item-id',
    parameterFomatter: (data?: { orderServeItemId: number }) => data,
    responseFormatter: (_, __, data?: VisitApplyPatientDoctorVoF6A530[]) =>
      data,
  },
  VisitApplyController_getApplyInfo_b2daa1: {
    method: 'post',
    url: '/api/visit-apply/get-apply-info',
    parameterFomatter: (data?: { orderServeItemId: number }) => data,
    responseFormatter: (_, __, data?: VisitApplyPatientDoctorVoF6A530) => data,
  },
  VisitApplyController_saveApply_fdeaf9: {
    method: 'post',
    url: '/api/visit-apply/save-apply',
    parameterFomatter: (data?: { bto: CreateVisitApplyBto0B5B9E }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VisitApplyController_updateStatusVisitApply_4af036: {
    method: 'post',
    url: '/api/visit-apply/update-status-visit-apply',
    parameterFomatter: (data?: {
      btoParam: UpdateStatusVisitApplyBto0B5B9E;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  RegController_getRegByDoctorId_164091: {
    method: 'post',
    url: '/api/visit-apply/get-reg-by-doctor-id',
    parameterFomatter: (data?: { doctorId: number }) => data,
    responseFormatter: (_, __, data?: VisitApplyDoctorVoF6A530[]) => data,
  },
  OrderRecordController_getVisitApplyDoctorList_46c052: {
    method: 'post',
    url: '/api/order-record/get-visit-apply-doctor-list',
    parameterFomatter: (data?: { orderItemId: number }) => data,
    responseFormatter: (_, __, data?: TeamMemberDetailVo03Ec0D[]) => data,
  },
});
