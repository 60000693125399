// housekeep
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Box,
  Button,
  Form,
  InfiniteScroll,
  NavBar,
  Popup,
  Tabs,
  TextArea,
} from '@vs/vsf-mobile';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import bell from '@/assets/bell.png';
import empty from '@/assets/empty.png';
import housekeepImg from '@/assets/housekeep_img.png';
import phone from '@/assets/phone.png';
import time from '@/assets/titme.png';
import Dom_title from '@/components/dom_title';
import {
  ApplyStatusEnum,
  ApplyStatusTYPE,
  GenderEnum,
  TimePointEnum,
} from '@/config';
const Housekeep: React.FC = (props: any) => {
  const [majordomoInfo, setMajordomoInfo] = useState<any>({});

  useEffect(() => {
    handleMajordomoInfo();
  }, []);

  const handleMajordomoInfo = async () => {
    const res =
      await vsf?.services?.EmployeeDoctorController_getListMedicalCareManagerVoByIsCustomerService_2a33ea(
        {
          isCustomerService: 1,
        },
      );
    if (res?.code == 200) {
      setMajordomoInfo(res?.data?.[0]);
    }
  };

  return (
    <>
      <NavBar children="客服中心" backArrow />
      <Dom_title title="客服中心" />
      <div className="housekeep-container">
        <div className="housekeep-bg">
          <div className="left">
            <div className="">联系客服</div>
            <div className="text">
              若您对服务有任何疑问，可联系健康管家免费咨询
            </div>
          </div>
          <img className="housekeep-logo" src={housekeepImg}></img>
        </div>
        <div className="content">
          <div className="content-item">
            <img className="logo" src={phone}></img>
            <div className="item-content">
              <div className="title">联系电话</div>
              <div className="text">{majordomoInfo?.phone}</div>
            </div>
          </div>
          <div
            className="content-item"
            // onClick={() => {
            //   const jsonStr = JSON.stringify({
            //     visitorProduct: {
            //       title:
            //         '纯坚果零食大礼包一整箱干果类网红爆款解馋小吃休闲食品送女友',
            //       price: '￥9.9',
            //       img: 'https://img.alicdn.com/bao/uploaded/i1/2201453915278/O1CN01dZolFu1orN8dFeKAj_!!0-item_pic.jpg_200x200q90.jpg_.webp',
            //       url: 'https://www.taobao.com',
            //     },
            //   });
            //   console.log(jsonStr, 'jsonStr');
            //   const base64Str = window?.btoa(encodeURIComponent(jsonStr));
            //   location.href = `https://aichat.teitui.com/chatIndex?kefu_id=admin&ent_id=3&visitor_id=1&visitor_name=哈哈哈&extra=${base64Str}`;
            // }}
          >
            <img className="logo" src={time}></img>
            <div className="item-content">
              <div className="title">工作时间</div>
              <div className="text text1">周一至周五 9:00-17:00</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="message-container">
        <div className="message-content">
          {' '}
          <div className="message-item">
            <div className="item-left">
              <img src={bell} className="img"></img>
            </div>
            <div className="item-center">
              <div className="title">服务通知</div>
              <div className="text">您想张三医生发起的面诊申请通过</div>
            </div>
            <div className="item-rigth">
              <div className="time">2023年12月12日</div>
              <Badge content="99+" />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default definePage(Housekeep);
