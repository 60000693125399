import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_createInvoice_62c1ad: {
    method: 'post',
    url: '/api/order-record/create-invoice',
    parameterFomatter: (data?: {
      orderId: number;
      companyEo: CompanyCreateInvoiceInputEo8A4D30;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderRecordController_getInvoice_2d81e4: {
    method: 'post',
    url: '/api/order-record/get-invoice',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (_, __, data?: OrderElectronicInvoiceVo03Ec0D) => data,
  },
  OrderRecordController_reInvoice_8c2036: {
    method: 'post',
    url: '/api/order-record/re-invoice',
    parameterFomatter: (data?: {
      orderId: number;
      companyEo: CompanyCreateInvoiceInputEo8A4D30;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
