import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, Grid, ImageUploader, NavBar } from '@vs/vsf-mobile';
import { RightOutline, UserContactOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import * as echarts from 'echarts';
import React, { useEffect, useState } from 'react';

import asthma from '@/assets/asthma.png';
import doctor from '@/assets/doctor.png';
import doctorIcon from '@/assets/doctorIcon.png';
import doctorIcon1 from '@/assets/doctorIcon1.png';
import grow from '@/assets/grow.png';
import Slice from '@/assets/Slice541.png';
import tabbg from '@/assets/tabbg.png';
import tabbg1 from '@/assets/tabbg1.png';
import Dom_title from '@/components/dom_title';
import { isImgSrc } from '@/utils';

import data from './data.json';

const imgObj = {
  doctorIcon: doctorIcon,
  doctorIcon1: doctorIcon1,
};

const Title = (props) => {
  const { iconColor = '#ff7e7e', isMore, title, onClick } = props;

  return (
    <div className="header-title">
      <div className="left">
        <div className="icon" style={{ backgroundColor: iconColor }}></div>
        {title}
      </div>
      <div
        className="add"
        onClick={() => {
          isMore && onClick && onClick();
        }}
      >
        {isMore && (
          <>
            查看全部 <RightOutline color="#A7AFBA" fontSize={8} />
          </>
        )}
      </div>
    </div>
  );
};

const PatientInformation: React.FC<any> = (props) => {
  //   const { patientName } = props?.routes?.query;
  const [infoData, setInfoData] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState('inspect');
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    setInfoData(data);
    setList(data?.inspectList);
    if (infoData) {
      handleCharts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoData]);

  const handleCharts = () => {
    const chartDom = document.getElementById('charts');
    let myChart;
    // let option;
    if (chartDom) {
      myChart = echarts.init(chartDom);
    }
    const option = {
      legend: {
        top: 'bottom',
        left: 'center',
        show: false,
      },
      grid: {
        top: 20,
        left: '10%',
        right: 10,
      },
      series: [
        {
          name: 'Nightingale Chart',
          type: 'pie',
          radius: [20, '50%'],
          center: ['50%', '50%'],
          roseType: 'area',
          padAngle: 5,
          itemStyle: {
            borderRadius: 0,
          },
          data: infoData?.MedicationList,
          label: {
            color: 'inherit',
            formatter: (value) => {
              return `${renderStr(value?.name, 4)}:${value?.value}`;
            },
            overflow: 'break',
            align: 'left',
            width: 69,
          },
        },
      ],
    };
    option && myChart.setOption(option);
    myChart.resize();
  };

  const renderStr = (value, num = 6) => {
    if (value?.length > num) {
      return `${value?.substring(0, num)}...`;
    }
    return value;
  };

  const renderIsImg = (value) => {
    return isImgSrc(value?.elementValue) ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ImageUploader
          value={[{ url: value?.elementValue }]}
          maxCount={1}
          deletable={false}
          showUpload={false}
          disableUpload
          upload={() => {
            return new Promise((resolveOuter) => {
              resolveOuter(new Promise((resolveInner) => {}));
            });
          }}
        ></ImageUploader>
      </div>
    ) : (
      `${value?.elementValue}${value?.normalValueUnit ?? ''}`
    );
  };
  const renderText = (value) => {
    return typeof value?.isAbnormal == 'boolean' &&
      !isImgSrc(value?.elementValue)
      ? '正常'
      : '';
  };

  const renderDivStyle = (value) => {
    console.log(value, 'value');
    const colorList = [
      'rgba(0,194,66,1)',
      'rgba(84,152,251,1)',
      'rgba(255,126,0,1)',
      'rgba(228,101,239,1)',
    ];
    const bgColorList = [
      'rgba(0,194,66,0.1)',
      'rgba(84,152,251,0.1)',
      'rgba(255,126,0,0.1)',
      'rgba(228,101,239,0.1)',
    ];
    const getRandomNumber = (min, max) => {
      return Math.random() * (max - min) + min;
    };
    console.log(colorList?.[value?.value + 1 ?? 1], value?.value, '-----');
    return {
      marginBottom: getRandomNumber(0, 1) * 10 + 'px',
      marginLeft: getRandomNumber(0, 1) * 10 + 'px',
      marginRight: getRandomNumber(0, 1) * 10 + 'px',
      color: colorList?.[value?.value - 1 ?? 0],
      backgroundColor: bgColorList?.[value?.value - 1 ?? 0],
    };
  };

  const renderData = (currentTab) => {
    if (currentTab == 'inspect') {
      return infoData?.inspectList;
    } else {
      return infoData?.inspect;
    }
  };

  return (
    <>
      <NavBar children="患者360全息视图" backArrow={false} />
      <Dom_title title="患者360全息视图" />
      <div className="PatientInformation-container">
        <div className="header-box">
          <img src={doctor} className="header-img"></img>
          <div className="header-center">
            <div className="center-header">
              <div className="name">
                {props?.routes?.query?.patientName
                  ? props?.routes?.query?.patientName
                  : '库浩晨'}
              </div>
              <div className="icon">男</div>
              <div className="age">2岁1月5天</div>
            </div>
            <div className="center-item">身份证号：3301***1410</div>
            <div className="center-item">门诊号：23284414</div>
          </div>
          <div className="header-rigth">
            <div className="top">
              <img className="top-icon" src={Slice}></img>
              切换
            </div>
            <div className="bottom">
              <div className="tag-item">甲状腺</div>
              <div className="tag-item">过敏</div>
              <div className="tag-item">支气管炎</div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="box frstBox">
            <Title title="过敏史"></Title>
            <div className="tag-box">
              {data?.AllergyHistoryList?.map((v, i) => {
                return (
                  <div className="tag" key={i}>
                    {v}
                  </div>
                );
              })}
            </div>
            <Title
              title="就诊史"
              iconColor="#2ED384"
              isMore
              onClick={(value: any) => {
                if (value) {
                  vsf?.navigateTo(
                    `/medical_history?patientName=${JSON.stringify(props?.routes?.query)}&data= ${JSON.stringify(data.MedicalHistoryList)}`,
                  );
                }
                vsf?.navigateTo(
                  `/medical_history?data= ${JSON.stringify(data.MedicalHistoryList)}`,
                );
              }}
            ></Title>
            <div className="tag1-box">
              <Grid columns={2} gap={10}>
                {data?.MedicalHistoryList?.map((v, i) => {
                  return (
                    <Grid.Item key={i}>
                      <div className="tag1">
                        <div className="left">
                          <div className="icon">
                            <img src={imgObj[v?.icon]} className="img"></img>
                          </div>
                          <div className="title">{v?.name}</div>
                        </div>
                        <div className="key">{v?.num ? v?.num : ''}</div>
                      </div>
                    </Grid.Item>
                  );
                })}
              </Grid>
            </div>
          </div>
          <div className="box charts-box">
            <div>
              <Title
                title="用药"
                iconColor="#17C7FF"
                isMore
                onClick={(value: any) => {
                  if (value) {
                    vsf?.navigateTo(
                      `/prescription_record?user=${props?.routes?.query?.patientName}&data= ${JSON.stringify(infoData?.MedicationList)}`,
                    );
                  }
                  vsf?.navigateTo(
                    `/prescription_record?data= ${JSON.stringify(infoData?.MedicationList)}`,
                  );
                }}
              ></Title>
            </div>
            <div className="charts" id="charts"></div>
          </div>
          <div className="line"></div>
          <div className="box third-box">
            <div className="third-tab">
              <div
                className={classNames(`tab-item`, {
                  activeTab: currentTab == 'inspect',
                })}
                onClick={() => {
                  setCurrentTab('inspect');
                  setList([]);
                  setList(infoData?.inspectList);
                }}
              >
                检验
              </div>
              <div
                className={classNames(`tab-item`, {
                  activeTab: currentTab == 'inspect1',
                })}
                onClick={() => {
                  setCurrentTab('inspect1');
                  setList([]);
                  setList(infoData?.inspect);
                }}
              >
                检查
              </div>
            </div>
            <div className="third-line-bg"></div>
            <div className="third-content">
              <div className="third-content-header">
                <div className="left">
                  <div className="text">近14天</div>
                  <div>近30天</div>
                </div>
                <div
                  className="add"
                  onClick={() => {
                    // console.log(currentTab, 'curr');
                    // 检查检验跳转
                    vsf?.navigateTo(`/report_query?currentTab=${currentTab}`);
                  }}
                >
                  查看全部 <RightOutline color="#A7AFBA" fontSize={8} />
                </div>
              </div>
              <div className="tag-box">
                {list?.map((v, i) => {
                  return (
                    <div
                      style={{ ...renderDivStyle(v) }}
                      className="third-tag"
                      key={i}
                    >
                      <span className="tag-span">{v?.name}</span>{' '}
                      <span className="span">{v?.value}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <img
              className="third-bg"
              src={currentTab == 'inspect' ? tabbg : tabbg1}
            ></img>
          </div>
          <div className="fifth-box">
            <div className="fifth-header">
              <img className="header-item item1" src={asthma}>
                {/* 哮喘专病全程管理 */}
              </img>
              <img className="header-item item2" src={grow}>
                {/* 儿童生长发育管理 */}
              </img>
            </div>
            <div className="fifth-content">
              <div className="indicators">
                <div className="indicators-header">
                  <div className="header-title">我的指标</div>
                  <div className="header-rigth" onClick={() => {}}>
                    <span className="text">指标记录</span>
                    <RightOutline
                      fontSize={12}
                      color="#A7AFBA"
                      className="icon"
                    />
                  </div>
                </div>
                <div className="indicators-content">
                  <div className="indicators-abnormol mine-col">
                    <>
                      <div className="indicators-title mine-col">异常项</div>
                      <div className="abnormol-content">
                        {infoData?.indicators
                          ?.filter((v) => v?.isAbnormal)
                          ?.map((v, index) => {
                            return (
                              <div
                                className="abnormol-item"
                                key={index}
                                onClick={() => {
                                  //   handleJumpDetails(v);
                                }}
                              >
                                <div className="item-title">
                                  {renderStr(v?.projectName)}
                                </div>
                                <div className="item-num">{renderIsImg(v)}</div>
                                <div className="abnormol-state">异常</div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  </div>
                  <div className="indicators-abnormol mine-col">
                    <>
                      <div className="indicators-title mine-col">标准项</div>
                      <div className="abnormol-content normol">
                        {infoData?.indicators
                          ?.filter((v) => !v?.isAbnormal)
                          ?.map((v, index) => {
                            return (
                              <div
                                className="abnormol-item"
                                key={index}
                                // onClick={() => {
                                //   handleJumpDetails(v);
                                // }}
                              >
                                <div className="item-title">
                                  {renderStr(v?.projectName)}
                                </div>
                                <div className="item-num">{renderIsImg(v)}</div>
                                <div className="abnormol-state normol-text">
                                  {renderText(v)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default definePage(PatientInformation);
