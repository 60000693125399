import './index.less';
import 'braft-editor/dist/index.css';
import '@/components/FormEditor/compontents/renderer/MyRenderer';

// import '@/components/FormEditor/compontents/editor/MyRenderer';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Modal, NavBar, Radio } from '@vs/vsf-mobile';
import { render as renderAmis } from 'amis';
import { Log } from 'amis/lib/renderers/Log';
import BraftEditor from 'braft-editor';
import classNames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';

import Dom_title from '@/components/dom_title';
// import Editor from '@/components/Editor';
import { customFetcher, isJSON } from '@/utils';
const TaskDetail = (props) => {
  const { routes } = props;
  const idRef = useRef(routes.query);
  const { user } = props.stores;

  const { doctorUserInfo, patientUserInfo } = user;

  const [detail, setDetail] = useState<any>({});
  const [code, setCode] = useState<any>();
  const { plan } = props.stores;
  const { setWarnNum, setWarnData } = plan;

  const handleClickBack = () => {
    vsf?.goBack();
  };

  const parseStringToBoolean = (str) => {
    str = str?.toLowerCase();

    if (str === 'true') {
      return true;
    } else if (str === 'false') {
      return false;
    }

    return false;
  };

  const handleSubmit = async () => {
    const res =
      await vsf.services.TaskExecuteController_createTaskExecute_27abfc({
        btoParam: {
          status: 'FINISH',
          content: detail?.content,
          planExecuteDateTime: JSON.parse(idRef?.current?.planDate),
          taskDetailId: idRef?.current?.detailId,
          planTaskId: idRef?.current?.id,
          taskMasterId: idRef?.current?.taskMasterId,
          doctorFlag: doctorUserInfo?.userAccount ? true : false,
        },
      });
    if (res?.code === 200) {
      vsf?.goBack();
    }
  };

  const queryValueForObject = async () => {
    const res =
      await vsf?.services.OssPolicyController_queryValueForObject_7ebe0b({
        code: 'INDICATOR_MONITORING',
      });
    if (res.code === 200 && res?.data) {
      setCode(res?.data?.filter((item) => item.unit).map((item) => item.key));
    }
  };

  useEffect(() => {
    const getPlanTaskExecuteDetailVoById = async () => {
      const res =
        await vsf.services.TaskExecuteController_getPlanTaskExecuteDetailVoById_a7c2ff(
          {
            id: idRef?.current?.planTaskExecute,
          },
        );
      if (res?.code === 200) {
        if (
          doctorUserInfo?.userAccount &&
          idRef?.current?.taskCategory == '4'
        ) {
          const content = res?.data?.content || res?.data?.taskDetail?.content;
          const body = JSON.parse(content);
          const newBody = body?.body?.map((item) => {
            if (item?.type === 'button') {
              return {
                ...item,
                hidden: true,
              };
            }
            return item;
          });

          const newDetail = {
            ...res?.data,
            content: JSON.stringify({
              ...body,
              body: newBody,
            }),
          };
          setDetail(newDetail);
        } else {
          setDetail(res?.data);
        }
      }
    };

    const queryTaskById = async () => {
      const res =
        await vsf.services.RegTempDetailController_queryTaskById_9d30b6({
          id: idRef?.current?.detailId,
        });
      if (res?.code === 200) {
        if (
          doctorUserInfo?.userAccount &&
          idRef?.current?.taskCategory == '4'
        ) {
          const content = res?.data?.content || res?.data?.taskDetail?.content;
          const body = JSON.parse(content);
          const newBody = body?.body?.map((item) => {
            if (item?.type === 'button') {
              return {
                ...item,
                hidden: true,
              };
            }
            return item;
          });

          const newDetail = {
            ...res?.data,
            content: JSON.stringify({
              ...body,
              body: newBody,
            }),
          };
          setDetail(newDetail);
        } else {
          setDetail(res?.data);
        }
      }
    };
    if (parseStringToBoolean(idRef?.current?.isExecute)) {
      getPlanTaskExecuteDetailVoById();
    } else {
      queryTaskById();
    }
  }, [doctorUserInfo?.userAccount]);

  useEffect(() => {
    queryValueForObject();
  }, []);

  const transformContentData = (data) => {
    console.log('data===', data);
    if (data && data.constructor === Object) {
      return Object.keys(data)
        ?.map((key) => {
          let iscode, num, unit;
          if (code.find((c) => c == key)) {
            iscode = true;
            num = parseFloat(data[key]) ?? null;
            unit = num ? data[key].split(String(num))?.[1] ?? null : null;
          }
          return {
            ...(detail?.planTaskExecuteElementBaseDtoList?.find(
              (item) => item.elementId === key,
            ) ?? {}),
            elementId: key,
            elementValue: iscode ? num : data[key],
            normalValueUnit: unit,
          };
        })
        ?.filter((item) => {
          return item.elementValue;
        });
    }
  };

  const convertContentData = () => {
    let data = {};
    detail?.planTaskExecuteElementBaseDtoList?.forEach((item) => {
      data = { ...data, [item.elementId]: item.elementValue };
    });
    return {
      ...data,
      disabled:
        doctorUserInfo?.userAccount && idRef?.current?.taskCategory == '4',
    };
  };

  const handelCustomValue = async (content, contentData) => {
    const res =
      await vsf.services.TaskExecuteController_createTaskExecuteAndDetail_eb1f72(
        {
          btoParam: {
            status: 'FINISH',
            // content: isJSON(content) ? content : JSON.stringify(content),
            planTaskExecuteElementBtoList: transformContentData(contentData),
            planExecuteDateTime: JSON.parse(idRef?.current?.planDate),
            taskDetailId: idRef?.current?.detailId,
            planTaskId: idRef?.current?.id,
            taskMasterId: idRef?.current?.taskMasterId,
            id: parseStringToBoolean(idRef?.current?.isExecute)
              ? detail?.id
              : undefined,
          },
        },
      );
    if (res?.code === 200) {
      if (
        patientUserInfo?.userAccount &&
        idRef?.current?.taskCategory === '4'
      ) {
        queryAbnormalIndicator(res?.data?.id);
      }
      vsf?.goBack();
    }
  };

  const toServiceDetails = (id) => {
    vsf?.navigateTo(`/service_details?id=${id}`);
  };

  const queryAbnormalIndicator = async (id) => {
    const res =
      await vsf?.services.TaskExecuteController_queryAbnormalIndicator_3fea70({
        planTaskExecuteId: id,
      });
    setWarnData(res?.data);
    if (res?.code === 200 && res?.data?.totalNum > 0) {
      setWarnNum(res?.data?.totalNum);
    }
  };

  const transformData = () => {
    const content = detail?.content || detail?.taskDetail?.content;
    if (!content) return {};
    if (doctorUserInfo?.userAccount && idRef?.current?.taskCategory === '4') {
      const schema = JSON?.parse(content);
      return {
        ...schema,
        body: schema.body.map((item) => ({
          ...item,
          disabled: true,
        })),
      };
    }
    console.log(content, 'content');
    return JSON?.parse(content);
  };

  console.log(detail, 'detail');

  const renderTitle = () => {
    return idRef?.current?.taskCategory === '1'
      ? '健康宣教'
      : idRef?.current?.taskCategory === '2'
        ? '治疗任务'
        : idRef?.current?.taskCategory === '3'
          ? '治疗任务'
          : idRef?.current?.taskCategory === '4'
            ? '健康日记'
            : idRef?.current?.taskCategory === '5'
              ? '随访问卷'
              : idRef?.current?.taskCategory === '6'
                ? '评估量表'
                : '';
  };

  return (
    <>
      <NavBar children={renderTitle()} backArrow onBack={handleClickBack} />
      <Dom_title title={renderTitle()} />
      {idRef?.current?.taskCategory === '1' ||
      idRef?.current?.taskCategory === '2' ||
      idRef?.current?.taskCategory === '3' ? (
        <>
          <div
            className={classNames(`task_detail`)}
            style={{
              paddingBottom:
                !parseStringToBoolean(idRef?.current?.isExecute) &&
                patientUserInfo?.userAccount &&
                !idRef?.current?.type
                  ? 60
                  : 16,
            }}
          >
            <BraftEditor
              readOnly={true}
              controls={[
                'undo',
                'redo',
                'font-size',
                'line-height',
                'letter-spacing',
                'text-color',
                'bold',
                'media',
              ]}
              value={BraftEditor.createEditorState(detail?.content)}
              placeholder={'请填写'}
            />
            {/* <Editor value={detail?.content} readOnly></Editor> */}
            {/* <div dangerouslySetInnerHTML={{ __html: detail?.content }}></div> */}
          </div>
          {!parseStringToBoolean(idRef?.current?.isExecute) &&
            patientUserInfo?.userAccount &&
            !idRef?.current?.type && (
              <div className="taskBtnBack">
                <div
                  className="taskDetailBtn"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  完成
                </div>
              </div>
            )}
        </>
      ) : (
        <>
          <div>
            {renderAmis(
              transformData(),
              {
                content: detail?.conten || detail?.taskDetail?.content,
                data: convertContentData(),
                onCustomSubmit: handelCustomValue,
                toServiceDetails,
              },
              {
                fetcher: async (config: any) => {
                  const res = await customFetcher(config);
                  const _res = {
                    status: res.status === 200 ? 0 : -1,
                    headers: res.headers,
                    data: {
                      value: res?.data?.data,
                    },
                  } as any;
                  return _res;
                },
              },
            )}
          </div>
        </>
      )}
    </>
  );
};

export default definePage(TaskDetail);
