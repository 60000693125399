import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, NavBar, Radio } from '@vs/vsf-mobile';
import React, { useState } from 'react';

import weixin from '@/assets/weixin.png';
import Dom_title from '@/components/dom_title';
const OrderPay: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const handleClickBack = () => {
    vsf?.goBack();
  };
  const toPay = () => {
    if (!checked) {
      vsf.showToast('请先勾选已阅读《服务购买协议》');
    }
  };
  return (
    <>
      <NavBar children="选择支付" backArrow onBack={handleClickBack} />
      <Dom_title title="选择支付" />

      <div className="service_order_pay">
        <div className="price_box">
          <span className="unit">￥</span>
          <span className="price">1200.00</span>
        </div>
        <div className="georges">收款方：浙江大学医学院附属儿童医院</div>
        <div className="pay_manner">请选择支付方式</div>
        <div className="pay_manner_list">
          <div className="pay_manner_list_item">
            <img src={weixin} className="icon" />
            <span className="name">微信支付</span>
            <span className="radio" onClick={() => setChecked(!checked)}>
              <Radio
                style={{
                  '--icon-size': '23px',
                  '--font-size': '23px',
                }}
                checked={checked}
              />
            </span>
          </div>
        </div>
        <Button disabled={!checked} className="pay_btn" onClick={toPay}>
          去支付
        </Button>
      </div>
    </>
  );
};

export default definePage(OrderPay);
