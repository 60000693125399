import ServiceDetails from '@/pages/service_details';
import ServiceList from '@/pages/service_list';

const routes = [
  {
    path: '/serviceList',
    exact: true,
    component: ServiceList,
  },
  {
    path: '/serviceDetails',
    exact: true,
    component: ServiceDetails,
  },
];

export default routes;
