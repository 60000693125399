import { createServices } from '@vs/vsf-boot';
export default createServices({
  SpecialistCategoryController_getSpecialistCategoryPopularBundle_8b58c4: {
    method: 'post',
    url: '/api/serve-bundle/get-specialist-category-popular-bundle',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SpecialistCategoryPopularBundleVoF1Ba03[],
    ) => data,
  },
});
