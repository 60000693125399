import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  Button,
  Form,
  InfiniteScroll,
  Input,
  NavBar,
  NoticeBar,
  Popup,
  TextArea,
} from '@vs/vsf-mobile';
import { CloseCircleOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import empty from '@/assets/empty.png';
import ERR from '@/assets/invoice_err.png';
import SUCCESS from '@/assets/invoice_success.png';
import TIME from '@/assets/invoice_time.png';
import Dom_title from '@/components/dom_title';
import { InvoiceEEnum, InvoiceEnumType } from '@/config';

const InvoiceImg = {
  REFUNDING: TIME,
  CREATING: TIME,
  SUCCESS: SUCCESS,
  FAILED: ERR,
  REFUND: ERR,
};

const InvoiceSuccess: React.FC = (props: any) => {
  const { routes } = props;
  const { orderId, price, invoiceStatus, num, phone, email } =
    props.routes?.query || {};

  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    handleGetInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = async () => {
    if (num == 2) return vsf?.go(-3);
    if (phone || email) {
      vsf?.go(-2);
    } else {
      vsf?.goBack();
    }
  };

  const handleGetInvoice = async () => {
    const res = await vsf?.services?.OrderRecordController_getInvoice_2d81e4({
      // orderId: orderId,
      orderId,
      // 19701
    });
    if (res?.code == 200) {
      setValue(res?.data);
    }
  };
  const renderType = (type, key) => {
    if (type) {
      return props.routes?.query?.[key];
    } else {
      return value?.[key];
    }
  };

  return (
    <>
      <NavBar children="开具发票" backArrow />
      <Dom_title title="开具发票" />
      <div className="InvoiceSuccess-container">
        <div className="success">
          <img className="icon" src={InvoiceImg?.[invoiceStatus]}></img>
          <div className="prompt">{InvoiceEEnum?.[invoiceStatus]}</div>
          <div className="text">{InvoiceEnumType?.[invoiceStatus]}</div>
        </div>
        <div className="content">
          <div className="invoice-content">
            <div className="invoice-item">
              <div className="item-title">抬头类型</div>
              <div className="item-text">电子发票</div>
            </div>
            <div className="invoice-item">
              <div className="item-title">发票抬头</div>
              <div className="item-text">{value?.buyerName}</div>
            </div>
            <div className="invoice-item">
              <div className="item-title">金额</div>
              <div className="item-text">￥{price}</div>
            </div>
            <div className="invoice-item">
              <div className="item-title">电子邮箱</div>
              <div className="item-text">
                {renderType(num, 'email') ? renderType(num, 'email') : '-'}
              </div>
            </div>
            <div className="invoice-item">
              <div className="item-title">手机号</div>
              <div className="item-text">
                {renderType(num, 'phone') ? renderType(num, 'phone') : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <Button className="footerBtn" onClick={handleClick}>
            返回
          </Button>
          {!value?.reinvoiceIndicator && invoiceStatus == 'SUCCESS' && (
            <div
              className="btn"
              onClick={() =>
                vsf?.navigateTo(
                  `/invoice?orderId=${orderId}&price=${price}&invoiceStatus=${invoiceStatus}`,
                )
              }
            >
              重开发票
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default definePage(InvoiceSuccess);
