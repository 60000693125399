import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_videoUse_6f1470: {
    method: 'post',
    url: '/api/order-record/get-view-list',
    parameterFomatter: (data?: { orderItemId: number }) => data,
    responseFormatter: (_, __, data?: OnlineVideoListEo8A4D30[]) => data,
  },
  OrderRecordController_videoValid_43254a: {
    method: 'post',
    url: '/api/order-record/video-valid',
    parameterFomatter: (data?: { orderItemId: number; videoId: string }) =>
      data,
    responseFormatter: (_, __, data?: string) => data,
  },
  RegController_getRegByDoctorId_164091: {
    method: 'post',
    url: '/api/visit-apply/get-reg-by-doctor-id',
    parameterFomatter: (data?: { doctorId: number }) => data,
    responseFormatter: (_, __, data?: VisitApplyDoctorVoF6A530[]) => data,
  },
});
