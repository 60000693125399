// 测试环境
export const text_url = {
  self: 'https://specialist-child-h5.byteawake.com', //公司测试域名
  new_self: 'https://specialist-child.byteawake.com', //院内测试域名
  ASTHMA: 'https://tstscreening.u-breath.cn', //哮喘跳转链接
  SpecialistSLEEP: 'http://brainwonder.cn/#/pages/index/home', //睡眠患者端跳转链接
  HealthSLEEP: 'http://brainwonder.cn/#/pages/index/record', //睡眠医生端跳转链接
};

// 生产环境
export const prod_url = {
  self: 'https://zbgl.chzju.cn',
  ASTHMA: 'https://astms.u-breath.com',
  SpecialistSLEEP: 'http://brainwonder.cn/#/pages/index/home',
  HealthSLEEP: 'http://brainwonder.cn/#/pages/index/record',
};
