import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_getPatientOrderServeById_b4f0a3: {
    method: 'post',
    url: '/api/order-record/get-patient-order-serve-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PatientOrderServeVo03Ec0D) => data,
  },
  OrderRecordController_cancelOrder_f1eef1: {
    method: 'post',
    url: '/api/order-record/cancel-order',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
