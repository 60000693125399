import React, { useCallback, useEffect, useState } from 'react';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import { Avatar, Button, Divider, Image } from 'antd-mobile';

import dayjs from 'dayjs';
import Rx_img from '@/assets/Rx_img.png';

import './index.less';

const contentList = [
  {
    id: 1,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-10-16 00:18:38',
    state: false,
    prescription: '盐酸氨溴索',
  },
  {
    id: 2,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-07-29 01:37:06',
    state: false,
    prescription: '右美沙芬',
  },
  {
    id: 3,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-10-26 14:47:16',
    state: false,
    prescription: '阿莫西林',
  },
  {
    id: 4,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-04-04 17:01:32',
    state: false,
    prescription: '阿莫西林',
  },
  {
    id: 5,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-12-26 03:47:25',
    state: false,
    prescription: '阿莫西林',
  },
  {
    id: 6,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-10-09 05:42:11',
    state: false,
    prescription: '右美沙芬',
  },
  {
    id: 7,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-11-20 11:14:10',
    state: false,
    prescription: '右美沙芬',
  },
  {
    id: 8,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-10-26 09:44:15',
    state: false,
    prescription: '右美沙芬',
  },
  {
    id: 9,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-04-20 14:02:11',
    state: false,
    prescription: '强力枇杷露',
  },
  {
    id: 10,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-11-10 04:56:37',
    state: false,
    prescription: '强力枇杷露',
  },
  {
    id: 11,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-02-13 08:59:28',
    state: false,
    prescription: '强力枇杷露',
  },
  {
    id: 12,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-08-16 07:06:31',
    state: false,
    prescription: '阿莫西林',
  },
  {
    id: 13,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-10-15 18:45:07',
    state: false,
    prescription: '头孢呋辛',
  },
  {
    id: 14,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-05-08 19:37:10',
    state: false,
    prescription: '阿莫西林',
  },
  {
    id: 15,
    text: '偏头痛',
    name: '林玉春',
    timer: '2023-10-06 22:13:26',
    state: false,
    prescription: '盐酸氨溴索',
  },
];

export default function index(props) {
  const { user, data } = props?.routes?.query;
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    let str = contentList?.sort(
      (a, b) => dayjs(a.timer).valueOf() - dayjs(b.timer).valueOf(),
    );
    setDataList(str);
  }, []);
  const title = () => {
    return (
      <div className="prescription_title">
        <div className="prescription_title_user">
          <Avatar src="" />
          <h3>{user ?? '库浩晨'}</h3>
          <p>男</p>
          <p className="i">|</p>
          <p>2岁1个月5天</p>
        </div>
      </div>
    );
  };
  const content = () => {
    return (
      <div className="prescription_content">
        <ul className="prescription_content_ul">
          {dataList.map((item, index) => {
            return (
              <li key={index} className="prescription_content_ul_li">
                <div className="prescription_content_ul_li_title">
                  <p className="prescription_content_ul_li_title_p">
                    <img
                      src={Rx_img}
                      alt=""
                      className="prescription_content_ul_li_title_p_rx_img"
                    />
                    {item.text}
                  </p>
                  <p>{item.name}</p>
                </div>
                <div className="prescription_content_ul_li_timer">
                  <p>开方日期：{item.timer}</p>
                  <p>{item.state ? '已支付' : '待支付'}</p>
                </div>
                <div className="prescription_content_ul_li_prescription">
                  处方药品:{item.prescription}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="prescription">
      {title()}
      {content()}
    </div>
  );
}
