// PickerPopupimport { definePage } from '@vs/vsf-boot';
import './index.less';

import { definePage } from '@vs/vsf-boot';
import { Box, NavBar, Popup } from '@vs/vsf-mobile';
import { UpOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

interface valueType {
  value: string;
  label: string;
}
interface PickerPopup {
  children?: ReactNode;
  containerClassName: string;
  list?: valueType[];
  defaultValue?: valueType[] | valueType;
  isCheckbox?: boolean;
  onChange?: (e) => void;
}

const PickerPopup: React.FC<PickerPopup> = (props) => {
  const {
    children,
    containerClassName,
    isCheckbox = false,
    defaultValue,
    list,
    onChange,
  } = props;

  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState<any>([]);

  useEffect(() => {
    if (defaultValue) {
      setActive(defaultValue);
    }
  }, [defaultValue]);

  const handleRadio = (value) => {
    setVisible(false);
    setActive(value);
    onChange && onChange(value);
  };

  const handleClickItme = (value) => {
    if (!isCheckbox) return handleRadio(value);
    const find = active?.find((v) => v?.value == value?.value);
    if (find) {
      const activeList = active?.filter((v) => v?.value != value?.value);
      setActive(activeList);
    } else {
      setActive([...active, value]);
    }
  };

  const renderIsActive = (value) => {
    if (!isCheckbox) return value?.value == active?.value;
    return active?.find((v) => v?.value == value?.value);
  };

  return (
    <>
      <div
        className={classNames({
          [containerClassName]: true,
        })}
        onClick={() => {
          setVisible(true);
        }}
      >
        {children}
      </div>
      <Popup visible={visible} position="top" bodyClassName="PickerPopup">
        <div className="PickerPopup-header">
          筛选
          <UpOutline
            onClick={() => {
              setVisible(false);
            }}
          />
        </div>
        <div className="PickerPopup-content">
          {list?.map((v, i) => {
            return (
              <div
                className={classNames(`tag-item`, {
                  activeTag: renderIsActive(v),
                })}
                key={'tag' + i}
                onClick={() => {
                  handleClickItme(v);
                }}
              >
                {v?.label}
              </div>
            );
          })}
        </div>
        {isCheckbox && (
          <div className="PickerPopup-bottom">
            <div
              className="btn clear"
              onClick={() => {
                setActive([]);
                setVisible(false);
                onChange && onChange([]);
              }}
            >
              清空
            </div>
            <div
              className="btn"
              onClick={() => {
                setVisible(false);
                onChange && onChange(active);
              }}
            >
              查询
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default definePage(PickerPopup);
