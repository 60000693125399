import { createServices } from '@vs/vsf-boot';
export default createServices({
  BundleTeamController_getBundleTeamLeaderByServeBundleId_36e1eb: {
    method: 'post',
    url: '/api/serve-bundle/get-bundle-team-leader-by-serve-bundle-id',
    parameterFomatter: (data?: { serveBundleId: number }) => data,
    responseFormatter: (_, __, data?: BundleTeamLeaderVoF1Ba03[]) => data,
  },
  ServeBundleController_getServeBundleById_a3d7d9: {
    method: 'post',
    url: '/api/serve-bundle/get-serve-bundle-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ServeBundleVoF1Ba03) => data,
  },
});
