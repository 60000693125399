// housekeep
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Box,
  Button,
  Form,
  InfiniteScroll,
  NavBar,
  Popup,
  Tabs,
  TextArea,
} from '@vs/vsf-mobile';
import { FileOutline, RightOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import bell from '@/assets/bell.png';
import butler from '@/assets/Butler.jpg';
import empty from '@/assets/empty.png';
import housekeepImg from '@/assets/housekeep_img.png';
import phone from '@/assets/phone.png';
import time from '@/assets/titme.png';
import Dom_title from '@/components/dom_title';
import {
  ApplyStatusEnum,
  ApplyStatusTYPE,
  GenderEnum,
  TimePointEnum,
} from '@/config';
const IntelligentButler: React.FC = (props: any) => {
  return (
    <>
      <NavBar children="健康守护" backArrow />
      <Dom_title title="健康守护" />
      <div className="IntelligentButler-container">
        <img className="img" src={butler}></img>
      </div>
    </>
  );
};

export default definePage(IntelligentButler);
