import Dom_title from '@/components/dom_title';
import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, NavBar, Radio } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';
const Agreement = (props) => {
  const { id } = props.routes?.query || {};
  const [data, setData] = useState();

  const handleClickBack = () => {
    vsf?.goBack();
  };
  const getBundleTeamServeById = async () => {
    const res =
      await vsf.services.ServeBundleController_getServeBundleById_a3d7d9({
        id: Number(id),
      });
    if (res.code === 200) {
      setData(res.data ?? {});
    }
  };
  useEffect(() => {
    getBundleTeamServeById();
  }, []);
  return (
    <>
      <NavBar children="服务购买协议" backArrow onBack={handleClickBack} />
      <Dom_title title="服务购买协议" />

      <div className="agreement">
        {data?.bundleAgreement?.map((item) => {
          return <img key={item} src={item} alt="" />;
        })}
      </div>
    </>
  );
};
export default definePage(Agreement);
