import { Renderer } from 'amis';
import { Box, ImageUploader, NavBar, ActionSheet } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { AddOutline } from 'antd-mobile-icons';
import { initwx, dataURLtoFile } from '@/utils';
//服务端接口路径
var server = location.origin + '/api/external-oss/min-io-upload-file';
const MAXSIZE = 5242880;
const MyRenderer = (props) => {
  const { target, $schema, data } = props;
  const { themeCss, className, label, accept, multiple } = $schema;
  const [visible, setVisible] = useState(false);
  const fileList =
    data[props.name]?.split(',')?.map((item) => ({ url: item })) ?? [];
  const setFileList = (list) => {
    props.onChange(
      list?.length ? list.map((item) => item.url).join(',') : null,
      props.name,
    );
  };
  console.log(props);
  const isNotErrMaxSize = (message) => {
    if (message.search('5242880') !== -1) {
      vsf.showToast('图片不能超过5MB，请重新上传！');
      return false;
    }
    return true;
  };
  const actions = [
    {
      text: '拍照',
      key: 'appendByCamera',
      onClick: async () => {
        appendByCamera();
        setVisible(false);
      },
    },
    {
      text: '从相册中选择',
      key: 'appendByGallery',
      onClick: async () => {
        const isAndroid = navigator.userAgent.match(/Android/i);
        if (isAndroid) {
          requestGallery();
        } else {
          appendByGallery();
        }
        setVisible(false);
      },
    },
  ];
  // 拍照添加文件
  const appendByCamera = () => {
    try {
      plus.camera.getCamera().captureImage(function (e) {
        console.log('e is' + e);
        plus.io.resolveLocalFileSystemURL(
          e,
          function (entry) {
            entry.getMetadata(function (metaData) {
              const imgSize = metaData.size;
              compressImage(entry.toLocalURL(), entry.name, imgSize);
            });
          },
          function (e) {
            vsf.showToast('读取拍照文件错误：' + e.message);
          },
        );
      });
    } catch (error) {
      console.log(error);
    }
  };
  // 从相册添加文件
  const appendByGallery = () => {
    try {
      console.log('从相册添加文件');
      plus.gallery.pick(
        function (e) {
          if (e.files) {
            for (var i in e.files) {
              plus.io.resolveLocalFileSystemURL(
                e.files[i],
                function (entry) {
                  entry.getMetadata(function (metaData) {
                    const imgSize = metaData.size;
                    compressImage(entry.toLocalURL(), entry.name, imgSize);
                  });
                },
                function (e) {
                  vsf.showToast('读取拍照文件错误：' + e.message);
                },
              );
            }
          } else {
            plus.io.resolveLocalFileSystemURL(
              e,
              function (entry) {
                entry.getMetadata(function (metaData) {
                  const imgSize = metaData.size;
                  compressImage(entry.toLocalURL(), entry.name, imgSize);
                });
              },
              function (e) {
                vsf.showToast('读取拍照文件错误：' + e.message);
              },
            );
          }
        },
        function (e) {
          console.log('取消选择图片');
        },
        { filter: 'image', multiple: !!multiple },
      );
    } catch (error) {
      console.log(error);
    }
  };

  //app压缩图片
  function compressImage(url, filename, imgSize) {
    console.log('压缩图片前:', imgSize);
    var name = '_doc/upload/' + props.name + '-' + filename; //_doc/upload/F_ZDDZZ-1467602809090.jpg
    let quality = 80;
    if (imgSize >= MAXSIZE / 2) {
      quality = 70;
    }
    if (imgSize >= MAXSIZE) {
      quality = 50;
    }
    if (imgSize >= MAXSIZE * 1.5) {
      quality = 30;
    }
    plus.zip.compressImage(
      {
        src: url, //src: (String 类型 )压缩转换原始图片的路径
        dst: name, //压缩转换目标图片的路径
        quality, //quality: (Number 类型 )压缩图片的质量.取值范围为1-100
        overwrite: true, //overwrite: (Boolean 类型 )覆盖生成新文件
      },
      function (event) {
        console.log('压缩图片后:', event.size);
        if (event.size >= MAXSIZE) {
          //判断图片大小是否大于 5M
          vsf.showToast('图片压缩后不能超过5MB，请重新上传！');
        } else {
          upload(event.target);
        }
      },
      function (error) {
        plus.nativeUI.toast('压缩图片失败，请稍候再试');
      },
    );
  }
  // 上传文件
  const upload = (path) => {
    console.log('path', path);
    var wt = plus.nativeUI.showWaiting();
    var task = plus.uploader.createUpload(
      server,
      { method: 'POST' },
      function (t, status) {
        //上传完成
        if (status == 200) {
          var data = JSON.parse(t.responseText);
          console.log(data);
          if (data.code === 200) {
            setFileList([...fileList, { url: data.data }]);
          } else {
            if (data.message && isNotErrMaxSize(data.message)) {
              vsf.showToast('图片上传失败，请重新上传！', 'error');
            }
          }
          wt.close(); //关闭等待提示按钮
        } else {
          vsf.showToast('图片上传失败，请重新上传！', 'error');
          wt.close(); //关闭等待提示按钮
        }
      },
    );
    //添加其他参数
    task.addFile(path, { key: 'file' });
    task.start();
  };

  const handleWxUpload = () => {
    wx.chooseImage({
      count: 9, // 默认9
      sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
      success: function (res) {
        localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
        img = [];
        localIds.forEach((item) => {
          // 获取base64
          wx.getLocalImgData({
            localId: item, // 图片的localID
            success: function (res) {
              var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
              if (localData.indexOf('data:image') != 0) {
                localData = 'data:image/jpeg;base64,' + localData;
              }
              var fileName = new Date().getTime() + '.jpeg';
              handelH5Upload(dataURLtoFile(localData, fileName));
            },
          });
        });
      },
    });
  };

  const handelH5Upload = async (file) => {
    console.log(file);
    if (file.size >= MAXSIZE) {
      //判断图片大小是否大于 5M
      vsf.showToast('图片不能超过5MB，请重新上传！');
      throw new Error('Fail to upload');
    }
    // 这里的请求体自己构建比较麻烦,一般使用HTML5里面的formData来构建
    const formData = new FormData();
    formData.append('file', file);
    const url = '/api/external-oss/min-io-upload-file';
    const resp = await fetch(url, {
      method: 'POST',
      body: formData, //自动修改请求头,formdata的默认请求头的格式是 multipart/form-data
    });
    const res = await resp.json();
    console.log(res);
    if (res.code === 200) {
      //上传完成处理
      // props.onChange([...fileList, { url: res.data }], props.name);
      return {
        url: res.data,
      };
    } else {
      if (res.message && isNotErrMaxSize(res.message)) {
        vsf.showToast('图片上传失败，请删除图片重新上传！', 'error');
      }
      throw new Error('Fail to upload');
    }
  };

  // 申请相册权限
  function requestGallery() {
    plus?.android?.requestPermissions(
      ['android.permission.READ_EXTERNAL_STORAGE'],
      function (e) {
        console.log('android.permission.READ_EXTERNAL_STORAGE: ', e);
        if (e.deniedAlways?.length > 0) {
          //权限被永久拒绝
          // 弹出提示框解释为何需要定位权限，引导用户打开设置页面开启
          console.log('Always Denied!!! ' + e.deniedAlways?.toString());
        }
        if (e.deniedPresent?.length > 0) {
          //权限被临时拒绝
          // 弹出提示框解释为何需要定位权限，可再次调用plus.android.requestPermissions申请权限
          console.log('Present Denied!!! ' + e.deniedPresent.toString());
        }
        if (e.granted.length > 0) {
          //权限被允许
          //调用依赖获取定位权限的代码
          appendByGallery();
          console.log('Granted!!! ' + e.granted.toString());
        }
      },
      function (e) {
        console.log('Request Permissions error:' + JSON.stringify(e));
      },
    );
  }

  const handleUpload = () => {
    console.log('开始上传');
    try {
      if (window.plus) {
        console.log('app上传');
        setVisible(true);
      } else if (window.wx) {
        console.log('wx上传');
        handleWxUpload();
      }
    } catch (error) {
      console.log('handleUpload:', error);
    }
  };

  // useEffect(() => {
  //   initwx()
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <>
      <div
        data-role="form-item"
        class="cxd-Form-item cxd-Form-item--normal"
        data-editor-id="393bbb56608c"
        name="u:0df4f9893b2d"
        data-visible=""
        data-hide-text=""
      >
        {label ? (
          <label class="cxd-Form-label">
            <span>
              <span class="cxd-TplField">
                <span>{label}</span>
              </span>
            </span>
          </label>
        ) : null}
        {/* {!!(window.wx || window.plus) ? ( */}
        {!!window.plus ? (
          <ImageUploader
            value={fileList}
            onChange={setFileList}
            disableUpload
            accept={accept} // 接受的文件类型
            upload={() => {
              return new Promise((resolveOuter) => {
                resolveOuter(new Promise((resolveInner) => {}));
              });
            }}
            className={className}
          >
            <span
              className="adm-image-uploader-cell adm-image-uploader-upload-button"
              onClick={handleUpload}
            >
              <span className="adm-image-uploader-upload-button-icon">
                <AddOutline />
              </span>
            </span>
          </ImageUploader>
        ) : (
          <ImageUploader
            value={fileList}
            onChange={setFileList}
            accept={accept} // 接受的文件类型
            upload={handelH5Upload}
            multiple={multiple}
            className={className}
          ></ImageUploader>
        )}
      </div>

      <ActionSheet
        visible={visible}
        actions={actions}
        onClose={() => setVisible(false)}
        cancelText="取消"
      />
    </>
  );
};

Renderer({
  type: 'my-renderer',
  autoVar: true,
})(MyRenderer);
export default MyRenderer;
