import { createServices } from '@vs/vsf-boot';
export default createServices({
  ServeBundleController_getFallServeBundle_286f2c: {
    method: 'post',
    url: '/api/serve-bundle/get-fall-serve-bundle',
    parameterFomatter: (data?: { qto: ServeBundleFallQto23B983 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ServeBundleVoF1Ba03[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SpecialistCategoryController_getSpecialistCategoryById_9f576f: {
    method: 'post',
    url: '/api/serve-bundle/get-specialist-category-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SpecialistCategoryVoF1Ba03) => data,
  },
  ServeBundleController_getServeBundleById_a3d7d9: {
    method: 'post',
    url: '/api/serve-bundle/get-serve-bundle-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ServeBundleVoF1Ba03) => data,
  },
  SpecialistCategoryController_getSpecialistCategoryAmountById_68823b: {
    method: 'post',
    url: '/api/serve-bundle/get-specialist-category-amount-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SpecialistCategoryAmountVoF1Ba03) => data,
  },
});
