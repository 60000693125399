import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, NavBar, Tabs } from '@vs/vsf-mobile';
import React, { useState } from 'react';

import Dom_title from '@/components/dom_title';

import OrderListDom from './components/orderListDom';
const tabList = [
  {
    title: '全部',
    value: 'ALL',
    children: <OrderListDom />,
  },
  {
    title: '待付款',
    value: 'WAIT_PAY',
    children: <OrderListDom orderStatus="WAIT_PAY" />,
  },
  {
    title: '服务中',
    value: 'IN_SERVE',
    children: <OrderListDom orderStatus="IN_SERVE" />,
  },
  {
    title: '待评价',
    value: 'WAIT_EVALUATE',
    children: <OrderListDom orderStatus="WAIT_EVALUATE" />,
  },
  {
    title: '已评价',
    value: 'EVALUATED',
    children: <OrderListDom orderStatus="EVALUATED" />,
  },
];
const OrderList: any = (props) => {
  const { activeKey } = props.routes?.query || {};
  const [urlstate, setUrlState] = useUrlState(
    {
      activeKey: activeKey || 'ALL',
    },
    { navigateMode: 'replace' },
  );
  const handleClickBack = () => {
    vsf?.goBack();
  };

  return (
    <>
      <NavBar children="我的专科服务" backArrow onBack={handleClickBack} />
      <Dom_title title="我的专科服务" />
      <div className="order_list">
        <Tabs
          className="tabs"
          activeKey={urlstate?.activeKey || 'ALL'}
          onChange={(activeKey) => setUrlState({ activeKey })}
        >
          {tabList.map((item) => {
            return (
              <Tabs.Tab title={item.title} key={item.value}>
                {item.children}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default definePage(OrderList);
