import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  Button,
  InfiniteScroll,
  List,
  PullToRefresh,
} from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import { HeaithCareOrderStatusEnum, PAGE_SIZE } from '@/config';
import { getIntegerAndDecimal } from '@/utils';
const OrderListDom: any = ({ orderStatusIs }) => {
  const [data, setData] = useState<OrderRecordTeamPatientVo03Ec0D[] | any>([]);
  const [hasMore, setHasMore] = useState(false);
  const [from, setFrom] = useState(0);
  const getDoctorOrderList = async (reload) => {
    const res =
      await vsf.services.OrderRecordController_getDoctorOrderList_83dec2({
        qto: {
          orderStatusIs,
          size: PAGE_SIZE,
          from: reload ? 0 : from * PAGE_SIZE ?? 0,
        },
      });
    /* eslint-disable */
    if (res.code === 200) {
      setData(reload ? res.data.result : [...data, ...res?.data?.result] ?? []);
      setHasMore(res.data.result.length === PAGE_SIZE);
      setFrom(reload ? 1 : from + 1);
      return res.data?.result;
    } else {
      setHasMore(false);
    }
    /* eslint-enable */
  };
  useEffect(() => {
    getDoctorOrderList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusIs]);
  return (
    <div className="health_care_order_list_dom">
      <PullToRefresh onRefresh={() => getDoctorOrderList(true)}>
        <div className="list">
          {data.map((item, index) => (
            <div
              className="card"
              onClick={() =>
                vsf.navigateTo(
                  `/health_care_service_details?orderId=${item.id}`,
                )
              }
              key={item.id}
            >
              <div className="header">
                <span className="title">{item.bundleName}</span>
                <span
                  className={`state ${item.orderStatus !== 'CANCEL' ? 'state1' : 'state2'}`}
                >
                  {item.orderStatus
                    ? HeaithCareOrderStatusEnum[item.orderStatus]
                    : null}
                </span>
              </div>
              <div className="line"></div>
              <div className="infos">
                <span className="label">服务对象</span>
                <span className="value">
                  {item.visitPatient?.patientName ?? '-'}
                </span>
              </div>
              <div className="infos">
                <span className="label">医生/团队</span>
                <span className="value">
                  {item.bundleTeamType === 'TEAM'
                    ? item.serveTeam?.teamName ?? '-'
                    : item.serveDoctor?.doctorName ?? '-'}
                </span>
              </div>
              <div className="infos">
                <span className="label">服务有效期</span>
                <span className="value">{item.serveDateRange ?? '-'}</span>
              </div>
              <div className="line"></div>

              <div className="price_box">
                <div className="label">服务费用</div>
                <div className="price">
                  <span className="unit">￥</span>
                  {item.roundingCharge ? (
                    <>
                      <span className="price_integer">
                        {getIntegerAndDecimal(item.roundingCharge).integer}.
                      </span>
                      <span className="price_fractional">
                        {getIntegerAndDecimal(item.roundingCharge).decimal}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
                {/* {index < 5 ? (
                  <Button className="pay_btn">立即支付</Button>
                ) : index === 6 ? (
                  <Button className="pay_btn1">去评价</Button>
                ) : null} */}
              </div>
            </div>
          ))}
        </div>

        <InfiniteScroll
          children="我是有底线的~"
          loadMore={getDoctorOrderList}
          hasMore={hasMore}
          className="InfiniteScroll_box"
        />
      </PullToRefresh>
    </div>
  );
};

export default definePage(OrderListDom);
