import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';
const version = defineStore({
  versionInfo: storage.get('version_info') || {},
  versionIsUp: storage.get('version_is_up') || false,
  setVersionInfo: (v) => {
    version.versionInfo = v;
    storage.set('version_info', v);
  },
  setVersionIsUp: (v) => {
    version.versionIsUp = v;
    storage.set('version_is_up', v);
  },
});

export default version;
