import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicHolidayDefineController_queryByClinicHolidayDefineBaseQto_c61c85: {
    method: 'post',
    url: '/api/outp-register/query-by-clinic-holiday-define-base-qto',
    parameterFomatter: (data?: {
      qto: ClinicHolidayDefineBaseQtoOutpRegisterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicHolidayDefineBaseVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
  ClinicHolidayDefineController_createClinicHolidayDefine_dce09c: {
    method: 'post',
    url: '/api/outp-register/create-clinic-holiday-define',
    parameterFomatter: (data?: {
      btoParam: CreateClinicHolidayDefineBtoOutpRegisterServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicHolidayDefineController_updateClinicHolidayDefine_c29abe: {
    method: 'post',
    url: '/api/outp-register/update-clinic-holiday-define',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicHolidayDefineBtoOutpRegisterServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicHolidayDefineController_getDefineIdSetClinicRegister_8941cf: {
    method: 'post',
    url: '/api/outp-register/get-define-id-set-clinic-register',
    parameterFomatter: (data?: {
      defineIds: number;
      startDate: string;
      endDate: string;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
