import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, NavBar, Radio } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import doctorImg from '@/assets/doctor.png';
import Dom_title from '@/components/dom_title';
import { CareOrderStatusEnum, TITLEEnum } from '@/config';
import { getIntegerAndDecimal } from '@/utils';
const OrderDetail = (props) => {
  const [data, setData] = useState<DoctorOrderServeVo03Ec0D | any>({});
  const handleClickBack = () => {
    vsf?.goBack();
  };
  const { orderId } = props.routes?.query || {};
  const getPatientOrderDetailById = async () => {
    const res =
      await vsf.services.OrderRecordController_getPatientOrderDetailById_0cc644(
        {
          id: Number(orderId),
        },
      );
    if (res.code === 200) {
      setData(res.data ?? {});
    }
  };
  useEffect(() => {
    getPatientOrderDetailById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSrcEmpty = (src) => {
    if (!src) return doctorImg;
    if (src?.length == 0) return doctorImg;
    return src;
  };

  return (
    <>
      <NavBar children="订单详情" backArrow onBack={handleClickBack} />
      <Dom_title title="订单详情" />
      <div className="order_detail">
        <div className="card card1">
          <div className="header">
            <span className="title">{data.bundleName ?? '-'}</span>
            <span
              className={`state ${data.orderStatus === 'WAIT_PAY' ? 'state1' : 'state2'}`}
            >
              {CareOrderStatusEnum[data.orderStatus]}
            </span>
          </div>
          <div className="content">
            <img
              className="avater"
              src={
                data.bundleTeamType === 'TEAM'
                  ? renderSrcEmpty(data.serveTeam?.teamLeader?.avatarImg)
                  : renderSrcEmpty(data.serveDoctor?.avatarImg)
              }
              alt=""
            />
            <div className="p_infos">
              <div className="name_box">
                <span className="name">
                  {data.bundleTeamType === 'TEAM'
                    ? data.serveTeam?.teamLeader?.doctorName ?? '-'
                    : data.serveDoctor?.doctorName ?? '-'}
                </span>
                <span className="text">
                  {TITLEEnum[
                    data.bundleTeamType === 'TEAM'
                      ? data.serveTeam?.teamLeader?.title
                      : data.serveDoctor?.title
                  ] ?? '-'}
                </span>
              </div>
              {data.serveTeam?.teamName && (
                <div className="unit">{data.serveTeam?.teamName}</div>
              )}
              {/* eslint-disable */}
              <div className="unit">
                {data.bundleTeamType === 'TEAM'
                  ? data.serveTeam?.teamLeader?.institution?.institutionName ??
                    '-'
                  : data.serveDoctor?.institution?.institutionName ?? '-'}
              </div>
              <div className="text2">
                {data.bundleTeamType === 'TEAM'
                  ? data.serveTeam?.teamLeader?.department?.departmentName ??
                    '-'
                  : data.serveDoctor?.department?.departmentName ?? '-'}
              </div>
              {/* eslint-enable  */}
            </div>
            <div className="price">￥{data.roundingCharge ?? '-'}</div>
          </div>
          <div className="infos">
            <span className="label">服务周期</span>
            <span className="value">{(data.servePeriod ?? '-') + '天'}</span>
          </div>
          <div className="price_box">
            <div className="label">服务费用</div>
            <div className="price">
              <span className="unit">￥</span>
              {data.roundingCharge ? (
                <>
                  <span className="price_integer">
                    {getIntegerAndDecimal(data.roundingCharge).integer}.
                  </span>
                  <span className="price_fractional">
                    {getIntegerAndDecimal(data.roundingCharge).decimal}
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <div className="card card2">
          <div className="infos">
            <span className="label">订单编号</span>
            <span className="value">{data.orderNumber ?? '-'}</span>
          </div>
          <div className="infos">
            <span className="label">下单时间</span>
            <span className="value">{data.submitDateTime ?? '-'}</span>
          </div>
          <div className="infos">
            <span className="label">支付时间</span>
            <span className="value">{data.payDateTime ?? '-'}</span>
          </div>
          <div className="infos">
            <span className="label">支付渠道</span>
            <span className="value">
              {data.tradeOrderDetail?.payPlatform ?? '-'}
            </span>
          </div>
          <div className="infos">
            <span className="label">服务对象</span>
            <span className="value">
              {data.visitPatient?.patientName ?? '-'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default definePage(OrderDetail);
