import vsf, { definePage } from '@vs/vsf-boot';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { webUrlObj } from '@/config';
import { prod_url, text_url } from '@/config/url_list';
// source from https://www.npmjs.com/package/use-force-update
export function useForceUpdate(): () => void {
  const [, dispatch] = React.useState<Record<string, any>>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

export const useHorizontalScroll = (ref) => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (ref) {
        ref.current.scrollLeft += event.deltaY;
      }
    };

    ref?.current?.addEventListener('wheel', handleWheel);
  }, [ref]);
};
export function isNumber(value) {
  return !isNaN(value) && !isNaN(parseFloat(value));
}
export function getIntegerAndDecimal(number) {
  if (!isNumber(number))
    return {
      integer: '-',
      decimal: '-',
    };
  const strList = number.split('.');
  const integerPart = strList[0];
  const decimalPart = strList[1];
  return {
    integer: integerPart,
    decimal: decimalPart,
  };
}
export function getIntegerAndDecimalNum(number) {
  if (!isNumber(number))
    return {
      integer: '-',
      decimal: '-',
    };
  const strList = String(number.toFixed(2)).split('.');
  const integerPart = strList[0];
  const decimalPart = strList[1];
  return {
    integer: integerPart,
    decimal: decimalPart,
  };
}
export function cardHide(card) {
  const reg = /^(.{6})(?:\d+)(.{4})$/; // 匹配身份证号前6位和后4位的正则表达式
  const maskedIdCard = card.replace(reg, '$1******$2'); // 身份证号脱敏，将中间8位替换为“*”
  return maskedIdCard; // 输出：371782******5896
}

export function nameHide(name) {
  if (name.length == 2) {
    name = name.substring(0, 1) + '*'; // 截取name 字符串截取第一个字符，
    return name; // 张三显示为张*
  } else if (name.length == 3) {
    name = name.substring(0, 1) + '*' + name.substring(2, 3); // 截取第一个和第三个字符
    return name; // 李思思显示为李*思
  } else if (name.length > 3) {
    name = name.substring(0, 1) + '*' + '*' + name.substring(3, name.length); // 截取第一个和大于第4个字符
    return name; // 王五哈哈显示为王**哈
  }
}
// 手机号做脱敏处理
export function phoneHide(phone) {
  const reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/; // 定义手机号正则表达式
  phone = phone.replace(reg, '$1****$2');
  return phone; // 185****6696
}
// 判断当前环境
export function getENVIR() {
  const ua = window?.navigator?.userAgent?.toLowerCase();
  const isWeixin = ua.indexOf('micromessenger') !== -1;
  const isInApp = /(^|;\s)app\//.test(ua);
  if (isWeixin) {
    if ((window as any).__wxjs_environment === 'miniprogram') {
      return 'wxapp';
    } else {
      return 'wxh5';
    }
  } else {
    if (!isInApp) {
      return 'browser';
    } else {
      return 'app';
    }
  }
}
export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
  console.log('It is not a string!');
}

export function getUrlConfig() {
  return location.origin === prod_url.self ? prod_url : text_url;
}
export function getEnv() {
  return location.origin === prod_url.self ? 'prod' : 'text';
}
export function getWeek(date) {
  const datas = dayjs(date).day();
  const week = ['日', '一', '二', '三', '四', '五', '六'];
  return '周' + week[datas];
}
export function formatEvaluationCount(count) {
  if (count <= 10) {
    return count.toString();
  } else if (count <= 99) {
    const roundedCount = Math.floor(count / 10) * 10;
    return roundedCount.toString() + '+';
  } else if (count <= 999) {
    const roundedCount = Math.floor(count / 100) * 100;
    return roundedCount.toString() + '+';
  } else if (count <= 9999) {
    const roundedCount = Math.floor(count / 1000) * 1000;
    return roundedCount.toString() + '+';
  } else if (count <= 99999) {
    const roundedCount = Math.floor(count / 10000) * 10000;
    return roundedCount.toString() + '+';
  } else if (count <= 999999) {
    const roundedCount = Math.floor(count / 100000) * 100000;
    return roundedCount.toString() + '+';
  } else {
    // 处理上千、上万等更大的数量
    // 可以根据位数添加相应的单位（例如K、M等）
    // 这里只是简单地返回原始数量
    return '100万+';
  }
}
export function contentHtml(content) {
  // // 富文本编辑器的内容如何只获得文字去掉标签
  // content = content.replace(/<[^>]+>/g, '');
  // // 在上面的基础上还去掉了换行<br/>
  return content.replace(/<[^>]+>/g, '').replace(/(\n)/g, '');
}

export function isImgSrc(str) {
  return /\.(jpg|jpeg|png|gif|webp)$/i.test(str);
}

// 自定义的fetcher函数
export const customFetcher = ({
  url,
  method = 'GET',
  data,
  headers = {},
  ...config
}) => {
  // 根据不同的HTTP方法构建请求配置
  const requestOptions: any = {
    url,
    method,
    headers,
    ...config,
  };

  // 如果method是POST、PUT等需要body的请求，则添加data
  if (['POST', 'PUT', 'PATCH'].includes(method.toUpperCase())) {
    requestOptions.data = data;
  }

  // 发送请求并返回Promise
  return axios(requestOptions)
    .then((response) => {
      // 请求成功，返回响应数据
      const fetcherResult = {
        data: response.data, // amis 需要的实际数据
        status: response.status, // HTTP 响应状态码
        headers: response.headers, // HTTP 响应头
        // 还可以添加其他自定义属性...
      };
      return fetcherResult;
    })
    .catch((error) => {
      // 请求失败，抛出错误或返回错误信息
      if (error.response) {
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        throw new Error(`请求失败: ${error.response.status}`);
      } else if (error.request) {
        // 请求已发出，但没有收到响应
        throw new Error('请求超时或网络错误');
      } else {
        // 发送请求时发生了某些问题
        throw new Error('发生了未知错误');
      }
    });
};
/**
 * JS-SDK申请配置的方法
 * 需要用微信API接口的页面引入这个方法调用一次就能用了
 */
export const initwx = async () => {
  /**
   *  url: '/getwxAPI?url=' + window.location.href.split('#')[0],
   * ? /getwxAPI  ==> 是你后端的一个地址，这个接口接收一个url，然后返回wx.config请求所需的参数appId、timestamp等等
   * ! url=' + window.location.href.split('#')[0] ===> 在哪个页面调用这个方法，就传给后台哪个页面的url
   * ! 因为要配置微信的API接口，需要指定要使用API的页面url
   * ? .split('#')[0]  ==> 因为这个传给后台的url，不需要#号之后的内容，所以要分割
   * ! 注意：url不需要进行转义，直接放上去就行了encodeURIComponent(url)
   */
  const res = (await vsf.services.initwxAPI({
    url: '/getwxAPI?url=' + window.location.href.split('#')[0],
  })) as any;
  if (res?.code !== 200) {
    window.wx.config({
      appId: 'wx3458e630d8186be7', // 必填，企业微信的corpID
      timestamp: '1720144629', // 必填，生成签名的时间戳
      nonceStr: 'test', // 必填，生成签名的随机串
      signature: '275d71256ce4d9106264927350b2d652108f6549', // 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList: ['chooseImage', 'uploadImage'], // 必填，需要使用的JS接口列表
    });
  }
  return new Promise((resolve, reject) => {
    window.wx.ready(function () {
      resolve(true);
    });
    window.wx.error((res) => {
      console.log('initwxErr:', res);
      resolve(res);
    });
    setTimeout(() => {
      resolve(false);
    }, 3000);
  });
};
//将base64转换为文件
export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// JSON对象转为base64

export const jsonToBase64 = (jsonString) => {
  // 将JSON字符串转换为UTF-8编码的字节序列
  const utf8Bytes = unescape(encodeURIComponent(jsonString))
    .split('')
    .map(function (c) {
      return c.charCodeAt(0);
    });

  // 将字节序列转换为Base64字符串
  const base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));

  return base64String;
};

// 获取当前环境
export const getWebEnv = () => {
  const host = location.origin;
  if (host == webUrlObj?.produce) {
    // 生产
    return 'produce';
  } else if (host == webUrlObj?.test) {
    // 院内测试
    return 'test';
  } else {
    // 本地
    return 'local';
  }
};
