// empty
import vsf, { definePage } from '@vs/vsf-boot';
import empty from '@/assets/empty1.png';
import empty2 from '@/assets/empty2.png';

import './index.less';

const Empty = (props) => {
  const { type, text } = props;
  return (
    <div className="Empty-container">
      <img
        className="empty"
        src={type === 'health_care' ? empty2 : empty}
      ></img>
      {text ? <div className="text">{text}</div> : null}
    </div>
  );
};
export default definePage(Empty);
