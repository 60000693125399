import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';
// 查询默认个案
const plan = defineStore({
  planData: storage.get('planData'),
  warnNum: storage.get('warnNum'),
  warnData: storage.get('warnData'),
  step: storage.get('step') || '-1', //新手引导
  patientPlanTask: storage.get('patientPlanTask'), //我的指标
  setPlanData: async (v) => {
    if (v === 'none') {
      plan.planData = null;
      storage.set('planData', null);
    } else {
      const res =
        await vsf.services?.PlanPatientController_getAccountDefaultPlan_700494();
      if (res.code === 200) {
        plan.planData = res.data;
        storage.set('planData', res.data);
      }
    }
  },
  setWarnNum: async (v) => {
    plan.warnNum = v;
    storage.set('warnNum', v);
  },
  setWarnData: async (v) => {
    plan.warnData = v;
    storage.set('warnData', v);
  },
  setStep: async (v) => {
    plan.step = v;
    storage.set('step', v);
  },
  setPatientPlanTask: async (v) => {
    plan.patientPlanTask = v;
    storage.set('patientPlanTask', v);
  },
});

export default plan;
