// CaseManagement
import './index.less';

import useUrlState from '@ahooksjs/use-url-state';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  ImageUploader,
  InfiniteScroll,
  NavBar,
  SearchBar,
} from '@vs/vsf-mobile';
import { useThrottleFn } from 'ahooks';
import { Badge, Button, Picker, Space, Toast } from 'antd-mobile';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import abnormal from '@/assets/abnormal.png';
import assess from '@/assets/assess.png';
import empty from '@/assets/empty.png';
import questionnaire from '@/assets/questionnaire.png';
import triangle from '@/assets/Slice49.png';
import task from '@/assets/task.png';
import DomTitle from '@/components/dom_title';
import Empty from '@/components/Empty';
import { GenderEnum, planStatus } from '@/config';
import { isImgSrc } from '@/utils';

const size = 20;

interface CaseCard {
  value?: any;
  key?: number;
  type?: string;
  onClickDetail?: (e) => void;
  onClickType?: (e) => void;
  onClickIndividual?: (e) => void;
}

interface CasePicker {
  title?: string;
  value?: any;
  visible?: boolean;
  columns?: any;

  onClose?: () => void;
  onConfirm?: (value) => void;
  onClick?: () => void;
}

const CaseCard: React.FC<CaseCard> = (props) => {
  const { value, type, onClickDetail, onClickType, onClickIndividual } = props;
  const {
    currentPlanPathway,
    currentPlanStage,
    doctor,
    order,
    team,
    planPatient,
  } = value;

  const renderStr = (value, num) => {
    if (value?.length > num) {
      return `${value?.substring(0, num)}...`;
    }
    return value;
  };

  return (
    <div
      className="CaseCard"
      // onClick={() =>
      //   vsf?.navigateTo(`/cases_detail?planId=${value?.id}&type=WAIT_AUDIT`)
      // }
    >
      <div className="CaseCard-top">
        <div className="header">
          <div className="left">
            <div className="text">{planPatient?.patient?.patientName}</div>
            <div className="text">
              {GenderEnum?.[planPatient?.patient?.patientGender]}
            </div>
            <div className="text">{planPatient?.patient?.age}岁</div>
          </div>
          <div className="rigth">
            {renderStr(currentPlanPathway?.title, 6)}
            {`(${renderStr(currentPlanStage?.title, 5) ?? '-'})`}
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="text">
              医生/团队：{doctor?.doctorName ?? team?.teamName}
            </div>
          </div>
          {/* <div className="rigth">服务包：{order?.serveBundle?.bundleName}</div> */}
        </div>
      </div>
      {type == 'all' ? (
        <div className="CaseCard-bottom">
          <div
            className="bottom-item"
            onClick={() => {
              onClickDetail && onClickDetail(value);
            }}
          >
            <Badge content={null}>
              <img className="ietm-img" src={task}></img>
            </Badge>
            <div className="item-title">任务详情</div>
          </div>

          {value?.flag && (
            <div
              className="bottom-item"
              onClick={() => {
                onClickType && onClickType(value);
              }}
            >
              <Badge content={null}>
                <img className="ietm-img" src={questionnaire}></img>
              </Badge>
              <div className="item-title">随访问卷</div>
            </div>
          )}
          {value?.waitAddFlag && (
            <div
              className="bottom-item"
              onClick={() => {
                onClickType && onClickType(value);
              }}
            >
              <Badge content={value?.evaluate_num ?? null}>
                <img className="ietm-img" src={assess}></img>
              </Badge>
              <div className="item-title">评估量表</div>
            </div>
          )}
          {value?.abnormalNum > 0 && (
            <div
              className="bottom-item"
              onClick={() => {
                onClickType && onClickType(value);
              }}
            >
              <Badge content={value?.abnormalNum}>
                <img className="ietm-img" src={abnormal}></img>
              </Badge>
              <div className="item-title">异常指标</div>
            </div>
          )}
        </div>
      ) : (
        <div className="case-individual">
          {value?.planTaskExecuteAbnormalDtoList?.map((v, index) => {
            return (
              <div
                className="individual-item"
                key={index}
                onClick={() => {
                  onClickIndividual && onClickIndividual({ ...v, value });
                }}
              >
                {v?.projectName}指标异常
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const CasePicker: React.FC<CasePicker> = (props) => {
  const {
    title,
    value = [],
    visible,
    columns,
    onClose,
    onConfirm,
    onClick,
  } = props;

  const renderStr = (value, num) => {
    if (value?.length > num) {
      return `${value?.substring(0, num)}...`;
    }
    return value;
  };

  return (
    <>
      {' '}
      <div
        className="CasePicker-contaniner"
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="title">{renderStr(value?.label ?? title, 5)}</div>
        <img className="triangle" src={triangle}></img>
      </div>
      <Picker
        columns={[columns]}
        visible={visible}
        onClose={() => {
          onClose && onClose();
        }}
        value={[value?.value]}
        onConfirm={(v) => {
          const selectValie = columns?.find((ietm) => ietm?.value == v?.[0]);
          onConfirm && onConfirm(selectValie);
        }}
      />
    </>
  );
};

const CaseManagement: React.FC<any> = (props) => {
  const { id, planPatientId } = props.routes.query;
  const [urlstate, setUrlState] = useUrlState(props.routes?.query, {
    navigateMode: 'replace',
  });
  const [stateVisible, setStateVisible] = useState(false);
  const [currentCaseState, setCurrentCaseState] = useState<any>(
    urlstate?.currentCaseState ? JSON?.parse(urlstate?.currentCaseState) : null,
  );

  const [pathVisible, setPathVisible] = useState(false);
  const [currentPathValue, setCurrentPathValue] = useState<any>();

  const [selectList, setSelectList] = useState([]);
  const [currentPatientNameIs, setcurrentPatientNameIs] = useState(null);

  const [currentTab, setCurrentTab] = useState(
    urlstate?.currentTab ?? 'individual',
  );

  const [list, setList] = useState<PlanMasterWorkbenchVoDe76C9[]>([]);
  const [individualNum, setIndividualNum] = useState(null);

  const scrollIdRef = useRef<any>(null);
  const count = useRef<number>(0);
  const hasMore = useRef<boolean>(false);
  const from = useRef<number>(0);

  useEffect(() => {
    handleSelectList();
    try {
      if (urlstate?.currentPathValue) {
        setCurrentPathValue(JSON?.parse(urlstate?.currentPathValue));
      }
      if (urlstate?.currentCaseState) {
        setCurrentCaseState(JSON?.parse(urlstate?.currentCaseState));
      } else {
        setCurrentCaseState({
          label: '路径中',
          value: 'EXECUTE',
        });
      }

      if (urlstate?.currentTab) {
        setCurrentTab(urlstate?.currentTab);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(currentTab, 'currentTab');
    scrollIdRef.current = null;
    from.current = 0;
    Toast.show({
      content: '加载中',
      icon: 'loading',
      maskClickable: false,
    });
    if (currentTab == 'all') {
      handleAllList(false, scrollIdRef?.current);
    } else {
      handleAllIndividualList(false, scrollIdRef?.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathValue, currentCaseState, currentPatientNameIs]);

  useEffect(() => {
    scrollIdRef.current = null;
    from.current = 0;
    Toast.show({
      content: '加载中',
      icon: 'loading',
      maskClickable: false,
    });
    if (currentTab == 'all') {
      handleAllList(false, scrollIdRef?.current);
    } else {
      handleAllIndividualList(false, scrollIdRef?.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleAllList = async (isScroll = false, scrollId) => {
    const res =
      await vsf?.services?.RagPlanController_getPagefallByQueryPlanMasterWorkbenchQto_2b68dc(
        {
          qto: {
            scrollId: scrollId,
            size,
            currentPlanPathwayIdIn: [currentPathValue?.value],
            statusIs: currentCaseState?.value,
            patientNameIs: currentPatientNameIs,
          },
        },
      );
    if (res?.code == 200) {
      const data = res?.data;
      scrollIdRef.current = data?.scrollId;
      count.current = data?.count;
      hasMore.current = data?.hasMore;
      setList(isScroll ? [...list, ...data.result] : data?.result);
    }
    if (res?.code) {
      Toast.clear();
    }
  };

  const handleAllIndividualList = async (isScroll = false, scrollId) => {
    const res =
      await vsf?.services?.RagPlanController_getPagefallByQueryPlanMasterMedicalWaitHandleQto_6d70ff(
        {
          qto: {
            scrollId: scrollId,
            size,
            patientNameLike: currentPatientNameIs,
            from: from.current,
          },
        },
      );
    if (res?.code == 200) {
      const data = res?.data;
      const value = isScroll ? [...list, ...data.result] : data?.result;
      from.current = value?.length;
      // scrollIdRef.current = data?.scrollId;
      count.current = data?.count;
      setList(isScroll ? [...list, ...data.result] : data?.result);
      setIndividualNum(data?.count);
    }
    if (res?.code) {
      Toast.clear();
    }
  };

  const handleSelectList = async () => {
    const res =
      await vsf?.services?.RagPathwayController_getAllByQueryPathwayForSelectQto_425600(
        {
          qto: {
            size: 100,
            from: 0,
          },
        },
      );
    if (res?.code == 200) {
      setSelectList(
        res?.data?.map((v) => {
          return {
            ...v,
            label: v?.title,
            value: v?.id,
          };
        }),
      );
    }
  };

  const handleMore = async () => {
    if (currentTab == 'all') {
      handleAllList(true, scrollIdRef?.current);
    } else {
      handleAllIndividualList(true, scrollIdRef?.current);
    }
  };

  const handleSearchChange = (value) => {
    setcurrentPatientNameIs(value);
  };

  const { run } = useThrottleFn(handleSearchChange, { wait: 500 });
  const { run: runHandleMore } = useThrottleFn(handleMore, { wait: 500 });

  const handleJump = (value) => {
    const id = value?.serveBundleId;
    vsf?.navigateTo(`/service_details?id=${id}`);
  };

  const renderStr = (value) => {
    if (value?.length > 10) {
      return `${value?.substring(0, 10)}...`;
    }
    return value;
  };

  const handleClickTab = (value) => {
    setCurrentTab(value);
    setList([]);
    if (value == 'individual') {
      setCurrentCaseState({
        label: '路径中',
        value: 'EXECUTE',
      });
      setCurrentPathValue({});
    }
    setcurrentPatientNameIs(null);
  };

  const renderEmpty = () => {
    return (
      <div className="render-empty">
        <img src={empty} className="render-img"></img>
        <div className="render-empty-text">暂无数据</div>
      </div>
    );
  };

  const renderIsHasMore = (value) => {
    if (value == 'all') {
      return hasMore.current;
    } else {
      return list?.length < count?.current && count?.current > 0;
    }
  };

  return (
    <>
      <NavBar children="个案管理" backArrow={false} />
      <DomTitle title="个案管理" />
      <div className="CaseManagement-container">
        <div className="CaseManagement-header">
          <SearchBar
            placeholder="搜索患者姓名"
            icon={false}
            className="search"
            value={currentPatientNameIs ?? ''}
            onChange={run}
          />
          <div className="tab">
            <div
              className={classNames(`tab-item`, {
                activeTab: currentTab == 'individual',
              })}
              onClick={() => {
                handleClickTab('individual');
              }}
            >
              待处理
            </div>
            <div
              className={classNames(`tab-item`, {
                activeTab: currentTab == 'all',
              })}
              onClick={() => {
                handleClickTab('all');
              }}
            >
              所有患者
            </div>
          </div>
          {currentTab == 'all' && (
            <div className="CasePicker-box">
              <CasePicker
                title="个案状态"
                value={currentCaseState}
                visible={stateVisible}
                onClick={() => {
                  setStateVisible(true);
                }}
                onClose={() => {
                  setStateVisible(false);
                }}
                onConfirm={(value) => {
                  setCurrentCaseState(value);
                }}
                columns={planStatus}
              ></CasePicker>
              <CasePicker
                title="请选择路径"
                value={currentPathValue}
                visible={pathVisible}
                onClick={() => {
                  setPathVisible(true);
                }}
                onClose={() => {
                  setPathVisible(false);
                }}
                onConfirm={(value) => {
                  setCurrentPathValue(value);
                }}
                columns={selectList}
              ></CasePicker>
            </div>
          )}
          {currentTab == 'individual' && (
            <div className="case">
              <Badge content={individualNum}>
                <div className="case-item">指标异常</div>
              </Badge>
            </div>
          )}
          {/* <CasePicker
            title="个案状态"
            value={currentCaseState}
            visible={stateVisible}
            onClick={() => {
              setStateVisible(true);
            }}
            onClose={() => {
              setStateVisible(false);
            }}
            onConfirm={(value) => {
              setCurrentCaseState(value);
            }}
            columns={planStatus}
          ></CasePicker>
          <CasePicker
            title="请选择路径"
            value={currentPathValue}
            visible={pathVisible}
            onClick={() => {
              setPathVisible(true);
            }}
            onClose={() => {
              setPathVisible(false);
            }}
            onConfirm={(value) => {
              setCurrentPathValue(value);
            }}
            columns={selectList}
          ></CasePicker> */}
        </div>
        <div className="CaseManagement-content">
          {list?.map((v, index) => (
            <CaseCard
              key={index}
              value={v}
              onClickDetail={(value) => {
                setUrlState({
                  currentCaseState: JSON?.stringify(currentCaseState),
                  currentPathValue: JSON?.stringify(currentPathValue),
                  currentTab: currentTab,
                });
                vsf?.navigateTo(
                  `/cases_detail?planId=${value?.id}&type=AUDIT_SUCCESS`,
                );
              }}
              onClickType={(value) => {
                setUrlState({
                  currentCaseState: JSON?.stringify(currentCaseState),
                  currentPathValue: JSON?.stringify(currentPathValue),
                  currentTab: currentTab,
                });
                vsf?.navigateTo(
                  `/cases_detail?planId=${value?.id}&type=WAIT_AUDIT`,
                );
              }}
              onClickIndividual={(value) => {
                setUrlState({
                  currentCaseState: JSON?.stringify(currentCaseState),
                  currentPathValue: JSON?.stringify(currentPathValue),
                  currentTab: currentTab,
                });
                vsf?.navigateTo(
                  `/cases_detail?planId=${value?.value?.id}&type=WAIT_AUDIT`,
                );
              }}
              type={currentTab}
            ></CaseCard>
          ))}
          {list?.length == 0 && (
            <div className="my_empty">
              <Empty type="health_care" text="暂无个案"></Empty>
            </div>
          )}
          <InfiniteScroll
            loadMore={runHandleMore}
            hasMore={renderIsHasMore(currentTab)}
            children={list?.length > 0 ? '没有更多了' : ''}
          />
        </div>
      </div>
    </>
  );
};

export default definePage(CaseManagement);
