import { createServices } from '@vs/vsf-boot';
export default createServices({
  PlanPatientController_getAccountDefaultPlan_700494: {
    method: 'post',
    url: '/api/rag-plan/get-account-default-plan',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: AccountPlanVoDe76C9) => data,
  },
  PlanPatientController_getAccountPlans_492034: {
    method: 'post',
    url: '/api/rag-plan/get-account-plans',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: AccountPlanVoDe76C9[]) => data,
  },
  PlanPatientController_getTaskDaily_7f5b87: {
    method: 'post',
    url: '/api/rag-plan/get-task-daily',
    parameterFomatter: (data?: { qto: PlanTaskDailyDetailQto08B695 }) => data,
    responseFormatter: (_, __, data?: PlanTaskStageAndExecuteVoDe76C9[]) =>
      data,
  },
  PlanPatientController_getUnExecuteDays_68068d: {
    method: 'post',
    url: '/api/rag-plan/get-un-execute-days',
    parameterFomatter: (data?: { qto: PlanStageExecuteDailyQto08B695 }) => data,
    responseFormatter: (_, __, data?: string[]) => data,
  },
  PlanDoctorController_getTasksForDoctor_4e3be7: {
    method: 'post',
    url: '/api/rag-plan/get-tasks-for-doctor',
    parameterFomatter: (data?: { planId: number }) => data,
    responseFormatter: (_, __, data?: PlanTaskStageAndExecuteVoDe76C9[]) =>
      data,
  },
});
