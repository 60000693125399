import Dom_title from '@/components/dom_title';
import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, NavBar, Radio } from '@vs/vsf-mobile';
import React, { useEffect, useRef, useState } from 'react';
import { getEnv, getENVIR, getUrlConfig } from '@/utils';
import classNames from 'classnames';
const InterviewAgreement = (props) => {
  const { orderId, order_item_id, unuseCount } = props.routes?.query || {};
  const [time, setTime] = useState(3); //倒计时时间

  const handleClickBack = () => {
    vsf?.goBack();
  };
  const renderSafeArea = () => {
    if (getENVIR()?.includes('wx')) {
      const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
      return isiOS ? '22px' : '0px';
    }
  };
  const toInterview = () => {
    vsf.history?.replace(
      `/face_to_face_consultation_request_form?orderId=${orderId}&order_item_id=${order_item_id}&unuseCount=${unuseCount}`,
    );
  };

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time]);

  return (
    <>
      <NavBar children="复诊须知" backArrow onBack={handleClickBack} />
      <Dom_title title="复诊须知" />
      <div className="interview_agreement" id="interview_agreement">
        <div className="interview_agreement_content">
          <div className="title">复诊须知</div>
          <p>
            1、请至少<span className="red">提前1天（20:00前）</span>
            提交复诊申请。
          </p>
          <p>
            2、若您的复诊申请在<span className="red">就诊日0:00</span>
            仍未审核通过，
            <span className="red">系统将自动关闭申请并退还申请次数。</span>
          </p>
          <p>
            3、
            <span className="red">
              请谨慎选择复诊日期，并如约完成线下复诊，复诊申请通过后再取消只能返还复诊申请次数，但视为1次违约。
            </span>
            一个月累计违约3次，将被医院列入黑名单1个月，无法正常申请。如解封后，仍连续违约3次，封禁时间将为1年。
          </p>
          <p>
            4、 客服电话<span className="red">0571-86670108</span>
            （工作日8:30-11:00 13:30-17:00）。
          </p>
        </div>
        <div
          className="interview_agreement_bottom"
          style={{ paddingBottom: renderSafeArea() }}
        >
          <Button
            disabled={time}
            className={classNames([
              'interview_agreement_btn',
              {
                disabled_btn: time,
              },
            ])}
            onClick={toInterview}
          >
            我已阅读{time ? `(${time}s)` : null}
          </Button>
        </div>
      </div>
    </>
  );
};
export default definePage(InterviewAgreement);
