import React, { useCallback, useEffect, useState } from 'react';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import { Avatar, Button, Divider, Image } from 'antd-mobile';
import './index.less';
import dayjs from 'dayjs';

const btnList = [
  {
    id: 1,
    text: '门诊',
  },
  {
    id: 2,
    text: '网络',
  },
  {
    id: 3,
    text: '住院',
  },
];

// 门诊
const outpatientLeft = [
  {
    id: 1,
    title: '门诊皮肤科',
    timer: '2024-04-29 16:05:08',
  },
  {
    id: 2,
    title: '门诊呼吸内科',
    timer: '2024-04-28 16:05:08',
  },
  {
    id: 3,
    title: '门诊药物咨询',
    timer: '2024-04-27 16:05:08',
  },
  {
    id: 4,
    title: '门诊皮肤科',
    timer: '2024-04-26 16:05:08',
  },
  {
    id: 5,
    title: '门诊呼吸内科',
    timer: '2024-04-25 16:05:08',
  },
  {
    id: 6,
    title: '门诊皮肤科',
    timer: '2024-04-24 16:05:08',
  },
  {
    id: 7,
    title: '门诊呼吸内科',
    timer: '2024-04-23 16:05:08',
  },
  {
    id: 8,
    title: '门诊药物咨询',
    timer: '2024-04-242 16:05:08',
  },
  {
    id: 9,
    title: '门诊呼吸内科',
    timer: '2024-04-21 16:05:08',
  },
  {
    id: 10,
    title: '门诊皮肤科',
    timer: '2024-04-20 16:05:08',
  },
];
// 网络
const netWorkLeftObj = [
  {
    id: 1,
    title: '急性支气管炎',
    timer: '2023-10-17 20:58:45',
  },
  {
    id: 2,
    title: '急性支气管炎',
    timer: '2023-09-26 07:33:28',
  },
  {
    id: 3,
    title: '急性支气管炎',
    timer: '2023-10-29 05:31:25',
  },
  {
    id: 4,
    title: '急性支气管炎',
    timer: '2023-09-08 20:05:04',
  },
  {
    id: 5,
    title: '支气管扩张',
    timer: '2023-11-23 15:52:48',
  },
  {
    id: 6,
    title: '支气管扩张',
    timer: '2023-11-04 06:59:13',
  },
  {
    id: 7,
    title: '皮肤过敏',
    timer: '2023-05-23 02:31:48',
  },
  {
    id: 8,
    title: '皮肤过敏',
    timer: '2023-05-15 19:06:08',
  },
];
// 住院
const hospitalization = [
  {
    id: 1,
    title: '住院皮肤科',
    timer: '2024-04-29 16:05:08',
  },
  {
    id: 2,
    title: '住院呼吸内科',
    timer: '2024-04-28 16:05:08',
  },
  {
    id: 3,
    title: '住院药物咨询',
    timer: '2024-04-27 16:05:08',
  },
  {
    id: 4,
    title: '住院皮肤科',
    timer: '2024-04-26 16:05:08',
  },
  {
    id: 5,
    title: '住院呼吸内科',
    timer: '2024-04-25 16:05:08',
  },
  {
    id: 6,
    title: '住院皮肤科',
    timer: '2024-04-24 16:05:08',
  },
  {
    id: 7,
    title: '住院呼吸内科',
    timer: '2024-04-23 16:05:08',
  },
  {
    id: 8,
    title: '住院药物咨询',
    timer: '2024-04-242 16:05:08',
  },
  {
    id: 9,
    title: '住院呼吸内科',
    timer: '2024-04-21 16:05:08',
  },
  {
    id: 10,
    title: '住院皮肤科',
    timer: '2024-04-20 16:05:08',
  },
];

export default function index(props) {
  const { patientName, data } = props.routes.query;
  const [zindex, setZindex] = useState(1);
  const [active, setActive] = useState(0);
  const [listData, setListData] = useState([]);

  const [administrative, setAdministrative] = useState('');

  // useEffect(() => {
  //   let obj = [];
  //   let i = 0;
  //   let index = 0;
  //   data?.forEach((item) => {
  //     if (item.num <= i) {
  //       i++;
  //       index++;
  //       obj[i].title = item.name;
  //       obj[i].timer = getRandomDate();
  //       id = index;
  //     } else {
  //       i = 0;
  //     }
  //     console.log(obj);
  //   });
  // }, []);

  useEffect(() => {
    let newList = [];
    switch (zindex) {
      case 0:
        newList = outpatientLeft?.sort(
          (a, b) => dayjs(a.timer).valueOf() - dayjs(b.timer).valueOf(),
        );
        break;
      case 1:
        newList = netWorkLeftObj?.sort(
          (a, b) => dayjs(a.timer).valueOf() - dayjs(b.timer).valueOf(),
        );
        break;
      case 2:
        newList = hospitalization?.sort(
          (a, b) => dayjs(a.timer).valueOf() - dayjs(b.timer).valueOf(),
        );
        break;

      default:
        break;
    }
    let str = newList;
    setAdministrative(str[active]?.title);
    setListData(newList);
  }, [zindex, active]);

  const tabsBtn = (index) => {
    setZindex(index);
    setActive(0);
  };

  const medicalTitle = () => (
    <div className="medical_title">
      <div className="medical_title_user">
        <Avatar src="" />
        <h3>库浩晨</h3>
        <p>男</p>
        <p className="i">|</p>
        <p>2岁1个月5天</p>
      </div>
      <Divider className="divider"></Divider>
      <div className="medical_title_btn">
        {btnList.map((item, index) => {
          return (
            <Button
              color="default"
              fill="none"
              size="large"
              shape="rounded"
              className={index === zindex ? 'active' : null}
              onClick={() => tabsBtn(index)}
            >
              {item.text}
            </Button>
          );
        })}
      </div>
    </div>
  );
  const medicalcenter = () => (
    <div className="medical_content">
      <div className="medical_content_left">
        <ul>
          {listData.map((item, index) => {
            return (
              <li
                key={item.id}
                className={active === index ? 'active' : null}
                onClick={() => setActive(index)}
              >
                <p>{item.title}</p>
                <p>{item.timer}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="medical_content_right">
        <div>
          <div className="medical_content_right_card">
            <div className="indicators">
              <div className="indicators-header">
                <div className="header-title">2024-04-22 10:54:11</div>
                <div className="header-rigth">
                  <span className="text">图文</span>
                </div>
              </div>
              <div className="indicators-content">
                <div className="indicators-abnormol medical_content_right_card_O">
                  <p>{administrative}</p>
                  <p>|</p>
                  <p>薛慧荣</p>
                </div>
                <div className="medical_content_right_card_T">
                  <p>门诊号</p>
                </div>
                <div className="medical_content_right_card_btn">
                  <p>文字记录</p>
                  <RightOutline
                    fontSize={12}
                    color="#A7AFBA"
                    className="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="height_weight">
            <div>
              <p className="title">身高</p>
              <span className="value">110cm</span>
            </div>
            <div>
              <p className="title">体重</p>
              <span className="value">50kg</span>
            </div>
          </div>
          <Divider className="divider"></Divider>
          <div>
            <p className="title">患病时长</p>
            <span className="nullText">暂无数据</span>
          </div>
          <Divider className="divider"></Divider>
          <div>
            <p className="title">病情描述</p>
            <span className="value">4.22-11</span>
          </div>
          <Divider className="divider"></Divider>
          <div>
            <p className="title">既往史</p>
            <p className="value">有过敏史,有出生抢救史,有重大疾病史</p>
          </div>
          <Divider className="divider"></Divider>
          <div>
            <p className="title">诊断</p>
            <p className="value">暂无数据</p>
          </div>
          <Divider className="divider"></Divider>
          <div>
            <p className="title">相关图片</p>
            <Image src="/404" width={100} height={100} />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="medical">
      {medicalTitle()}
      {medicalcenter()}
    </div>
  );
}
