import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitApplyController_updateStatusVisitApply_4af036: {
    method: 'post',
    url: '/api/visit-apply/update-status-visit-apply',
    parameterFomatter: (data?: {
      btoParam: UpdateStatusVisitApplyBto0B5B9E;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VisitApplyController_getPagedByGetApplyByDoctorStatusQto_9007bf: {
    method: 'post',
    url: '/api/visit-apply/get-paged-by-get-apply-by-doctor-status-qto',
    parameterFomatter: (data?: { qto: GetApplyByDoctorStatusQto0B5299 }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: VisitApplyPatientDoctorVoF6A530[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
