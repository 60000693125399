import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, Button, NavBar, Popup, Radio } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';
import { getIntegerAndDecimal, isJSON, getUrlConfig, getEnv } from '@/utils';
import del from '@/assets/del.png';
import down from '@/assets/down.png';
import { TITLEEnum } from '@/config';
import empty from '@/assets/empty.png';
import doctorImg from '@/assets/doctor.png';

const cusType = {
  GRAPHIC_CONSULTATION: 'IM', //图文咨询
  PRESCRIPTION_CONSULTATION: 'PRES', //复诊续方
  TELEPHONE_CONSULTATION: 'VOICE', //电话咨询
  // GRAPHIC_CONSULTATION: 'IM',
};
// 跳转问诊聊天室参数
// toUserNick：string //跟医生聊天的医生名称
// orderNo：订单号
// type：暂定写死history
// cusType：服务类型： 图文咨询："IM"；在线续方："PRES"；电话咨询："VOICE"；视频问诊："REMOTE"
// packageOrderNo: 服务包订单No
// orderId：订单ID
// doctorId： 医生ID
// packOrderFrom：服务包来源，写死zhuanbing

const getPageType = (serveType) => {
  let pageType;
  switch (serveType) {
    case 'GRAPHIC_CONSULTATION': //图文咨询
      pageType = 1;
      break;
    case 'TELEPHONE_CONSULTATION': //电话咨询
      pageType = 1;
      break;
    case 'PRESCRIPTION_CONSULTATION': //复诊续方
      pageType = 1;
      break;
    case 'VIDEO_EVANGELISM': //视频宣教
      pageType = 2;
      break;
    case 'FACE_APPLY': //面诊申请
      pageType = 3;
      break;
    case 'OFFLINE_SERVE': //线下服务
      pageType = 4;
      break;
    case 'ASTHMA_FOLLOW': //哮喘随访
      pageType =
        '/elk-asthma-internet/index.html#/pages/followService/follow-upService?access_token=';
      break;
    case 'ASTHMA_DIARY': //哮喘日记
      pageType =
        '/elk-asthma-internet/index.html#/pages/asthmaDiary/asthmaDiary?access_token=';
      break;
    case 'ASTHMA_MANAGER': //哮喘管理
      pageType =
        getEnv() === 'prod'
          ? '/elk-asthma-internet-doctor/index.html#/report/0?fromType=internet&access_token='
          : '/elk-asthmamgmt-web-doctor/index.html#/report/0?fromType=internet&access_token=';
      break;
    case 'ASTHMA_ASSESSMENT': //哮喘评估
      pageType =
        '/elk-asthma-internet/index.html#/pages/controlAssess/controlAssess?access_token=';
      break;
    case 'SPECIALIST_MANAGER': //专病管理
      pageType = 5;
      break;
    default:
      break;
  }
  return pageType;
};
const ServiceListPopupButton = ({
  children,
  style,
  className,
  order_item_id,
  orderId,
  serveType,
  unuseCount,
  onLoad,
  value,
  onClickCustomer,
}) => {
  const [visible, setVisible] = useState(false);
  const [teamDoctorList, setTeamDoctorList] = useState([]);
  const [doctor, setDoctor] = useState();

  // 去智康咨询页
  const toZhikangSelectPatient = (data) => {
    const value = {
      cusType: cusType[serveType],
      packageOrderNo: data?.id, //服务包订单No
      // orderId: data.id, //服务包id
      patientIdCard: data?.order?.visitPatient?.idNum,
      patientName: data?.order?.visitPatient?.patientName,
      serveComboName: data?.order?.bundleName,
      doctorId: doctor?.jobCode,
      packOrderFrom: 'zhuanbing',
    };
    const searchStr = new URLSearchParams(value).toString();
    console.log('searchStr', searchStr);
    window.uni?.navigateTo({
      url: `/pages/consult/select-patient/select-patient?${searchStr}`,
    });
  };

  // 去智康我的问诊
  const toZhikangChat = (data) => {
    // const value = {
    //   toUserNick: data?.operatorUser?.doctorName,
    //   orderNo: data?.thirdOrderNo || data,
    //   doctorNo: data?.operatorUser?.accountNumber,
    //   // type: 'history',
    // };
    // const searchStr = new URLSearchParams(value).toString();
    // window.uni?.navigateTo({
    //   url: `/pages/consult/chat/chat?${searchStr}`,
    // });
    vsf.showToast('您有问诊未结束，请查看！');
    setTimeout(() => {
      window.uni?.switchTab({
        url: `/pages/consult-record/consult-record`,
      });
    }, 1500);
  };

  // 去哮喘
  const toSsthma = async (url) => {
    const res = await vsf.services?.OrderRecordController_asthmaLogin_b92979?.({
      orderId,
      type: 1,
    });
    if (res.code === 200 && res.data) {
      if (isJSON(res.data?.result)) {
        const data = JSON.parse(res.data?.result);
        if (data?.data && data?.errcode === 0) {
          location.href =
            getUrlConfig().ASTHMA + url + data?.data?.access_token;
        } else {
          vsf.showToast(data.errmsg, 'error');
        }
      }
    }
  };

  const getTeamDoctorList = async () => {
    const res =
      await vsf.services?.OrderRecordController_getTeamDoctorList_34089b?.({
        order_item_id,
      });
    if (res.code === 200 && res?.data) {
      setTeamDoctorList(res?.data);
      if (res.data?.length === 1) {
        setDoctor(res.data?.[0]?.doctor);
      }
    }
  };
  // 消费服务包项目
  const orderUse = async () => {
    if (!doctor.id) {
      return vsf.showToast('请选选择医生');
    }
    const res = await vsf.services?.OrderRecordController_orderUse_87cce4?.({
      doctorId: doctor.id,
      orderItemId: order_item_id,
    });
    if (res.code === 200 && res?.data) {
      setVisible(false);
      onLoad && onLoad();
      toZhikangSelectPatient(res?.data);
    }
  };

  // 判断是否有消费服务包项目正在进行中
  const orderInService = async () => {
    console.log(value);
    const pageTypeOrUrl = getPageType(serveType);
    if (pageTypeOrUrl === 1) {
      const res =
        await vsf.services?.OrderRecordController_getUsedOrderItemLog_ac1713?.({
          orderItemId: order_item_id,
        });
      if (res.code === 200 && res?.data) {
        toZhikangChat(res?.data);
      } else {
        if (unuseCount) {
          vsf.showToast('剩余服务次数为0', 'error');
        } else {
          setVisible(true);
        }
      }
      return;
    }
    if (pageTypeOrUrl === 2) {
      console.log('视频宣教');
      if (unuseCount) {
        vsf.showToast('剩余服务次数为0', 'error');
      } else {
        vsf.navigateTo('/video_list?id=' + order_item_id);
      }
      return;
    }
    if (pageTypeOrUrl === 3) {
      console.log('面诊申请');
      if (unuseCount) {
        vsf.showToast('剩余服务次数为0', 'error');
      }
      vsf.navigateTo(
        `/interview_agreenment?orderId=${orderId}&order_item_id=${order_item_id}&unuseCount=${unuseCount ? 0 : 1}`,
      );
      return;
    }
    if (pageTypeOrUrl === 4) {
      console.log('线下服务');
      // if (unuseCount) {
      //   vsf.showToast('剩余服务次数为0', 'error');
      // } else {
      //   vsf.showToast('请联系健康管家！', 'warn');
      // }
      onClickCustomer && onClickCustomer(value);
      return;
    }
    if (pageTypeOrUrl === 5) {
      console.log('专病管理');
      const res =
        await vsf.services?.PlanPatientController_getAccountDefaultPlan_700494({
          orderId,
        });
      if (res.code === 200 && res.data) {
        vsf.navigateTo('/daily_quest?currentPlanId=' + res.data?.id);
      } else {
        vsf.showToast('请联系健康管家！', 'warn');
      }
      return;
    }
    toSsthma(pageTypeOrUrl);
  };

  useEffect(() => {
    if (visible) {
      getTeamDoctorList();
    } else {
      setDoctor(null);
    }
  }, [visible]);

  const renderEmpty = () => {
    return (
      <div className="empty">
        <img src={empty} className="img"></img>
        <div className="empty-text">暂无数据</div>
      </div>
    );
  };

  const renderSrcEmpty = (src) => {
    if (!src) return doctorImg;
    if (src?.length == 0) return doctorImg;
    return src;
  };

  return (
    <>
      <div style={style} className={className} onClick={orderInService}>
        {children}
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        destroyOnClose
        className="service_popup1"
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
          maxHeight: '70vh',
          backgroundColor: '#F8F8F8',
        }}
      >
        <div className="service_popup_content">
          <div className="header">
            <div className="headeer_top">
              <div className="title">请选择问诊医生</div>
              <span className="close_box" onClick={() => setVisible(false)}>
                <img src={del} className="close"></img>
              </span>
            </div>
          </div>
          <Radio.Group
            onChange={(val) => {
              setDoctor(
                teamDoctorList.find((d) => d.doctor?.id === val)?.doctor,
              );
            }}
            value={doctor?.id}
          >
            <div className="service_list">
              {teamDoctorList?.length
                ? teamDoctorList?.map((item) => {
                    return (
                      <div className="service_item" key={item.doctor?.id}>
                        <div className="avatar">
                          <img
                            src={renderSrcEmpty(item.doctor?.avatarImg)}
                            alt=""
                          />
                        </div>
                        <div className="info">
                          <div className="name_box">
                            <span className="name">
                              {item.doctor?.doctorName ?? '-'}
                            </span>
                            <span className="posts">
                              {TITLEEnum[item.doctor?.title] ?? '-'}
                            </span>
                          </div>
                          <div className="profession">
                            {item.doctor?.departmentName ?? '-'}
                          </div>
                        </div>
                        <div className="price">
                          <Radio value={item.doctor?.id} />
                        </div>
                      </div>
                    );
                  })
                : renderEmpty()}
            </div>
          </Radio.Group>
          {teamDoctorList?.length ? (
            <Button className="topage_btn" onClick={orderUse}>
              确认开始
            </Button>
          ) : null}
        </div>
      </Popup>
    </>
  );
};

export default definePage(ServiceListPopupButton);
