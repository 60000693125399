import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  Button,
  Form,
  InfiniteScroll,
  Input,
  NavBar,
  NoticeBar,
  Popup,
  TextArea,
} from '@vs/vsf-mobile';
import { CloseCircleOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import empty from '@/assets/empty.png';
import Dom_title from '@/components/dom_title';
import {
  ApplyStatusEnum,
  ApplyStatusTYPE,
  GenderEnum,
  TimePointEnum,
} from '@/config';

const Invoice: React.FC = (props: any) => {
  const { routes } = props;
  const { orderId, price, invoiceStatus } = props.routes?.query || {};
  const [formValue, setFormValue] = useState<any>();

  const [visible, setVisible] = useState(false);

  const idRef = useRef(routes.query?.id ?? 2401);

  const [form] = Form.useForm();

  useEffect(() => {
    if (invoiceStatus == 'SUCCESS') handleGetInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = async () => {
    // const data = await form?.getFieldsValue();
    // const value = await vsf?.refs?.Form1?.getFieldsValue();
    // console.log(data, value, data?.buyerName, value?.email, value?.phone);
    const data = await form
      ?.validateFields()
      ?.then((values) => {
        return values;
      })
      ?.catch((err) => {});

    const value = await vsf?.refs?.Form1?.validateFields()
      ?.then((values) => {
        return values;
      })
      ?.catch((err) => {});
    if (data && value) {
      setVisible(true);
      setFormValue({ ...data, ...value });
    }
  };

  const handleGetInvoice = async () => {
    const res = await vsf?.services?.OrderRecordController_getInvoice_2d81e4({
      // orderId: orderId,
      orderId,
      // 19701
    });
    if (res?.code == 200) {
      form?.setFieldsValue(res?.data);
      vsf?.refs?.Form1?.setFieldsValue(res?.data);
    }
  };

  const handleFinish = async (value) => {
    setVisible(false);
  };

  const handleClickBtn = async () => {
    if (invoiceStatus == 'SUCCESS') {
      const res = await vsf?.services?.OrderRecordController_reInvoice_8c2036({
        orderId,
        companyEo: formValue,
      });
      if (res?.code === 200) {
        vsf?.navigateTo(
          `/invoice_success?orderId=${orderId}&price=${price}&invoiceStatus=CREATING&num=2&phone=${formValue?.phone ?? ''}&email=${formValue?.email ?? ''}`,
        );
      }
    } else {
      const res =
        await vsf?.services?.OrderRecordController_createInvoice_62c1ad({
          orderId,
          companyEo: formValue,
        });
      if (res?.code === 200) {
        vsf?.navigateTo(
          `/invoice_success?orderId=${orderId}&price=${price}&invoiceStatus=CREATING&num=1&phone=${formValue?.phone ?? ''}&email=${formValue?.email ?? ''}`,
        );
      }
    }
  };

  const handleCreateInvoice = async () => {};

  const renderEmpty = () => {
    return (
      <div className="empty">
        <img src={empty} className="img"></img>
        <div className="empty-text">暂无数据</div>
      </div>
    );
  };

  return (
    <>
      <NavBar children="开具发票" backArrow />
      <Dom_title title="开具发票" />
      <div className="invoice-container">
        {invoiceStatus == 'SUCCESS' && (
          <NoticeBar
            content="温馨提示：发票仅可重开一次，请仔细核对"
            wrap
            color="alert"
            icon={<></>}
          />
        )}

        <div className="content">
          <div className="title">发票详情</div>
          <Form
            layout="horizontal"
            footer={false}
            form={form}
            initialValues={{ cancelReason: '个人或事业单位' }}
            onFinish={handleFinish}
          >
            <Form.Item
              name="cancelReason"
              label="抬头类型"
              rules={[{ required: true, message: '请输入审核不通过原因' }]}
            >
              <Input onChange={console.log} placeholder="请输入姓名" disabled />
            </Form.Item>
            <Form.Item
              name="buyerName"
              label="发票抬头"
              rules={[{ required: true, message: '请填写发票抬头' }]}
            >
              <Input
                onChange={console.log}
                placeholder="填写需要开具发票的姓名"
              />
            </Form.Item>
          </Form>
          <div className="price">
            <div className="left">
              <text>总金额</text>
              <text className="num">￥{price}</text>
            </div>
            <div className="rigth">共1张</div>
          </div>
          <div className="title">接收方式</div>
          <Form
            layout="horizontal"
            footer={false}
            // form={form}
            id="Form1"
            onFinish={handleFinish}
          >
            <Form.Item
              name="email"
              label="电子邮箱"
              rules={[
                {
                  validator(rule, value, callback) {
                    const formRef = vsf?.refs?.Form1;
                    const formValue = formRef?.getFieldsValue();
                    if (formValue?.phone || formValue?.email) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error(`请输入电子邮箱`));
                    }
                  },
                },
                {
                  message: '请输入有效的邮箱',
                  pattern: new RegExp(
                    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  ),
                },
              ]}
            >
              <Input placeholder="请输入电子邮箱" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="手机号"
              rules={[
                {
                  pattern: new RegExp(/^1[3-9]\d{9}$/),
                  message: '请输入有效的手机号',
                },
                {
                  validator(rule, value, callback) {
                    const formRef = vsf?.refs?.Form1;
                    const formValue = formRef?.getFieldsValue();
                    if (formValue?.email || formValue?.phone) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error(`请输入手机号`));
                    }
                  },
                },
              ]}
            >
              <Input placeholder="请输入手机号" />
            </Form.Item>
          </Form>
          <div className="footer">
            <Button className="footerBtn" onClick={handleClick}>
              {invoiceStatus == 'SUCCESS' ? '重开发票' : '提交'}
            </Button>
          </div>
        </div>
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        className="invoice-popup"
        bodyClassName="invoice-content"
      >
        <div className="invoice-header">
          <div className="btn" onClick={() => setVisible(false)}></div>
          <div className="title">开具电子发票</div>
          <div className="btn determine" onClick={() => setVisible(false)}>
            <CloseCircleOutline fontSize={24} color="#D9D9D9" />
          </div>
        </div>
        <div className="content">
          <div className="content-item">
            <div className="item">
              <div className="title">抬头类型</div>
              <div className="text">电子发票</div>
            </div>
          </div>
          <div className="content-item">
            <div className="item">
              <div className="title">发票抬头</div>
              <div className="text">{formValue?.buyerName ?? '-'}</div>
            </div>
          </div>
          <div className="content-item">
            <div className="item">
              <div className="title">金额</div>
              <div className="text">￥{price ?? '-'}</div>
            </div>
          </div>
          <div className="content-item">
            <div className="item">
              <div className="title">电子邮箱</div>
              <div className="text">
                {formValue?.email ? formValue?.email : '-'}
              </div>
            </div>
          </div>
          <div className="content-item">
            <div className="item">
              <div className="title">手机号</div>
              <div className="text">
                {formValue?.phone ? formValue?.phone : '-'}
              </div>
            </div>
          </div>
          <div className="prompt time">
            如无特殊情况，我们将在24小时内通过邮箱或短信发送。
          </div>
          <Button className="footerBtn" onClick={handleClickBtn}>
            确认提交
          </Button>
        </div>
      </Popup>
    </>
  );
};

export default definePage(Invoice);
