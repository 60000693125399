import './index.less';

import { Box, CenterPopup, Grid, NavBar, Toast } from '@vs/vsf-mobile';
import { CloseCircleOutline } from 'antd-mobile-icons';
import React from 'react';

interface MajordomoPopup {
  visible: boolean;
  title?: string;
  phone?: string;
  codeUrl?: string;
  onClosePopup?: () => void;
  onOpenPopup?: () => void;
  type?: string;
}

const MajordomoPopup: React.FC<MajordomoPopup> = (props) => {
  const {
    visible = false,
    title = '',
    phone = '',
    codeUrl = '',
    type,
    onClosePopup,
  } = props;

  const handleClose = () => {
    onClosePopup && onClosePopup();
  };

  return (
    <div className="majordomo-popup-container">
      <CenterPopup
        visible={visible}
        onMaskClick={handleClose}
        className="majordomo-popup"
      >
        <div className="popup-content">
          <div className="popup-header">
            <CloseCircleOutline
              onClick={handleClose}
              fontSize={24}
              color="#D9D9D9"
            />
          </div>
          <div className="header-name">联系客服</div>
          <div className="content">
            {type !== 'doctor' ? (
              <div className="content-item">
                <div className="text">
                  若您对服务有任何疑问，可联系健康管家免费咨询
                </div>
                {/* <div className="title">{title}</div> */}
              </div>
            ) : null}
            <div className="content-item">
              <div className="title">联系电话：</div>
              <div className="phone">{phone}</div>
            </div>
            <div className="content-item">
              <div className="title">工作时间：</div>
              <div className="text">周一至周五 9:00-17:00</div>
            </div>
            {codeUrl && (
              <div className="code">
                <img className="code-img" src={codeUrl}></img>
                <div>微信二维码</div>
              </div>
            )}
          </div>
        </div>
      </CenterPopup>
    </div>
  );
};
export default MajordomoPopup;
