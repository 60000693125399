import { definePage } from '@vs/vsf-boot';
import { Box, NavBar } from '@vs/vsf-mobile';
import React, { useEffect } from 'react';
interface DomTitlePopup {
  title: string;
}
const DomTitle: React.FC<DomTitlePopup> = (props) => {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return <></>;
};

export default definePage(DomTitle);
