import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar } from '@vs/vsf-mobile';
import {
  AddressBookFill,
  LocationFill,
  PhonebookFill,
} from 'antd-mobile-icons';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import Dom_title from '@/components/dom_title';
import { GenderEnum } from '@/config';
const size = 20;
const PatientList: React.FC = (props: any) => {
  const { routes } = props;
  const { userAccount } = routes.query;
  console.log(routes.query, ' routes.query');

  const [list, setList] = useState<PatientManagerVo462A84[]>([]);

  useEffect(() => {
    handleGetPatienList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount]);

  const handleGetPatienList = async () => {
    const res =
      await vsf?.services?.EmployeePatientController_getPatientByUserAccount_309763(
        {
          userAccount,
        },
      );
    if (res?.code === 200) {
      setList(res?.data);
    }
  };

  const renderAge = (v) => {
    const birthday = dayjs(v?.birthday);
    const today = dayjs();
    const age = today.diff(birthday, 'year');
    return age;
  };

  return (
    <>
      <NavBar children="就诊人" backArrow />
      <Dom_title title="就诊人" />
      <div className="PatientList-container">
        {list?.map((v, index) => {
          return (
            <div className="patient-item" key={index}>
              <div className="item-header">
                <div className="name">{v?.patientName}</div>
                <div className="left">
                  {GenderEnum?.[v?.patientGender ?? '']}
                </div>
                <div className="left">{renderAge(v)}岁</div>
              </div>
              <div className="item-content">
                <div className="num">
                  <PhonebookFill />
                  <div className="text">{v?.phone}</div>
                </div>
                <div className="num">
                  <AddressBookFill />
                  <div className="text">{v?.idNum}</div>
                </div>
                {/* <LocationFill /> */}
              </div>
              <div className="item-footer">
                <div className="num">
                  <LocationFill />
                  <div className="text">{v?.address ?? '-'}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default definePage(PatientList);
