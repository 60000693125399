import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderPayController_getTradeNo_9f9f57: {
    method: 'post',
    url: '/api/order-pay/get-trade-no',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (_, __, data?: ResultVo93Bd63) => data,
  },
  OrderPayController_refundingOne_69ba38: {
    method: 'post',
    url: '/api/order-pay/refunding-one',
    parameterFomatter: (data?: { tradeNo: string; refundFee: string }) => data,
    responseFormatter: (_, __, data?: ResultVo93Bd63) => data,
  },
  OrderPayController_getThirdServiceEo_52a825: {
    method: 'post',
    url: '/api/order-pay/get-third-service-eo',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (_, __, data?: ThirdServiceEo13B1B6) => data,
  },
  OrderRecordController_asthmaLogin_b92979: {
    method: 'post',
    url: '/api/order-record/asthma-login',
    parameterFomatter: (data?: {
      orderId: number;
      dockerId: string;
      type: string;
    }) => data,
    responseFormatter: (_, __, data?: AsthmaLoginVo03Ec0D) => data,
  },
  RagPlanController_getPagefallByQueryPlanMasterMedicalWaitHandleQto_6d70ff: {
    method: 'post',
    url: '/api/rag-plan/get-pagefall-by-query-plan-master-medical-wait-handle-qto',
    parameterFomatter: (data?: {
      qto: QueryPlanMasterMedicalWaitHandleQto08B695;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PlanMasterMedicalWaitHandleDtoVoDe76C9[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  EmployeePatientController_getPatientByUserAccount_309763: {
    method: 'post',
    url: '/api/employee-patient/get-patient-by-user-account',
    parameterFomatter: (data?: { userAccount: string }) => data,
    responseFormatter: (_, __, data?: PatientManagerVo462A84[]) => data,
  },
  RagTempController_getTaskMasterUsedDetailVoById_b702ff: {
    method: 'post',
    url: '/api/rag-temp/get-task-master-used-detail-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: TaskMasterUsedDetailVo765641) => data,
  },
});
