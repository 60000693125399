import './index.less';
import 'braft-editor/dist/index.css';
import '@/components/FormEditor/compontents/renderer/MyRenderer';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Modal, NavBar, Radio } from '@vs/vsf-mobile';
import { render as renderAmis } from 'amis';
import { Log } from 'amis/lib/renderers/Log';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Dom_title from '@/components/dom_title';
import { aichatKefuIdObj, aichatUrlObj, customerUrlList } from '@/config';
import { customFetcher, getWebEnv } from '@/utils';
// import Editor from '@/components/Editor';
const TaskDetail = (props) => {
  const { routes } = props;
  const { query } = routes;
  const { detailId } = query || {};
  const { user, plan } = props.stores;
  const [detail, setDetail] = useState<any>({});
  const { currentUserInfo } = user;

  const handleClickBack = () => {
    vsf?.goBack();
  };

  const getTaskMasterUsedDetailVoById = async () => {
    const res =
      await vsf.services.RagTempController_getTaskMasterUsedDetailVoById_b702ff(
        {
          id: detailId,
        },
      );
    if (res?.code === 200) {
      setDetail(res?.data?.usedDetail);
    }
  };
  useEffect(() => {
    getTaskMasterUsedDetailVoById();
    const getEle = document.getElementById('pageView');
    getEle?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailId]);

  const toDoctorDetails = (url) => {
    console.log(url);
    window.uni?.navigateTo({
      url,
    });
  };

  const toMoreDoctor = (id, active) => {
    console.log('toMoreDoctor', id, active);
    vsf.navigateTo(`/summer_html?detailId=${id}&active=${active}`);
    location.reload();
  };

  const handleClickMajords = () => {
    window.location.href = `${aichatUrlObj?.[getWebEnv()]}/chatIndex?ent_id=${aichatKefuIdObj?.[getWebEnv()]}&visitor_name=${JSON?.stringify({ userAccount: currentUserInfo?.userAccount })}}`;
  };

  const transformData = () => {
    const content = detail?.content || detail?.taskDetail?.content;
    if (!content) return {};
    return JSON?.parse(content);
  };
  return (
    <>
      <NavBar
        children={'8月份暑期平台活动'}
        backArrow
        onBack={handleClickBack}
      />
      <Dom_title title="8月份暑期平台活动" />
      <div id="pageView" key={'xxx' + detailId}>
        {renderAmis(
          transformData(),
          {
            content: detail?.conten || detail?.taskDetail?.content,
            toDoctorDetails,
            handleClickMajords,
            toMoreDoctor,
          },
          {
            fetcher: async (config: any) => {
              const res = await customFetcher(config);
              const _res = {
                status: res.status === 200 ? 0 : -1,
                headers: res.headers,
                data: {
                  value: res?.data?.data,
                },
              } as any;
              return _res;
            },
          },
        )}
      </div>
    </>
  );
};

export default definePage(TaskDetail);
