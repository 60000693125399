import { createServices } from '@vs/vsf-boot';
export default createServices({
  RagPlanController_getAllByQueryPlanMasterIndicatorFrontQto_e9348e: {
    method: 'post',
    url: '/api/rag-plan/get-all-by-query-plan-master-indicator-front-qto',
    parameterFomatter: (data?: {
      qto: QueryPlanMasterIndicatorFrontQto08B695;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlanMasterIndicatorRecordFrontVoDe76C9[],
    ) => data,
  },
  RagPlanController_getPagefallByQueryPlanMasterWorkbenchQto_2b68dc: {
    method: 'post',
    url: '/api/rag-plan/get-pagefall-by-query-plan-master-workbench-qto',
    parameterFomatter: (data?: { qto: QueryPlanMasterWorkbenchQto08B695 }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PlanMasterWorkbenchVoDe76C9[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  RagPlanController_getPatients_3a45e8: {
    method: 'post',
    url: '/api/rag-plan/get-patients',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: PatientManagerVo462A84[]) => data,
  },
  RagPathwayController_getAllByQueryPathwayForSelectQto_425600: {
    method: 'post',
    url: '/api/rag-pathway/get-all-by-query-pathway-for-select-qto',
    parameterFomatter: (data?: { qto: QueryPathwayForSelectQtoAfb1D1 }) => data,
    responseFormatter: (_, __, data?: PathwayMasterVoEd4Ec1[]) => data,
  },
  OssPolicyController_getMinIoPolicy_592a17: {
    method: 'post',
    url: '/api/external-oss/get-min-io-policy',
    parameterFomatter: (data?: { fileName: string }) => data,
    responseFormatter: (_, __, data?: MinIoPolicyVoCa3088) => data,
  },
});
