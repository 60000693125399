import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderRecordController_getPatientOrderDetailById_0cc644: {
    method: 'post',
    url: '/api/order-record/get-patient-order-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PatientOrderDetailVo03Ec0D) => data,
  },
  OrderRecordController_orderUse_87cce4: {
    method: 'post',
    url: '/api/order-record/order-use',
    parameterFomatter: (data?: { orderItemId: number; doctorId: number }) =>
      data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OrderRecordController_getTeamDoctorList_34089b: {
    method: 'post',
    url: '/api/order-record/get-team-doctor-list',
    parameterFomatter: (data?: { orderItemId: number }) => data,
    responseFormatter: (_, __, data?: TeamMemberDetailVo03Ec0D[]) => data,
  },
  OrderRecordController_getUsedOrderItemLog_ac1713: {
    method: 'post',
    url: '/api/order-record/get-used-order-item-log',
    parameterFomatter: (data?: { orderItemId: number }) => data,
    responseFormatter: (_, __, data?: OrderItemUseLogOperatorVo03Ec0D) => data,
  },
});
