import './index.less';

import { definePage } from '@vs/vsf-boot';
import { Box, NavBar } from '@vs/vsf-mobile';
import { DownOutline, RightOutline, UpOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
interface DomTitlePopup {
  content: string;
  row?: number;
}
const EllipsisDom: React.FC<DomTitlePopup> = (props) => {
  const { content, row } = props;
  const [isEllipsis, setIsEllipsis] = useState(true);
  const listHeight = useRef<any>();

  return (
    <div className="EllipsisDom" ref={listHeight}>
      {content ? (
        <div className={classNames([{ ellipsis3: isEllipsis }])}>
          {content ?? '-'}
          {listHeight.current?.clientHeight > 45 ? (
            <span
              className={classNames([
                'ellipsis_btn',
                {
                  ellipsis_btn_expanded: isEllipsis,
                },
              ])}
            >
              {isEllipsis ? (
                <span onClick={() => setIsEllipsis(false)}>
                  <DownOutline />
                  展开
                </span>
              ) : (
                <span onClick={() => setIsEllipsis(true)}>
                  <UpOutline />
                  收起
                </span>
              )}
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default definePage(EllipsisDom);
